import React, { useState, useEffect } from 'react'
import CustomizedTreeView from '../../../components/General/CustomizedTreeView' // Your existing CustomizedTreeView component
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import {
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import CategorizationComponent from 'src/components/Asset/CategorizationComponent'
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector'

const filterData = (node, searchTerm) => {
  if (!node || !node.title) return null

  const isSelfMatch = node.title.toLowerCase().includes(searchTerm.toLowerCase())

  const filterChildren = (childrenKey) => {
    if (!node[childrenKey]) return []
    return node[childrenKey].map(childNode => filterData(childNode, searchTerm)).filter(Boolean)
  }

  const filteredChildren = {
    missions: filterChildren('missions'),
    related_organization: filterChildren('related_organization'),
    information_systems: filterChildren('information_systems'),
    primary_assets: filterChildren('primary_assets'),
    supporting_assets: filterChildren('supporting_assets'),
    related_assets: filterChildren('related_assets'),
    related_information_systems: filterChildren('related_information_systems')
  }

  if (isSelfMatch) {
    return { ...node } // If the node is a match, return it with all its original children.
  } else if (Object.values(filteredChildren).some(children => children.length > 0)) {
    return { ...node, ...filteredChildren } // If the node has matching descendants, return it with the filtered children.
  }

  return null
}

const ContentPage = () => {
  const [selectedNode, setSelectedNode] = useState(null)
  const [catId, setCatId] = useState()
  const [searchTerm, setSearchTerm] = useState('') // State to capture search input
  const { getComplianceList: getAssetManagementInstances, complianceList: amInstances } = useRequestCompliance({ endpoint: 'asset_management_tree', resourceLabel: 'Asset management instances' })
  const { getCompliance: getCatInstance, compliance: catInstance, resetCompliance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })

  const [drawerOpen, setDrawerOpen] = useState(false)

  const [dynamicEndpoint, setDynamicEndpoint] = useState('')
  const { getCompliance: getResource, compliance: resource } =
    useRequestCompliance({ endpoint: dynamicEndpoint })
  const editLevels = () => {
    setDrawerOpen(true)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
  }
  useEffect(() => {
    getAssetManagementInstances()
  }, [getAssetManagementInstances])

  useEffect(() => {
    if (dynamicEndpoint) { getResource(selectedNode.id) }
  }, [dynamicEndpoint, selectedNode])

  useEffect(() => {
    if (resource) { resource.security_requirements ? setCatId(resource.security_requirements) : setCatId(resource.security_compatibilities) }
  }, [resource])

  useEffect(() => {
    if (catId) { getCatInstance(catId) }
  }, [catId])
  const handleNodeSelect = (node) => {
    if (node !== selectedNode) {
      setSelectedNode(node)
      setDynamicEndpoint(node.url) // Update the endpoint when a node is selected
      resetCompliance() // Reset catInstance to null
    }
  }
  const filteredData = amInstances.results.map(node => filterData(node, searchTerm)).filter(Boolean)
  console.log('resource resource:', resource?.roles)
  return (
      <>
        {/* ... (Drawer component and other elements) */}
        <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '40%',
                    marginTop: '64px'
                  }
                }}
            anchor="right" variant="temporary" open={drawerOpen} onClose={closeDrawer}>
      <div>
        <IconButton onClick={closeDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Box p={2} sx={{ flexGrow: 1 }}>
      {catInstance
        ? <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance}/>
        : null}
</Box>
          </Drawer>

        <Grid container>
          {/* Title Section */}
          <Box p={2} sx={{ flexGrow: 1 }}>
            <Typography variant="h5">
              Eunoia Asset Explorer
            </Typography>
            <Divider/>
          </Box>

          {/* Main Content */}
          <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 64px)' }}>
            {/* TreeView Sidebar */}
            <Grid item xs={12} md={4}>
  <Paper
    style={{
      height: 'calc(100vh - 64px)', // adjust 64px if you have different header/footer sizes
      overflow: 'auto'
    }}
  >
    <Box p={2}>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
      />
    </Box>
    <Box p={2}>
      <CustomizedTreeView data={filteredData} handleNodeSelect={handleNodeSelect} />
    </Box>
  </Paper>
</Grid>
            {/* Details View */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Resource Details */}
                {selectedNode ? (
                  <>
                    {/* If an element is selected, render the detailed view */}
                    {resource ? (
                <>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        {resource.title}
                      </Typography>
                      <Typography variant="subtitle1" paragraph>
                        {resource.description}
                      </Typography>
                      <Divider />
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Created By"
                            secondary={resource.created_by} // Note: Display user name if available.
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Created At"
                            secondary={resource.created_at}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Updated At"
                            secondary={resource.updated_at}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
            {/* Roles and Responsibilities */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Roles and Responsibilities
            </Typography>
            {resource.roles && Object.keys(resource.roles).length > 0 ? (
              <List>
                {Object.entries(resource.roles).map(([roleTitle, userDetails], index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={roleTitle}
                      secondary={`${userDetails?.username} (${userDetails?.email})`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No roles assigned.</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
                                </>
                    ) : null}

                                {catInstance
                                  ? <Grid item xs={6}>
              <CategorizationComponent data={catInstance} editLevels={editLevels} />
            </Grid>
                                  : null}
                  </>
                ) : (
                  // If no element is selected, render a placeholder or message
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Welcome to Eunoia Asset Explorer!
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          Please select an element from the left panel to view its details.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
  )
}

export default ContentPage
