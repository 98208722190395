import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const RaciMatrix = ({ raciMatrix, roles, activities }) => {
  const theme = useTheme(); // Use theme to access colors

    // Define a function to get color based on RACI option
    const getColor = (option) => {
        switch (option) {
          case 'R':
            return theme.palette.riskLevels.veryHigh; // Use a specific color for "Responsible"
          case 'A':
            return theme.palette.riskLevels.high; // Use a specific color for "Accountable"
          case 'C':
            return theme.palette.riskLevels.medium; // Use a specific color for "Consulted"
          case 'I':
            return theme.palette.riskLevels.low; // Use a specific color for "Informed"
          default:
            return theme.palette.action.active; // Default color
        }
      };

  if (!raciMatrix) {
    return <Typography>No RACI Matrix available for your organization.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Activity \ Role</TableCell>
            {roles.map((role) => (
              <TableCell key={role.id}>{role.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity) => (
            <TableRow key={activity.id}>
              <TableCell component="th" scope="row">
                {activity.title}
              </TableCell>
              {roles.map((role) => {
                const raciKey = `${role.id}-${activity.id}`;
                let raci = raciMatrix[raciKey];
                // Attempt to parse raci data if it's a string
                if (typeof raci === 'string') {
                  try {
                    raci = JSON.parse(raci.replace(/'/g, '"'));
                  } catch (e) {
                    console.error('Failed to parse RACI data:', e);
                    raci = [];
                  }
                }
                return (
                  <TableCell key={raciKey}>
                    {Array.isArray(raci) ? raci.map((code, index) => (
                      <Chip
                        key={index}
                        label={code}
                        size="small"
                        style={{ backgroundColor: getColor(code), color: 'white', margin: 2 }}
                      />
                    )) : ''}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RaciMatrix;
