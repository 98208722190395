import React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';

const CardWrapper = ({ title, subheader, children }) => (
    <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: 'h7' }}
        subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
      />
      <Divider />
      <CardContent style={{ flexGrow: 1 }}>
        {children}
      </CardContent>
    </Card>
  );
  
export default CardWrapper
