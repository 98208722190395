import React from 'react'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import BasicTabs from '../components/DetailTabPanel'
import OutcomeForm from '../components/complianceAssessment/OutcomesForm'

export default function DetailHalfPage ({ open, setOpen, selectedData, endpoint, selectedTargetData, selectedSubInstance }) {
  let basicTabs
  if (endpoint === 'outcomes') {
    basicTabs = <OutcomeForm selectedSubInstance={selectedSubInstance} outcomes={selectedData} setOpen={setOpen} data={selectedTargetData} ></OutcomeForm>
  } else {
    basicTabs = <BasicTabs endpoint={endpoint} id={selectedData}></BasicTabs>
    { /* <ObjectDetailTabs id={selectedData} endpoint={endpoint} open={open} setOpen={setOpen} />
 */ }
  }
  if (selectedData) {
    return (
        <div>
            <Drawer ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '75%'
                    // marginTop: "64px",
                  }
                }}
            anchor="right" open={open} variant="temporary">
                <Toolbar />
                <Box>
                    <IconButton onClick={() => setOpen(false)}>
                          <CloseIcon />
                    </IconButton>
                </Box>
                {basicTabs}
            </Drawer>
        </div>
    )
  }
}
