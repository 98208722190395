import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, Button } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector'
function ImpactLevel ({ resourceName, id, instance_id }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { getCompliance: getSources, compliance: sources, updateCompliance: updateRiskInstance, actionResource } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Impact instance' })
  const { getCompliance: getCatInstance, compliance: catInstance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })
  const [refreshKey, setRefreshKey] = useState(0)
  const updateParentState = (newValue) => {
    setRefreshKey(newValue)
  }
  const endpointUrl = `risk-assessment-instances/${instance_id}/update-criteria-instance/`

  useEffect(() => {
    if (instance_id) {
      const url = instance_id + '/impact-instances'
      getSources(url)
    }
  }, [instance_id, getSources])

  useEffect(() => {
    if (instance_id) {
      const url = instance_id + '/impact-instances'
      getSources(url)
    }
  }, [refreshKey])

  const handleCharClick = (id) => {
    getCatInstance(id)
    setIsDrawerOpen(true)
  }
  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  return (
        <>
        <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '40%',
                    marginTop: '64px'
                  }
                }}
            anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
      <div>
        <IconButton onClick={closeDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Box p={2} sx={{ flexGrow: 1 }}>
      {catInstance
        ? <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance} setRefreshKey={updateParentState} endpoint={endpointUrl} />
        : null}
          </Box>
          </Drawer>
            <MRTcustomized
                key={instance_id} // Use a unique key based on initialSelectedIds
                results={sources}
                enableRowActions={true}
                enableRowSelection={false}
      renderRowActions={ ({ row }) => (
        <Box>
          <Button variant="contained" color='secondary' onClick={() => handleCharClick(row.original.level)}>Level</Button>

        </Box>
      )}
            />
        </>
  )
}

export default ImpactLevel
