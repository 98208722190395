import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const CustomFieldTemplate = ({
  id,
  classNames,
  label,
  help,
  required,
  description,
  errors,
  children,
}) => {
  return (
    <Grid className={classNames} sx={{ mb: 3 }}>
      {label && (
        <Typography variant="h6" component="label" htmlFor={id} sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {required && (
            <Box component="span" sx={{ color: 'red', ml: 0.5, mt: -0.5 }}>
              *
            </Box>
          )}
        </Typography>
      )}
      {description && description !== '' && (
        <Typography variant="body2" component="div" color="textSecondary" sx={{ mb: 2 }}>
          {description}
        </Typography>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {children}
      </div>
      {errors}
      {help}
    </Grid>
  );
};

export default CustomFieldTemplate;
