import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import RiskHeatmap
 from 'src/components/Risk/Metrics/RiskHeatmap';
import RiskLevels from 'src/components/Risk/Metrics/RiskLevels';
import StakeHoldermetric from 'src/components/Risk/Metrics/StakeHoldermetric';
import CardWrapper from 'src/components/BaseLayout/CardWrapper';
import VulnerabilityMetrics from 'src/components/Risk/Metrics/VulnerabilityMetrics';
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import { useParams } from 'react-router-dom'; // Import if using React Router
import TrendMetrics from 'src/components/Risk/Metrics/TrendMetrics';
import CustomFilter from 'src/components/General/CustomFilter';
import { Card, CardContent } from '@mui/material';



export default function MonitorPage() {
    const { getComplianceList: getOrganizations, complianceList: organizations, } = useRequestCompliance({ endpoint: 'organizations', resourceLabel: 'Organizations' })
    const { getComplianceList: getMissions, complianceList: missions, } = useRequestCompliance({ endpoint: 'missions', resourceLabel: 'Missions' })
    const { getComplianceList: getInfoSystems, complianceList: informationSystems, } = useRequestCompliance({ endpoint: 'information-systems', resourceLabel: 'Information systems' })

    const { getComplianceList: getRiskMetric, complianceList: riskMetrics, } = useRequestCompliance({ endpoint: 'riskinstancemetrics', resourceLabel: 'Risk Instance metrics' })
    const { getComplianceList: getVulnMetric, complianceList: vulnMetrics, } = useRequestCompliance({ endpoint: 'vulnerabilityinstancemetrics', resourceLabel: 'Vulnerability metrics' })
    const { getComplianceList: getSHMetric, complianceList: shMetrics, } = useRequestCompliance({ endpoint: 'stakeholders/average_threat_level', resourceLabel: 'Stakeholder metrics' })

    const { id } = useParams(); // Use this line if id comes from URL parameters
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [missionOptions, setMissionOptions] = useState([]);
    const [informationSystemOptions, setInformationSystemOptions] = useState([]);

    // Declaration of states and hooks as before
    const [selectedFilters, setSelectedFilters] = useState({
      organization: null,
      mission: [],
      informationSystem: [],
    });
    // Handle selection changes including updating selectedFilters state
    const handleSelectionChange = (selections) => {
      const filter = {};
        setSelectedFilters(prev => ({
          ...prev,
          ...selections
      }));
      // For organization, directly use the selected ID
      if (selections.organization) {
        filter['organization'] = selections.organization.value;
      }
      
      // For missions, since they are multi-select, collect their IDs
      if (selections.mission.length > 0) {
        // Assuming 'selections.mission' is an array of selected option objects
        const missionIds = selections.mission.map(option => option.value).join(',');
        filter['missions'] = missionIds;
      }
      
      // For information systems, do the same as for missions
      if (selections.informationSystem.length > 0) {
        const systemIds = selections.informationSystem.map(option => option.value).join(',');
        filter['information_systems'] = systemIds;
      }
      // Now recall the functions with the constructed query string
      // Example: getRiskMetric(`/endpoint?${queryString}`);
      getRiskMetric(filter);
      getVulnMetric(filter);
      getSHMetric(filter);
    };
    

    // New useEffect hook to fetch missions based on the selected organization
    useEffect(() => {
      if (selectedFilters.organization) {
          // Assuming your useRequestCompliance hook can accept filters
          getMissions({ organization: selectedFilters.organization.value });
      }
  }, [selectedFilters.organization, getMissions]);
    // When missions data is fetched or changed, update the mission options
    useEffect(() => {
      if (missions.results) {
          const formattedOptions = missions.results.map(mission => ({ value: mission.id, label: mission.title }));
          setMissionOptions(formattedOptions);
      }
  }, [missions]);
    useEffect(() => {
      getOrganizations();
    }, [getOrganizations]);
    useEffect(() => {
      if (organizations.results) {
          const formattedOptions = organizations.results.map(org => ({ value: org.id, label: org.title }));
          setOrganizationOptions(formattedOptions);
      }
  }, [organizations]);
    // Fetch information systems based on selected organization and missions
    useEffect(() => {
      const filter = {};
      if (selectedFilters.organization) {
          filter.organization = selectedFilters.organization.value;
      }
      if (selectedFilters.mission.length > 0) {
          filter.missions = selectedFilters.mission.map(m => m.value).join(',');
      }
      getInfoSystems(filter);
  }, [selectedFilters.organization, selectedFilters.mission, getInfoSystems]);


    // Update information system options based on fetched data
    useEffect(() => {
        if (informationSystems.results) {
            const formattedOptions = informationSystems.results.map(sys => ({ value: sys.id, label: sys.title }));
            setInformationSystemOptions(formattedOptions);
        }
    }, [informationSystems]);

    useEffect(() => {
      let filter = '';
        if (id) {
            // Constructing an object for filters
           filter = { risk_assessment_instance: id };
           getRiskMetric(filter); // Pass the filter object directly
           getVulnMetric(filter);
           getSHMetric(filter);
        }
    }, [id, getRiskMetric,getVulnMetric,getSHMetric]);

    return (
      <Grid justifyContent="space-between" >
      <Typography variant="h5" gutterBottom>
          Risk Posture
        </Typography>
        {!id && (
      <Card >
        <CardContent >
          <CustomFilter
            organizationOptions={organizationOptions}
            missionOptions={missionOptions}
            informationSystemOptions={informationSystemOptions}
            onSelectionChange={handleSelectionChange}
          />
        </CardContent>
      </Card>
    )}
        {/* Incorporate CustomSelectionComponent */}
    <Grid  justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
        {/* Risk Overview Section */}
        <Typography variant="h6" gutterBottom>
          Risk Instances
        </Typography>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 0 }}>
          <Grid item xs={2} md={2}>
            <CardWrapper title="Risk Levels" subheader="Overview of current risk levels">
              <RiskLevels data={riskMetrics?.results?.risk_level_counts || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={2} md={2}>
            <CardWrapper title="Residual Risk Levels" subheader="Overview of residuel risk levels">
              <RiskLevels data={riskMetrics?.results?.residual_risk_level_counts || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={8} md={8}>
          <CardWrapper title="Risk Instances status" subheader="Monthly Opened and closed risk instances">
              <TrendMetrics data={riskMetrics?.results?.risks || {}}/>
            </CardWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
        <Grid item xs>
            <CardWrapper title="Current Risk Heatmap" subheader="Visualization of current impacts and likelihood">
              <RiskHeatmap data={riskMetrics?.results?.impact_likelihood || {}} />
            </CardWrapper>
          </Grid>
          <Grid item xs>
            <CardWrapper title="Residual Risk Heatmap" subheader="Visualization of residual impacts and likelihood" >
              <RiskHeatmap data={riskMetrics?.results?.residual_impact_likelihood || {}} />
            </CardWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
        <Grid item xs={6} md={2}>
            <CardWrapper title="Risk responses by type" subheader="Overview of all the taken reponses by type">
              <RiskLevels data={riskMetrics?.results?.threat_response_counts || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={6} md={2}>
            <CardWrapper title="Risk measures by status" subheader="Overview of all the identified measures">
              <RiskLevels data={riskMetrics?.results?.security_measure_counts_by_status || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardWrapper title="Risk measures Trend" subheader="Monthly risk measures created vs completed">
              <TrendMetrics data={riskMetrics?.results?.measures || {}}/>
            </CardWrapper>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          Vulnerabilities
        </Typography>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 0 }}>
          <Grid item xs>
            <CardWrapper title="Vulnerabilities" subheader="Metrics specific to Vulnerabilities">
              <VulnerabilityMetrics data={vulnMetrics?.results}/>
            </CardWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 2 }}>
          <Grid item xs>
            <CardWrapper title="Vulnerabilities Discoveries vs Resolutions" subheader="Monthly Vulnerabilities discoveries and resolutions">
              <TrendMetrics data={vulnMetrics?.results}/>
            </CardWrapper>
          </Grid>
        </Grid>
        {/* Stakeholder Section */}
        <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
          Stakeholder Threats
        </Typography>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ marginTop: 0 }}>
          <Grid item xs={12} md={6} >
            <CardWrapper title="Current Stakeholder Threat" subheader="Metrics specific to stakeholders current threat to the object scope">
              <StakeHoldermetric data={shMetrics?.results?.average_overall_threat_levels  || {}}/>
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardWrapper title="Residual Stakeholder Threat" subheader="Metrics specific to stakeholders residual threat to the object scope">
              <StakeHoldermetric data={shMetrics?.results?.average_residual_overall_threat_levels  || {}} />
            </CardWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ marginTop: 2 }}>
        <Grid item xs={6} md={2}>
            <CardWrapper title="Stakeholders responses by type" subheader="Overview of all the taken reponses by type">
              <RiskLevels data={shMetrics?.results?.threat_response_counts || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={6} md={2}>
            <CardWrapper title="Threat measures by status" subheader="Overview of all the identified measures">
              <RiskLevels data={shMetrics?.results?.security_measure_counts_by_status || {}}  />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardWrapper title="Risk measures Trend" subheader="Monthly threat measures created vs closed">
              <TrendMetrics data={shMetrics?.results?.measures || {}}/>
            </CardWrapper>
          </Grid>
        </Grid>
        {/* Additional Sections */}
        {/* Follow the same structure for other sections like Threat Sources, Vulnerabilities, etc. */}
  
      </Grid>
    );
  }