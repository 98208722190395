import React from 'react';
import { Grid, IconButton, Paper, Box } from '@mui/material';
import { Delete } from '@mui/icons-material';

const CustomObjectFieldTemplate = (props) => {
    
  return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          {props.properties.map(prop => (
            <Box key={prop.content.key}>
              {prop.content}
            </Box>
          ))}
        </Grid>
        {props.canRemove && (
          <Grid item>
            <IconButton
              aria-label="Delete"
              onClick={props.onDropPropertyClick(props.index)}
              disabled={props.disabled || props.readonly}
            >
              <Delete />
            </IconButton>
          </Grid>
        )}
      </Grid>
  );
};

export default CustomObjectFieldTemplate;
