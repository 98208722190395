import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

const DetailedProgramView = () => {
  const { id } = useParams(); // Get the id from the route parameters
  const { getCompliance, compliance: programDetails } = useRequestCompliance({ endpoint: 'compliance/programs' });

  useEffect(() => {
    if (id) {
      getCompliance(id); // Fetch the program details using the id
    }
  }, [id, getCompliance]);

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* Title Section */}
        <Box p={2} sx={{ flexGrow: 1 }}>
          <Typography variant="h5">
            Program Detail
          </Typography>
          <Divider />
        </Box>

        {/* Main Content */}
        {programDetails ? (
          <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 64px)' }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {programDetails.title}
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    {programDetails.description}
                  </Typography>
                  <Divider />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Type"
                        secondary={programDetails.type}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Start Date"
                        secondary={new Date(programDetails.start_date).toLocaleDateString()}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="End Date"
                        secondary={new Date(programDetails.end_date).toLocaleDateString()}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Risks"
                        secondary={programDetails.risks}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Resources"
                        secondary={programDetails.resources}
                      />
                    </ListItem>
                  </List>
                  <Divider />
                  <Typography variant="h6" gutterBottom mt={2}>
                    Associated Information Systems
                  </Typography>
                  <List>
                    {programDetails.information_systems_titles.map((title, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={title}
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <Typography variant="h6" gutterBottom mt={2}>
                    Associated Standards
                  </Typography>
                  <List>
                    {programDetails.standards_titles.map((title, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={title}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Loading Program Details...
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailedProgramView;
