import React, { useEffect, useState } from 'react'
import CollapsibleTable from './ColTable'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import {
  Typography
} from '@mui/material'
import { useParams } from 'react-router-dom'
/* function Merge_instances_to_meta(assessmentInstance) {
    if(assessmentInstance)
} */
export default function ComplianceAssessmentDetails () {
  const { id } = useParams()
  const { compliance, getCompliance } = useRequestCompliance({ endpoint: 'complianceAssessmentDetail', resourceLabel: 'compliance assessment detail' })
  const [selectedItem, setSelectedItem] = useState(null)
  const [standards, setStandards] = useState([])

  const [value, setValue] = useState(0)
  useEffect(() => {
    getCompliance(id)
  }, [getCompliance, id])
  useEffect(() => {
    if (compliance !== null && compliance !== undefined) {
      const results = Object.values(compliance)[1]
      const initstandards = Object.values(results)[6]
      setStandards(Object.values(results)[6])
      setSelectedItem(Object.values(initstandards[0])[8])
    }
  }, [compliance])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (standards !== []) {
      setSelectedItem(Object.values(standards[newValue])[8])
    }
  }

  if (compliance) {
    return (
        <>
        <Typography variant="h4" gutterBottom>
            Compliance assessment detail {id}
        </Typography>
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs"
            initialSelectedIndex={0}
          >
            {standards && standards.map((item) => // Check if standards exists
                    <Tab label={item.title} />
            )}
        </Tabs>
        <Typography variant="h4" gutterBottom>
        </Typography>
         <CollapsibleTable data={selectedItem} controlsinstances={compliance.controlsinstances} ></CollapsibleTable>
         </>

    )
  } else {
    return <Typography>No data available</Typography>
  }
}
