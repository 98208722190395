import React, { useContext, useState } from 'react'
import { Avatar, Card, Stack, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import RepliesSection from './RepliesSection'
import ConfirmDelete from './ConfirmDelete'
import Username from './Reusable/Username'
import CreatedAt from './Reusable/CreatedAt'
import CommentText from './Reusable/Comment/CommentText'
import EditableCommentField from './Reusable/Comment/EditableCommentField'
import EditButton from './Reusable/Buttons/TextButtons/EditButton'
import DeleteButton from './Reusable/Buttons/TextButtons/DeleteButton'
import ReplyButton from './Reusable/Buttons/TextButtons/ReplyButton'
import UpdateButton from './Reusable/Buttons/BgButtons/UpdateButton'
import { deepPurple } from '@mui/material/colors'
import moment from 'moment'

const Comment = ({ onPass, actuser }) => {
  const { id, content, created_at, score, replies, user } = onPass
  const userName = user.username
  const [comRepClicked, setComRepClicked] = useState(false)
  const [editingComm, setEditingComm] = useState(false)
  const [commentText, setCommentText] = useState(content)
  const [openModal, setOpenModal] = useState(false)
  const [tar, setTar] = useState(userName)

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
      <><ConfirmDelete onOpen={openModal} onClose={handleClose} id={id} />
      <Card >
      <Box sx={{ p: '15px' }} >
        <Stack spacing={2} direction="row">
          <Box >
            <Stack
              spacing={{ xs: 1, sm: 1, md: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack spacing={{ xs: 1, sm: 1, md: 4 }} direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems="center" >
                <Avatar sx={{ bgcolor: deepPurple[400] }}>{userName.charAt(0)}</Avatar>
                <Username userName={userName} actuser={actuser.username}/>
                <CreatedAt createdAt={moment(created_at).fromNow()} />
              </Stack>
              {userName === actuser.username
                ? (
                <Stack direction="row" spacing={1}>
                  <DeleteButton functionality={() => handleOpen()} />
                  <EditButton
                    functionality={() => setEditingComm(!editingComm)}
                    editingComm={editingComm} />
                </Stack>
                  )
                : (
                <ReplyButton functionality={() => { setComRepClicked(!comRepClicked); console.log('dazddadzd'); setTar(userName) }} />
                  )}
            </Stack>
            {editingComm
              ? (
              <>
                <EditableCommentField
                  commentText={commentText}
                  setCommentText={setCommentText}
                  placeHolder="Don't leave this blank!" />
                <UpdateButton
                  commentText={commentText}
                  editingComm={editingComm}
                  setEditingComm={setEditingComm}
                  id={id} />
              </>
                )
              : (
              <CommentText commentText={commentText} />
                )}
          </Box>
        </Stack>
      </Box>
    </Card>
      {replies
        ? (
        <RepliesSection
          onReplies={replies}
          comRepClicked={comRepClicked}
          onTar={tar}
          actuser={actuser}
          commid={id}
        />
          )
        : null}
      </>
  )
}
export default Comment
