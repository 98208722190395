// Flatten nested objects to a single level
const flatten = (obj, prefix = '') => {
  let flatObject = {}

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      flatObject = { ...flatObject, ...flatten(value, `${prefix}${key}_`) }
    } else {
      flatObject[`${prefix}${key}`] = value
    }
  }

  return flatObject
}

// Function to download CSV from JSON
export const downloadCSV = (data, filename) => {
  let jsonArray

  // If data is an object, convert it to an array containing that object
  if (Array.isArray(data)) {
    jsonArray = data
  } else if (typeof data === 'object' && data !== null) {
    jsonArray = [data]
  } else {
    console.error('Invalid input: Neither an array nor an object')
    return
  }

  const flattenedArray = jsonArray.map(item => flatten(item))

  // Extract column headers
  const headers = Object.keys(flattenedArray[0])

  // Extract data rows
  const rows = flattenedArray.map(row =>
    headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')
  )

  // Format as CSV string
  const csvString = headers.join(',') + '\n' + rows.join('\n')

  // Create Blob
  const blob = new Blob([csvString], { type: 'text/csv' })

  // Create and configure the download link
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'data.csv'
  a.click()

  // Release the URL
  window.URL.revokeObjectURL(url)
}

// Helper function to escape quotes
const replacer = (key, value) => value === null ? '' : value

// More utility functions can be added
export const downloadJSON = (data, filename) => {
  // Check if the provided data is an array or object
  if (typeof data !== 'object' || data === null) {
    console.log(data)
    console.error('The provided data is not a valid object or array')
    return
  }

  // Stringify the data
  const jsonString = JSON.stringify(data, null, 2) // Pretty formatting with 2 spaces

  // Generate blob from string
  const blob = new Blob([jsonString], { type: 'application/json' })
  const url = window.URL.createObjectURL(blob)

  // Create and configure the download link
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'data.json'
  a.click()

  // Release the URL
  window.URL.revokeObjectURL(url)
}
