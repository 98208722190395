import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, Button } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

function ImpactThreatEvent ({ resourceName, id, instance_id }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { getCompliance: getEvents, compliance: events, actionResource } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Threat source instance' })
  const { getComplianceList: getSources, complianceList: sources } = useRequestCompliance({ endpoint: 'impact', resourceLabel: 'Impact' })
  const { getCompliance: getThreatEventInstance, compliance: threatEventInstance, updateCompliance: updateAssociation } = useRequestCompliance({ endpoint: 'threat-event-instances', resourceLabel: 'Threat event instance' })

  const [selectedIds, setSelectedIds] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [lastSelectedIds, setLastSelectedIds] = useState([])
  const [refreshKey, setRefreshKey] = useState(0)
  const updateParentState = (newValue) => {
    setRefreshKey(newValue)
  }
  useEffect(() => {
    if (instance_id) {
      const url = instance_id + '/threat-event-instances'
      getEvents(url)
      getSources()
    }
  }, [instance_id, getSources, getEvents])

  useEffect(() => {
    if (instance_id) {
      const url = instance_id + '/threat-event-instances'
      getEvents(url)
    }
  }, [refreshKey])
  useEffect(() => {
    setSelectedIds(threatEventInstance?.impact_ids)
  }, [threatEventInstance, setSelectedIds])

  const handleCharClick = (id) => {
    /*  getCatInstance(id) */
    getThreatEventInstance(id)
    setSelectedId(id)
    setIsDrawerOpen(true)
  }
  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  const handleSelectedIdsChange = (newSelectedIds) => {
    setLastSelectedIds(newSelectedIds)
  }
  const handleAssociateClick = () => {
    const payload = {
      impact_ids: lastSelectedIds,
      threat_event_instance_id: selectedId
    }
    actionResource(instance_id, payload, 'associate-event-to-impacts/')
    setRefreshKey(prevKey => prevKey + 1)
  }

  return (
        <>
        <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '40%',
                    marginTop: '64px'
                  }
                }}
            anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
      <div>
        <IconButton onClick={closeDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Box p={2} sx={{ flexGrow: 1 }}>
      {sources.results
        ? <MRTcustomized
          key={JSON.stringify(selectedIds)} // Use a unique key based on initialSelectedIds
          results={sources.results}
          enableRowSelection={true}
          initialSelectedIds={selectedIds}
          onSelectedIdsChange={handleSelectedIdsChange}
      /> : null}
      <Button variant="contained" color="secondary" onClick={() => handleAssociateClick()}>
                            Finish
                        </Button>
        </Box>
          </Drawer>
            <MRTcustomized
                key={instance_id} // Use a unique key based on initialSelectedIds
                results={events}
                enableRowActions={true}
                enableRowSelection={false}
      renderRowActions={ ({ row }) => (
        <Box>
          <Button variant="contained" color='secondary' onClick={() => handleCharClick(row.id)}>Impacts</Button>
        </Box>
      )}
      columnOrder={['mrt-row-actions', 'impacts', 'event', 'sources']}
            />
        </>
  )
}

export default ImpactThreatEvent
