import React, { useState, useEffect, useMemo } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'
import { Box, IconButton, Stack, Button, MenuItem } from '@mui/material'

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word.slice(0, -1)}ies`
  }
  return `${word}s` // simple pluralization
}


// Utility function to parse a filter string and prefix its keys
const parseAndPrefixFilter = (filterString, prefix) => {
  return filterString?.split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=')
    result[`${prefix}_${key}`] = value
    return result
  }, {})
}

function Edit ({ model, instance_id, model1, model2, assessments, filter1, filter2, refreshKey, updateParentState  }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pluralModel2Name = pluralize(model2?.model)

  const [selectedSubInstance, setSelectedSubInstance] = useState(null)
  const [currentForm, setCurrentForm] = useState(null);

  const { getChildrenByContentType, complianceList: listResources } = useRequestCompliance({ endpoint: model, resourceLabel: model })

  const [internalrefreshKey, setInsternalRefreshKey] = useState(0)

  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')

    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata)
  }, [instance_id, refreshKey, getChildrenByContentType, internalrefreshKey])

  const handleStatus = (row) => {
    setSelectedSubInstance(row);
    setCurrentForm('statusChange'); // Another form or action
    setIsDrawerOpen(true);
};

  useEffect(() => {
    updateParentState()
  }, [internalrefreshKey])


  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  // Determine if criteria_titles exist and should be included in the column order
  const dynamicColumnOrder = useMemo(() => {
    // Start with your base column order
    let baseColumnOrder = [
      'mrt-row-expand',
      'mrt-row-actions',
      'title',
      'description',
      'group',
    ];

    // Check if there are results and criteria_titles is available
    if (listResources.results.length > 0 && listResources.results[0].criteria_titles) {
      // Assuming criteria_titles is an array of title strings you want to add as columns
      // Concatenate them with the baseColumnOrder
      return baseColumnOrder.concat(listResources.results[0].criteria_titles);
      
    }
    // If no criteria_titles or no results, return the base column order
    return baseColumnOrder;
  }, [listResources.results]);
    return (
        <>                   
        <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '90%',
            marginTop: '64px'
          }
        }}
    anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
<div>
<IconButton onClick={closeDrawer}>
  <ChevronRightIcon />
</IconButton>
</div>
<Box p={2} sx={{ flexGrow: 1 }}>
  {currentForm === 'statusChange' && selectedSubInstance && (
    <MultiStepResourceForm
      key={selectedSubInstance?.id}
      id={selectedSubInstance?.id}
      initialData={selectedSubInstance} // Assuming this form takes initial data
      resourceName={model2? model2.model: model1.model}
      setRefreshKey={setInsternalRefreshKey}
      partialUpdateProperties={assessments}
    />
  )}
</Box>

  </Drawer>

            <MRTcustomized
  key={`MRT-${instance_id}-${model1?.id}-${model2?.id}`}
  results={listResources.results}
    enableRowSelection={false}
    enableRowActions={true}
    enableDetailPanel={true}
    nestedObjectName ={pluralModel2Name}
    warnOnEmptyFields={(listResources?.results?.length > 0) ? listResources.results[0].criteria_titles : null} // Adjust based on your needs
    columnOrder={dynamicColumnOrder}
    {...(model2
      ? {
          renderMenuItems: (row) => [
            <Stack key={`stack-${model2?.id}-${row.id}`} direction="column" spacing={1}>
            <Button size="small" key={`status-${model1?.id}-${row.id}`} variant="outlined" color="secondary" onClick={() => handleStatus(row)}>
              Edit
            </Button>
          </Stack>
          ]
        }
      : {
          renderRowActionMenuItems: ({ row }) => [
          <MenuItem key={`change-status-${row.id}`} onClick={() => handleStatus(row.original)}>
            Edit
          </MenuItem>
          ]
        }
    )}
    
/>

        </>
  )
}

export default Edit
