import React, { useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'

const ProcessNavigation = ({ steps, onItemSelect }) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const theme = useTheme()

  const handleItemSelect = (type, item) => {
    setSelectedItem({ type, item })
    onItemSelect(type, item)
  }

  const isSelected = (type, item) => {
    return selectedItem && selectedItem.type === type && selectedItem.item.title === item.title
  }
  const selectedStyle = {
    backgroundColor: theme.palette.selection.main,
    borderLeft: `4px solid ${theme.palette.primary.main}`, // Vertical divider style
    fontWeight: '600'
  }
  return (
    <Box borderRight={1} borderColor="divider" width={240}>
      <List>
        {steps.map((step) => (
          <div key={step.title}>
            <ListItem
              button
              onClick={() => handleItemSelect('step', step)}
              style={isSelected('step', step) ? selectedStyle : { paddingLeft: 0 }}

            >
              <ListItemText
                primary={step.title}
              />
            </ListItem>
            {step.tasks.map(task => (
              <ListItem
                key={task.title}
                button
                onClick={() => handleItemSelect('task', task)}
                style={isSelected('task', task) ? selectedStyle : { paddingLeft: 30 }}
              >
                <ListItemText
                  primary={task.title}
                />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </Box>
  )
}

export default ProcessNavigation
