import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table';
import {
  Stack,
  Tooltip,
} from '@mui/material';

const MRTcustomizedOriginal = ({
  results = [],
  initialSelectedIds = [],
  enableRowActions,
  enableRowSelection = true,
  renderRowActions,
  onSelectedIdsChange,
  enableMultiRowSelection,
  firstColumnAccessor,
  columnOrder,
  filterBycolumnOrder = false,
  renderRowActionMenuItems,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const isInitialSelectionSet = useRef(false);
  const [pinnedRows, setPinnedRows] = useState([]);


  // Set the initial row selection state and pinned rows
  useEffect(() => {
    if (!isInitialSelectionSet.current && results.length > 0) {
      const initialRowSelectionState = Array.isArray(initialSelectedIds)
        ? initialSelectedIds.reduce((acc, currId) => {
          acc[currId] = true;
          return acc;
        }, {})
        : { [initialSelectedIds]: true };

      setRowSelection(initialRowSelectionState);
        if (enableMultiRowSelection === false && initialSelectedIds !== []) {
            if (initialSelectedIds.length !== 0)
            {
                setPinnedRows([initialSelectedIds]);
            }
            else{
               
            }
        } else {
            setPinnedRows(initialSelectedIds);
        }      
        isInitialSelectionSet.current = true; // Set to true after initial selection is set
    }
  }, [initialSelectedIds, results]);


  // Handle row selection change and update pinned rows
  const handleRowSelectionChange = (newRowSelection) => {
    setRowSelection(prev => {
      const updatedSelection = typeof newRowSelection === 'function' ? newRowSelection(prev) : newRowSelection;
      const selectedIdsArray = Object.entries(updatedSelection)
        .filter(([key, value]) => value)
        .map(([key]) => Number(key));

      setPinnedRows(selectedIdsArray);

      if (enableRowSelection && onSelectedIdsChange) {
        if (enableMultiRowSelection === false) {
          onSelectedIdsChange(selectedIdsArray[0]);
        } else {
          onSelectedIdsChange(selectedIdsArray);
        }
      }

      return updatedSelection;
    });
  };

  const columns = useMemo(() => {
    if (!Array.isArray(results) || results.length === 0) return [];
    const sample = results[0];

    const columnList = Object.keys(sample).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1),
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span style={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        </Tooltip>
      )
    }));

    if (firstColumnAccessor) {
      const firstColumnIndex = columnList.findIndex(
        (column) => column.accessorKey === firstColumnAccessor
      );
      if (firstColumnIndex !== -1) {
        const firstColumn = columnList.splice(firstColumnIndex, 1);
        columnList.unshift(...firstColumn);
      }
    }

    // Exclude columns with nested data (for example, objects or arrays)
    const filteredColumns = columnList.filter((column) => {
      const value = sample[column.accessorKey];
      return !Array.isArray(value) && typeof value !== 'object';
    });

    return filteredColumns;
  }, [results, firstColumnAccessor]);

  const tableColumns = useMemo(() => {
    if (!Array.isArray(results) || results.length === 0) return [];

    if (columnOrder && filterBycolumnOrder && columnOrder.length > 0) {
      const columnKeysSet = new Set(columnOrder);

      const filteredColumns = columns.filter((column) =>
        columnKeysSet.has(column.accessorKey)
      );

      return filteredColumns;
    } else {
      return columns;
    }
  }, [results, columns, columnOrder, filterBycolumnOrder]);

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: results,
    enablePagination: true,
    enableRowPinning: true,
    rowPinningDisplayMode: 'select-sticky',
    getRowId: (row) => row.id,
    enableGrouping: true,
    initialState: {
      columnOrder,
      columnVisibility: {
        id: false,
        created_by: false,
        created_at: false,
        updated_at: false
      },
      rowPinning: {
        top: pinnedRows,
      },
      pagination: {
        pageSize: 30, // Set the initial page size to 30 rows
      },
    },
    enableRowSelection,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    onRowSelectionChange: handleRowSelectionChange,
    state: {
      rowSelection,
      rowPinning: {
        top: pinnedRows,
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '800px'
      }
    },
    defaultColumn: {
      maxSize: 400,
      minSize: 20,
      size: 400
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowActions,
    renderRowActions,
    enableMultiRowSelection,
    renderRowActionMenuItems,
  });

  return (
    <Stack sx={{ m: '2rem 0' }}>
      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default MRTcustomizedOriginal;
