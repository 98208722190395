import React from 'react';
import { Grid } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';

const TrendMetrics = ({ data }) => {
  const theme = useTheme();

  const convertMonthlyDataForChart = (monthlyData) => {
    return monthlyData?.map(item => ({
        name: item.month,
        value: [item.month, item.count],
    }));
  };

  const getMonthlyTrendOption = () => {
    return {
      title: {
          text: 'Monthly Trends',
          left: 'center',
          textStyle: {
              color: theme.palette.text.primary,
          },
      },
      tooltip: {
          trigger: 'axis',
      },
      xAxis: {
          type: 'time',
          axisLabel: {
              color: theme.palette.text.primary,
          },
      },
      yAxis: {
          type: 'value',
          axisLabel: {
              color: theme.palette.text.primary,
          },
      },
      series: [
          {
              name: 'Created',
              type: 'line',
              data: convertMonthlyDataForChart(data.monthly_discoveries),
              itemStyle: {
                  color: theme.palette.riskLevels.high,
              },
              lineStyle: {
                  color: theme.palette.riskLevels.high,
                  width: 2,
              },
          },
          {
              name: 'Closed',
              type: 'line',
              data: convertMonthlyDataForChart(data.monthly_resolutions),
              itemStyle: {
                  color: theme.palette.riskLevels.veryLow,
              },
              lineStyle: {
                  color: theme.palette.riskLevels.veryLow,
                  width: 2,
                  type: 'dashed',
              },
              symbol: 'circle',
              symbolSize: 8,
          },
      ],
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
      },
    };
  };
  // Check if data is empty and render placeholder or chart
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReactECharts
          option={getMonthlyTrendOption()}
          style={{ height: '200px' }}
        />
      </Grid>
    </Grid>
  );
};

export default TrendMetrics;
