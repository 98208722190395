import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import SideMenu from './SideMenu';
import AppHeader from './AppHeader';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import LoadingOverlayResource from '../LoadingOverlayResource';
import BreadcrumbsNav from './BreadcrumbsNav'; // Import the BreadcrumbsNav component

function BaseLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <SideMenu mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <LoadingOverlayResource>
          <Box
            sx={{
              flexGrow: 1,
              padding: (theme) => theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Toolbar />
            <BreadcrumbsNav /> {/* Add the BreadcrumbsNav component here */}
            <Outlet />
          </Box>
        </LoadingOverlayResource>
      </Box>
    </Box>
  );
}

export default BaseLayout;
