import React, { useEffect, useState } from 'react';
import { Grid, Box, useTheme, Typography } from '@mui/material';
import Select from 'react-select';

function CustomFilter({ organizationOptions, missionOptions, informationSystemOptions, onSelectionChange }) {
  const [selectedValues, setSelectedValues] = useState({
    organization: null,
    mission: [],
    informationSystem: [],
  });

  const theme = useTheme(); // Use the theme hook

  const handleChange = (selectedOption, action) => {
    let newValue = selectedOption;

    if (action.name === 'organization') {
      // Reset missions and information systems when organization changes
      newValue = selectedOption;
      setSelectedValues({
        organization: newValue,
        mission: [],
        informationSystem: [],
      });
      onSelectionChange({
        organization: newValue,
        mission: [],
        informationSystem: [],
      });
    } else if (action.name === 'mission') {
      // Reset information systems when mission changes
      newValue = selectedOption || [];
      setSelectedValues(prev => ({
        ...prev,
        mission: newValue,
        informationSystem: [],
      }));
      onSelectionChange({
        ...selectedValues,
        mission: newValue,
        informationSystem: [],
      });
    } else if (action.name === 'informationSystem') {
      // Update information systems without resetting
      newValue = selectedOption || [];
      setSelectedValues(prev => ({
        ...prev,
        informationSystem: newValue,
      }));
      onSelectionChange({
        ...selectedValues,
        informationSystem: newValue,
      });
    }
  };

  // Custom styles for react-select components using theme values
  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: 240,
      margin: '0 8px',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderColor: state.hasValue ? theme.palette.secondary.main : theme.palette.grey,
      boxShadow: state.hasValue ? `0 0 0 1px ${theme.palette.secondary.main}` : 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? theme.palette.background.paper : theme.palette.text.primary,
      backgroundColor: state.isSelected ? theme.palette.secondary.main : theme.palette.background.paper,
      ':hover': {
        backgroundColor: theme.palette.secondary.light,
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.text.primary,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.palette.text.secondary,
    }),
  };

  return (
    <Grid container spacing={3} alignItems="center">
      {/* Organization Select */}
      <Grid item xs={12} sm={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" component="span" marginRight={1}>By organization:</Typography>
          <Select
            name="organization"
            options={organizationOptions}
            value={selectedValues.organization}
            onChange={(selectedOption) => handleChange(selectedOption, { name: 'organization' })}
            placeholder="Organization"
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </Box>
      </Grid>
      {/* Mission Select */}
      <Grid item xs={12} sm={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" component="span" marginRight={1}>By missions:</Typography>
          <Select
            isMulti
            name="mission"
            options={missionOptions}
            value={selectedValues.mission}
            onChange={(selectedOption) => handleChange(selectedOption, { name: 'mission' })}
            placeholder="Mission"
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </Box>
      </Grid>
      {/* Information System Select */}
      <Grid item xs={12} sm={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" component="span" marginRight={1}>By information systems:</Typography>
          <Select
            isMulti
            name="informationSystem"
            options={informationSystemOptions}
            value={selectedValues.informationSystem}
            onChange={(selectedOption) => handleChange(selectedOption, { name: 'informationSystem' })}
            placeholder="Information System"
            styles={selectStyles}
            menuPortalTarget={document.body}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CustomFilter;
