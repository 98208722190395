import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { NavLink, useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { GlobalStyles, useTheme } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import BlockIcon from '@mui/icons-material/Block';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FactCheckIcon from '@mui/icons-material/FactCheck'; // Import missing icon
import GppMaybeIcon from '@mui/icons-material/GppMaybe'; // Import missing icon
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';


const drawerWidth = 240;

const listItems = [
  {
    key: 'Governance',
    title: 'Governance',
    links: [
      {
        key: 'Organization',
        to: '/governance/organizations',
        name: 'Organizations',
        icon: <CorporateFareIcon />
      },
      {
        key: 'Strategies',
        to: '/governance/strategies',
        name: 'Strategies',
        icon: <GpsFixedIcon />
      },
      {
        key: 'Processes',
        to: '/governance/processes',
        name: 'Processes',
        icon: <ManageHistoryRoundedIcon />
      },
      {
        key: 'Responsibilities',
        to: '/governance/responsibilities',
        name: 'Responsibilities',
        icon: <GroupIcon />
      }
    ]
  },
  {
    key: 'Compliance',
    title: 'Compliance',
    links: [
      {
        key: 'Standards',
        to: '/compliance/standards',
        name: 'Standards',
        icon: <FactCheckIcon />
      },
      {
        key: 'Programs',
        to: '/compliance/programs',
        name: 'Programs',
        icon: <BeenhereIcon />
      },
      {
        key: 'Assessments',
        to: '/compliance/assessments',
        name: 'Assessments',
        icon: <AssessmentIcon />
      },
      {
        key: 'Monitoring',
        to: '/compliance/monitoring',
        name: 'Monitoring',
        icon: <VisibilityIcon />
      }
    ]
  },
  {
    key: 'Risk',
    title: 'Risk',
    links: [
      {
        key: 'Taxonomies',
        to: '/risk/taxonomies',
        name: 'Taxonomies',
        icon: <LibraryBooksIcon />
      },
      {
        key: 'Methodologies',
        to: '/risk/methodologies',
        name: 'Methodologies',
        icon: <GppMaybeIcon />
      },
      {
        key: 'Assessments',
        to: '/risk/assessments',
        name: 'Assessments',
        icon: <AssessmentIcon />
      },
      {
        key: 'Monitoring',
        to: '/risk/monitoring',
        name: 'Monitoring',
        icon: <VisibilityIcon />
      }
    ]
  },
  {
    key: 'Manage',
    title: 'Manage',
    links: [
      {
        key: 'Assets',
        to: '/manage/assets',
        name: 'Assets',
        icon: <InventoryIcon />
      },
      {
        key: 'Teams',
        to: '/manage/teams',
        name: 'Teams',
        icon: <GroupsIcon />
      },
      {
        key: 'RBAC',
        to: '/manage/rbac',
        name: 'RBAC',
        icon: <BlockIcon />
      }
    ]
  },
  {
    key: 'Settings',
    title: 'Settings',
    links: [
      {
        key: 'IAM',
        to: 'settings/IAM',
        name: 'IAM',
        icon: <PermIdentityIcon />
      },
      {
        key: 'Models',
        to: 'settings/models',
        name: 'Models',
        icon: <AccountTreeIcon />
      }
    ]
  }
];

const SidebarGlobalStyles = () => {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        '.sidebar-nav-item': {
          color: 'unset',
          textDecoration: 'none'
        },
        '.sidebar-nav-item-active': {
          textDecoration: 'none',
          color: theme.palette.primary.main,
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main
          },
          '& .MuiTypography-root': {
            fontWeight: 500,
            color: theme.palette.primary.main
          }
        }
      }}
    />
  );
};
const SidebarGlobalStylesMemo = React.memo(SidebarGlobalStyles);

export function SideMenu(props) {
  const { mobileOpen, setMobileOpen } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const drawer = (
    <Box sx={{ m: 2 }}>
      <Toolbar />
      <List>
        {listItems.map((section, sectionIndex) => (
          <React.Fragment key={section.key}>
            <Typography variant="h6" gutterBottom>
              {section.title}
            </Typography>
            <Divider />
            {section.links.map((link, linkIndex) => {
              const index = sectionIndex * 10 + linkIndex; // Unique index for each item
              return (
                <NavLink
                  end={link.to === '/'}
                  className={(navData) => navData.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}
                  to={link.to}
                  key={link.key}
                >
                  <ListItemButton
                    selected={location.pathname === link.to}
                    onClick={(event) => handleListItemClick(event, index)}
                  >
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.name} />
                  </ListItemButton>
                </NavLink>
              );
            })}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}>
      <SidebarGlobalStylesMemo />
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideMenu.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func.isRequired
};

export default SideMenu;
