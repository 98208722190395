import React, { useState, useMemo } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import 'src/static/css/EunoiaFlow.css'; // Import the CSS file

const MRTNested = ({ data, renderActionCell, renderTopToolbarCustomActions, onDeleteStandard }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteConfirm = () => {
    setOpenDialog(false);
    onDeleteStandard(); // Call the prop function to handle deletion
  };

  const columns = useMemo(() => [
    {
      id: 'actions',
      header: 'Actions',
      Cell: renderActionCell,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row, cell }) => (
        <Box className={`tree-branch ${row.depth > 0 ? 'nested' : ''}`} sx={{ display: 'flex', alignItems: 'center', pl: row.depth * 2 }}>
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} arrow>
          <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '300px' // Adjust the max width as needed
          }}>
            {cell.getValue()}
          </span>
        </Tooltip>
      ),
    },
  ], [renderActionCell]);

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableExpanding={true}
        getSubRows={(row) => row.subControls || []}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this standard? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MRTNested;
