import React, { useState, useEffect } from 'react'
import GlobalNavMenu from '../../components/General/GlobalNavMenu'
import StatCard from '../../components/StatCard'
import { Grid, Box } from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CheckIcon from '@mui/icons-material/Check'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import Typography from '@mui/material/Typography'
import DynamicTabsAndTables from '../../components/General/DynamicTabsAndTables'
import { downloadCSV, downloadJSON } from '../../components/General/Utils'
import { useNavigate } from 'react-router-dom'
import useRequestCompliance from 'src/hooks/useRequestCompliance'

const tabEndpoints = [
  { name: 'Role assignments', endpoint: 'rbac/assignements' },
  { name: 'Role definitions', endpoint: 'rbac/roles' },
  { name: 'Role permissions', endpoint: 'rbac/role_permissions' }
]

export default function StandardView () {
  const navigate = useNavigate()
  const getActionList = (complianceList, fileName) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'Add role assignment',
          action: () => navigate('/manage/rbac/role-assignments/add')
        },
        {
          name: 'Add role definition',
          action: () => navigate('/manage/rbac/role-definitions/add')
        },
        {
          name: 'Add role permission',
          action: () => navigate('/manage/rbac/role-permissions/add')
        }
      ]
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(complianceList.results, `${fileName}.json`)
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(complianceList.results, `${fileName}.csv`)
        }
      ]
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon'
    }
  ]

  const [complianceList, setComplianceList] = useState([])
  const [fileName, setFileName] = useState()
  const [refreshKey, setRefreshKey] = useState(0)
  const { getComplianceList: getKpi, complianceList: kpi } =
    useRequestCompliance({ endpoint: 'rbac/kpi' })
  useEffect(() => {
    getKpi()
  }, [getKpi, refreshKey])

  // Use the function here to get the action list
  const actionList = getActionList(complianceList, fileName)

  return (
    <>
          <Grid container spacing={0} justifyContent="space-between">
      <Grid >
      <Typography variant="h5" gutterBottom>
        EUNOIA RBAC
      </Typography>
      </Grid>
      </Grid>
      <GlobalNavMenu actionList={actionList}>
    <Box m={0} pt={3} sx={{
      flexGrow: 1,
      mb: (theme) => theme.spacing(2)
    }}>
            <Grid container spacing={2}>
                <StatCard
                    title="Total assignements"
                    value={kpi ? kpi.results.total_assignments: 'Loading...'}
                    loading={false}
                    icon={<AssignmentIcon fontSize="small" />}
                />
                <StatCard
                    title="Total roles"
                    value={kpi ? kpi.results.total_roles: 'Loading...'}
                    loading={false}
                    icon={<DataUsageIcon fontSize="small" />}
                />
                <StatCard
                    title="Total permissions"
                    value={kpi ? kpi.results.total_permissions: 'Loading...'}
                    loading={false}
                    icon={<CheckIcon fontSize="small" />}
                />
            </Grid>
        </Box>
            <DynamicTabsAndTables
        tabEndpoints={tabEndpoints}
        onDataLoaded={(data, filename) => {
          setComplianceList(data)
          setFileName(filename)
        }}
        refreshKey={refreshKey}
    />
    </GlobalNavMenu>
    </>
  )
}
