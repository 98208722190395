import React, { useState, useEffect } from 'react';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
  IconButton,
  Divider
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import Select from 'react-select';

function MatrixForm({ id }) {
  const { getComplianceList: getCategorizations, complianceList: categorizations } = useRequestCompliance({ endpoint: 'categorization_models_detail', resourceLabel: 'categorization models' });
  const { addCompliance: addModel, getCompliance: getModel, updateCompliance: updateModel, compliance: model } = useRequestCompliance({ endpoint: 'grid_matrices', resourceLabel: 'grid matrices' });

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    modelName: '',
    modelDescription: '',
    xModel: null,
    yModel: null,
    zModel: null,
    raciMatrix: {}
  });

  useEffect(() => {
    getCategorizations();
    if (id) {
      getModel(id);
    }
  }, [getCategorizations, getModel, id]);

  useEffect(() => {
    if (model) {
      const initialRaciMatrix = model.elements.reduce((acc, elem) => {
        const key = `${elem.row}-${elem.column}`;
        acc[key] = elem.value;
        return acc;
      }, {});
      setFormValues({
        modelName: model.title,
        modelDescription: model.description,
        xModel: model.x_categorization_model,
        yModel: model.y_categorization_model,
        zModel: model.z_categorization_model,
        raciMatrix: initialRaciMatrix
      });
    }
  }, [model]);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleModelChange = (selectedOption, axis) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [`${axis}Model`]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleRACIChange = (row, column, value) => {
    const key = `${row}-${column}`;
    setFormValues(prevValues => ({
      ...prevValues,
      raciMatrix: {
        ...prevValues.raciMatrix,
        [key]: value,
      }
    }));
  };

  const handleSubmit = () => {
    const elements = Object.entries(formValues.raciMatrix).map(([key, value]) => {
      const [row, column] = key.split('-').map(Number);
      return { row, column, value };
    });

    const submissionData = {
      title: formValues.modelName,
      description: formValues.modelDescription,
      x_categorization_model: formValues.xModel,
      y_categorization_model: formValues.yModel,
      z_categorization_model: formValues.zModel,
      elements
    };

    if (id) {
      updateModel(id, submissionData);
    } else {
      addModel(submissionData);
    }
  };

  const renderTable = () => {
    const xModel = categorizations.results.find(model => model.id === formValues.xModel);
    const yModel = categorizations.results.find(model => model.id === formValues.yModel);
    const zModel = categorizations.results.find(model => model.id === formValues.zModel);

    if (!xModel || !yModel || !zModel) return null;

    const xLevels = xModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];
    const yLevels = yModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];
    const zLevels = zModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title={xModel.criterias[0]?.description || ''}>
                  <Box display="flex" alignItems="center">
                    {xModel.criterias[0]?.title}
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Divider />
                <Tooltip title={yModel.criterias[0]?.description || ''}>
                  <Box display="flex" alignItems="center">
                    {yModel.criterias[0]?.title}
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </TableCell>
              {xLevels.map((level, index) => (
                <TableCell key={index}>
                  <Tooltip title={level.description || ''}>
                    <Box display="flex" alignItems="center">
                      {level.title}
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <InfoIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {yLevels.map((yLevel, yIndex) => (
              <TableRow key={yIndex}>
                <TableCell component="th" scope="row">
                  <Tooltip title={yLevel.description || ''}>
                    <Box display="flex" alignItems="center">
                      {yLevel.title}
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <InfoIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
                {xLevels.map((xLevel, xIndex) => (
                  <TableCell key={`${yIndex}-${xIndex}`}>
                    <Select
                      options={zLevels.map((level, zIndex) => ({ value: zIndex, label: level.title }))}
                      onChange={(selectedOption) => handleRACIChange(yIndex, xIndex, selectedOption ? selectedOption.value : null)}
                      value={zLevels.find((level, zIndex) => zIndex === formValues.raciMatrix[`${yIndex}-${xIndex}`]) ? { value: formValues.raciMatrix[`${yIndex}-${xIndex}`], label: zLevels[formValues.raciMatrix[`${yIndex}-${xIndex}`]].title } : null}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const steps = ['Basics', 'Select Models', 'Matrix'];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth label="Model Name" name="modelName" value={formValues.modelName} onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Model Description" name="modelDescription" value={formValues.modelDescription} onChange={handleChange} multiline rows={4} />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="xModel">X Axis Model</label>
                <Select
                  inputId="xModel"
                  options={categorizations.results.map(model => ({ value: model.id, label: model.title }))}
                  onChange={selectedOption => handleModelChange(selectedOption, 'x')}
                  value={categorizations.results.find(model => model.id === formValues.xModel) ? { value: formValues.xModel, label: categorizations.results.find(model => model.id === formValues.xModel)?.title } : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="yModel">Y Axis Model</label>
                <Select
                  inputId="yModel"
                  options={categorizations.results.map(model => ({ value: model.id, label: model.title }))}
                  onChange={selectedOption => handleModelChange(selectedOption, 'y')}
                  value={categorizations.results.find(model => model.id === formValues.yModel) ? { value: formValues.yModel, label: categorizations.results.find(model => model.id === formValues.yModel)?.title } : null}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="zModel">Z Axis Model</label>
                <Select
                  inputId="zModel"
                  options={categorizations.results.map(model => ({ value: model.id, label: model.title }))}
                  onChange={selectedOption => handleModelChange(selectedOption, 'z')}
                  value={categorizations.results.find(model => model.id === formValues.zModel) ? { value: formValues.zModel, label: categorizations.results.find(model => model.id === formValues.zModel)?.title } : null}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      case 2:
        return renderTable();
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {activeStep === steps.length ? (
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'center' }}>All steps completed</Typography>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default MatrixForm;
