import { Typography } from '@mui/material'
import React from 'react'
import YouTag from '../YouTag'

const Username = ({ userName, actuser }) => {
  return (
    <>
      <Typography fontWeight="bold" sx={{ color: 'neutral.darkBlue' }}>
        {userName}
      </Typography>
      {userName === actuser ? <YouTag /> : null}
    </>
  )
}

export default Username
