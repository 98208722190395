import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm' // Adjust the path if necessary

function ComplianceStepper ({ id }) {
  return (
        <div>
            <MultiStepResourceForm resourceName="compliance/programs" id={id || null} />
        </div>
  )
}

export default ComplianceStepper
