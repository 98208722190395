import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, Toolbar, Typography } from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import MRTcustomizedOriginal from '../BaseLayout/MRTcustomizedOriginal'; // Adjust the import path as necessary
import MultiStepResourceForm from '../Risk/MultiStepResourceForm';

const DynamicSelectWidget = ({ id, label, onChange, schema, value }) => {
  const { getComplianceList, complianceList: choices } = useRequestCompliance({ endpoint: schema.endpoint });
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    getComplianceList();
  }, [getComplianceList]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNewEntrySuccess = () => {
    getComplianceList();
    setDrawerOpen(false);
  };
  console.log(value)
  return (
    <div>
      <Box mb={2}>
        <Typography variant="body1" gutterBottom>
          You can create a new entry or add from the table below.
        </Typography>
        <Button color="primary" onClick={toggleDrawer}>
          Create New
        </Button>
        <MRTcustomizedOriginal
          results={choices.results}
          initialSelectedIds={value}
          enableRowSelection
          enableMultiRowSelection={schema.relationType === 'ManyToMany'}
          onSelectedIdsChange={onChange}
          hideActionsColumn={true}
        />
      </Box>
      <Drawer
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '50%'
          }
        }}
        anchor="right"
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar />
        <Box p={2}>
          <MultiStepResourceForm resourceName={schema.endpoint} id={null} onSubmitSuccess={handleNewEntrySuccess} />
        </Box>
      </Drawer>
    </div>
  );
};

export default DynamicSelectWidget;
