import React, { createContext, useState, useEffect } from 'react'
import { AuthContext } from './AuthContextProvider'
import useRequestCompliance from '../hooks/useRequestCompliance'

const CommentContext = createContext()
const data = [
  {
    id: 1,
    content: "Access management is crucial in cybersecurity. It's all about controlling who has access to what information and resources within an organization. Proper access controls can prevent unauthorized access and data breaches.",
    createdAt: '3 weeks ago',
    score: 8,
    user: {
      image: {
        png: './images/avatars/image-cyberexpert.png',
        webp: './images/avatars/image-cyberexpert.webp'
      },
      username: 'cyberexpert'
    },
    replies: []
  },
  {
    id: 2,
    content: 'Absolutely! Implementing the principle of least privilege is essential. Users should only have the minimum access necessary to perform their tasks. It reduces the attack surface and potential damage if an account is compromised.',
    createdAt: '2 weeks ago',
    score: 6,
    user: {
      image: {
        png: './images/avatars/image-securemind.png',
        webp: './images/avatars/image-securemind.webp'
      },
      username: 'securemind'
    },
    replies: [
      {
        id: 3,
        content: 'Also, consider multi-factor authentication (MFA). It adds an extra layer of security by requiring users to provide additional proof of identity, such as a one-time code sent to their phone.',
        createdAt: '1 week ago',
        score: 4,
        replyingTo: 'securemind',
        user: {
          image: {
            png: './images/avatars/image-infosecguru.png',
            webp: './images/avatars/image-infosecguru.webp'
          },
          username: 'infosecguru'
        }
      },
      {
        id: 4,
        content: 'Role-based access control (RBAC) is another effective method. It simplifies access management by assigning permissions based on job roles, making it easier to manage access for large organizations.',
        createdAt: '6 days ago',
        score: 3,
        replyingTo: 'securemind',
        user: {
          image: {
            png: './images/avatars/image-cyberdefender.png',
            webp: './images/avatars/image-cyberdefender.webp'
          },
          username: 'cyberdefender'
        }
      }
    ]
  }
]

const comments = data

export function CommentProvider ({ children, selectedSubInstance }) {
  const { user } = React.useContext(AuthContext)

  const { complianceList, getResourceAttribute } = useRequestCompliance({ endpoint: 'subControlInstance', resourceLabel: 'comment added' })
  const { addCompliance, deleteResource, updateCompliance } = useRequestCompliance({ endpoint: 'comments', resourceLabel: 'comment' })

  const [commentSection, setCommentSection] = useState([])
  const [changed, setChanged] = useState(false)
  useEffect(() => {
    getResourceAttribute(selectedSubInstance, 'subcontrolinstance_comments')
    setChanged(false)
  }, [getResourceAttribute, changed])

  useEffect(() => {
    if (Array.isArray(complianceList)) {
      setCommentSection(complianceList)
    }
  }, [complianceList])

  const addComment = (data, selectedSubInstance) => {
    addCompliance(
      {
        content: data,
        score: 0,
        subControlInstance: selectedSubInstance
      }
    )
    setChanged(true)
  }

  const deleteComment = (commentId) => {
    deleteResource(commentId)
    setChanged(true)
  }

  const updateComment = (commentText, id) => {
    updateCompliance(id, { content: commentText })
    setChanged(true)
  }
  const addReply = () => {
    setChanged(true)
  }

  const deleteReply = (id) => {}

  return (
    <CommentContext.Provider
      value={{
        user,
        commentSection,
        addComment,
        deleteComment,
        addReply,
        updateComment
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

export default CommentContext
