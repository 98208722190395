import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Tooltip, IconButton, Box } from '@mui/material';
import RoleAssignmentMultiStepForm from "../RBAC/MultiStepForms/RoleAssignmentMultiStepForm";
import RoleMultiStepForm from "../RBAC/MultiStepForms/RoleMultiStepForm";
import CloseIcon from '@mui/icons-material/Close';
import RolePermissionMultiStepForm from "../RBAC/MultiStepForms/RolePermissionMultiStepForm"; 
import { Stack } from '@mui/system';
import JsonEditor from '../../components/General/JsonEditor';
import ComplianceStepper from '../Compliance/MultiStepForms/ComplianceStepper';  
import AssetMultiStepForm from '../Governance/Organization/MultiStepForms/AssetMultiStepForm';
import ISMultiStepForm from '../Asset/MultiStepForms/ISMultiStepForm';
import PrimaryAssetMultiStepForm from '../Asset/MultiStepForms/PrimaryAssetMultiStepForm';
import SupportingAssetMultiStepForm from '../Asset/MultiStepForms/SupportingAssetMultiStepForm';
import MethodologyForm from '../Risk/Methodologies/MethodologyForm';
import MissiontMultiStepForm from '../Governance/Organization/MultiStepForms/MissionMultiStepForm';
import RiskAssessmentForm from 'src/pages/Risk/Assessments/MultiStepForms/RiskAssessmentForm';
import StrategyForm from '../Governance/Strategy/MultiStepForms/StrategyForm';
import ResponsibilityMultiStepForm from '../Governance/Responsibility/MultiStepForms/ResponsibilityMultiStepForm';
import ActivityMultiStep from '../Governance/Responsibility/MultiStepForms/ActivityMultiStep';
import GenericResourcePage from './GenericResourcePage';
import AssessmentForm from '../Compliance/MultiStepForms/AssessmentForm';
import MatrixForm from '../Settings/Models/MultiStepForm/MatrixForm';
import MultiProcess from '../Governance/Processes/MultiProcess';

const getTitleAndDescription = (type, resource, module, action) => {
  switch(action) {
    case 'json':
      return { title: 'Json file', description: `Add using a json file` };
  }
  console.log(`${module}-${type}`)
  switch(`${module}-${type}`) {
    case 'manage-role-assignments':
      return { title: 'Role Assignment', description: 'Assign roles to users and groups' };
    case 'manage-role-definitions':
      return { title: 'Role Definition', description: 'Define a new role for your scopes' };
    case 'manage-role-permissions':
      return { title: 'Role Permission', description: 'Add new permissions for your EUNOIA models and objects' };
    case 'compliance-programs':
      return { title: "Compliance program", description: "Manage your compliance program" };
    case 'compliance-assessments':
      return { title: "Compliance assessment", description: "Manage your compliance assessment" };
    case 'governance-organizations':
      return { title: 'Organization', description: 'Model your organization structure' };
    case 'governance-missions':
      return { title: 'Mission', description: 'Model your mission' };
    case 'manage-information-systems':
      return { title: 'Information system', description: 'Model your Information systems' };
    case 'manage-supporting-assets':
      return { title: 'Supporting assets', description: 'Model your Supporting assets' };
    case 'manage-primary-assets':
      return { title: 'Primary assets', description: 'Model your Primary assets' };
    case 'risk-methodologies':
      return { title: 'Methodology', description: 'Create your risk assessment methodology' };
    case 'risk-assessments':
      return { title: 'Risk Assessment', description: 'Create your risk assessment instance' };
    case 'governance-definitions':
      return { title: 'Strategy', description: 'Define your organization security strategy' };
    case 'governance-responsibility-models':
      return { title: 'Responsibility model', description: 'Define your organization responsibility model' };
    case 'governance-activities':
      return { title: 'Activity', description: 'Define your organization cybersecurity activity' };
    case 'compliance-sub-controls':
      return { title: 'Sub control', description: 'Define new sub-control to be used in your organization controls' };
    case 'compliance-controls':
      return { title: 'Control', description: 'Define new control to be used in your organization standards' };
    case 'compliance-standards':
      return { title: 'Standard', description: 'Define new standard to be used by your organization' };
    case 'settings-users':
      return { title: 'User', description: 'Create a new internal user in your organization' };
    case 'settings-groups':
      return { title: 'Group', description: 'Create a new internal group in your organization' };
    case 'settings-categorization-models':
      return { title: 'Categorization model', description: 'Create a new categorization model in your organization' };
    case 'settings-grid-matrices':
      return { title: 'Grid matrix', description: 'Create a new grid matrix in your organization' };
    case 'governance-models':
      return { title: 'Process', description: 'Create a new process for your organization' };
    case 'governance-assignments':
      return { title: 'Strategy assignment', description: 'Assign strategy to your organization' };
    case 'manage-teams':
      return { title: 'Team', description: 'Create or edit a team in your organization' };
    case 'risk-threatsources':
      return { title: 'Threat Source', description: 'Manage threat sources for risk assessments' };
    case 'risk-threatevents':
      return { title: 'Threat Event', description: 'Manage threat events for risk assessments' };
    case 'risk-vulnerabilities':
      return { title: 'Vulnerability', description: 'Manage vulnerabilities for risk assessments' };
    case 'risk-impacts':
      return { title: 'Impact', description: 'Manage impacts for risk assessments' };
    case 'risk-taxonomies':
      return { title: 'Taxonomy', description: 'Manage taxonomies for risk assessments in your organization' };
    default:
      return { title: 'Item', description: 'Add a new item' };
  }
};

const AddItemPage = () => {
  const { type, id, resource, admin, action, module } = useParams();
  const navigate = useNavigate();
  
  const handleCancel = () => {
    navigate(-1); // Navigate back
  };

  const { title, description } = getTitleAndDescription(type, resource, module,action);
  const commonProps = id ? { id } : {};
  const actionVerb = id ? "Edit" : "Add";
  console.log(type, resource, module,action)
  return (
    <div>
      <Stack spacing={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Stack spacing={2}>
              <Typography variant="h4" component="h1">
                {actionVerb} {title}
              </Typography>
              <Typography variant="body1">
                {description}
              </Typography>
            </Stack>
          </Box>

          <Box display="flex" alignItems="center">
            <Tooltip title="Cancel">
              <IconButton onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          {module === 'manage' && type === 'role-assignments' && <RoleAssignmentMultiStepForm {...commonProps} />}
          {module === 'manage' && type === 'role-definitions' && <RoleMultiStepForm {...commonProps} />}
          {module === 'manage' && type === 'role-permissions' && <RolePermissionMultiStepForm {...commonProps} />}
          {action === 'json' && <JsonEditor endpoint={type} />}
          {module === 'compliance' && type === 'programs' && <ComplianceStepper {...commonProps} />}
          {module === 'compliance' && type === 'assessments' && <AssessmentForm {...commonProps} />}
          {module === 'governance' && type === 'organizations' && <AssetMultiStepForm {...commonProps} />}
          {module === 'governance' && type === 'missions' && <MissiontMultiStepForm {...commonProps} />}
          {module === 'manage' && type === 'information-systems' && <ISMultiStepForm {...commonProps} />}
          {module === 'manage' && type === 'primary-assets' && <PrimaryAssetMultiStepForm {...commonProps} />}
          {module === 'manage' && type === 'supporting-assets' && <SupportingAssetMultiStepForm {...commonProps} />}
          {module === 'risk' && type === 'methodologies' && <MethodologyForm {...commonProps} />}
          {module === 'risk' && type === 'assessments' && <RiskAssessmentForm {...commonProps} />}
          {module === 'risk' && type === 'threatsources' && resource === 'taxonomies' && action !== 'json' && <GenericResourcePage {...commonProps} resourceName="threatsources" />}
          {module === 'risk' && type === 'threatevents'&& resource === 'taxonomies' && action !== 'json' && <GenericResourcePage {...commonProps} resourceName="threatevents" />}
          {module === 'risk' && type === 'vulnerabilities' && resource === 'taxonomies' && action !== 'json' && <GenericResourcePage {...commonProps} resourceName="vulnerabilities" />}
          {module === 'risk' && type === 'impacts' && resource === 'taxonomies' && action !== 'json' && <GenericResourcePage {...commonProps} resourceName="impacts" />}
          {module === 'risk' && type === 'definitions' && resource === 'taxonomies' && action !== 'json' && <GenericResourcePage {...commonProps} resourceName="taxonomies" />}

          {module === 'governance' && type === 'definitions' && <StrategyForm {...commonProps} />}
          {module === 'governance' && type === 'responsibility-models' && <ResponsibilityMultiStepForm {...commonProps} />}
          {module === 'governance' && type === 'activities' && <ActivityMultiStep {...commonProps} />}
          {module === 'compliance' && type === 'sub-controls' && <GenericResourcePage {...commonProps} resourceName="compliance/sub-controls" />}
          {module === 'compliance' && type === 'controls' && <GenericResourcePage {...commonProps} resourceName="compliance/controls" />}
          {module === 'compliance' && type === 'standards' && <GenericResourcePage {...commonProps} resourceName="compliance/standards" />}
          {module === 'settings' && type === 'users' && actionVerb === "Add" && <GenericResourcePage {...commonProps} resourceName="users" />}
          {module === 'settings' && type === 'users' && actionVerb === 'Edit' && <GenericResourcePage {...commonProps} resourceName={`admin/users`} />}
          {module === 'settings' && type === 'groups' && <GenericResourcePage {...commonProps} resourceName="groups" />}
          {module === 'settings' && type === 'categorization-models' && <GenericResourcePage {...commonProps} resourceName="categorization_models" />}
          {module === 'settings' && type === 'grid-matrices' && <MatrixForm {...commonProps} />}
          {module === 'governance' && type === 'models' && action !== 'json' && <MultiProcess {...commonProps} />}
          {module === 'governance' && type === 'assignments' && <GenericResourcePage {...commonProps} resourceName="strategyassignments" />}
          {module === 'manage'&& type === 'teams' && <GenericResourcePage {...commonProps} resourceName="teams" />}
        </Box>
      </Stack>
    </div>
  );
};

export default AddItemPage;
