import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import {
  List,
  ListItem,
  Typography,
  Box,
  Divider,
  IconButton,
  Toolbar,
  TextField,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RaciMatrixDisplay from 'src/components/Governance/RaciMatrix';

function App() {
  const { id } = useParams(); // Get the id from the route parameters
  const navigate = useNavigate(); // For navigating programmatically
  const { getComplianceList: getProcesses, complianceList: processes } = useRequestCompliance({ endpoint: 'governance/responsibilitymodels', resourceLabel: 'processes' });
  const { getComplianceList: getRoles, complianceList: roles } = useRequestCompliance({ endpoint: 'rbac/roles', resourceLabel: 'roles' });
  const { getComplianceList: getActions, complianceList: actions } = useRequestCompliance({ endpoint: 'governance/activities', resourceLabel: 'activities' });
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getProcesses();
    getRoles();
    getActions();
  }, [getProcesses, getRoles, getActions]);

  useEffect(() => {
    if (processes.results && id) {
      const process = processes.results.find((process) => process.id === parseInt(id));
      if (process) {
        setSelectedProcess(process);
      }
    }
  }, [processes.results, id]);

  const handleSelection = (index) => {
    const selected = processes.results[index];
    setSelectedProcess(selected);
    navigate(`/governance/responsibilities/explore/${selected.id}`, { replace: true }); // Update the URL without navigating
  };

  const filteredProcesses = processes.results?.filter((process) =>
    process.title.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Prepare roles and activities data for the selected process
  const selectedRoles = roles.results?.filter((role) =>
    Array.isArray(selectedProcess?.roles) && selectedProcess?.roles.includes(role.id)
  ) || [];

  const selectedActivities = actions.results?.filter((activity) =>
    Array.isArray(selectedProcess?.activities) && selectedProcess?.activities.includes(activity.id)
  ) || [];

  const raciMatrix = selectedProcess?.rows?.reduce((acc, row) => {
    const key = `${row.role}-${row.activity}`;
    acc[key] = row.raci_data; // Assuming this is the format you need
    return acc;
  }, {});

  const selectedProcessId = selectedProcess ? selectedProcess.id : null;

  return (
    <div className="App">
      <Box display="flex" flexDirection="column" height="100vh">
        <Toolbar>
          <Typography variant="h5" flex={1}>
            Eunoia RACI Explorer
          </Typography>
          <IconButton onClick={() => setIsMenuVisible(!isMenuVisible)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>

        <Box display="flex" flex={1} pt={2}>
          {isMenuVisible && (
            <Box pr={2} width={200} borderRight="1px solid #ccc">
              <TextField
                label="Search by Title"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                margin="normal"
              />
              <List>
                {filteredProcesses.map((processData, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSelection(index)}
                    selected={selectedProcessId === processData.id} // Check if this item's id matches the selectedProcess's id
                  >
                    {processData.title}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Box flex={1} pl={3}>
            <Divider orientation="horizontal" flexItem />
            <Box flex={1} pl={3}>
              <RaciMatrixDisplay
                raciMatrix={raciMatrix}
                roles={selectedRoles}
                activities={selectedActivities}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default App;
