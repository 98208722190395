import { Button } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Reply'

const ReplyButton = ({ functionality }) => {
  return (
    <Button
      startIcon={<ReplayIcon />}
      sx={{
        color: 'custom.moderateBlue',
        fontWeight: 500,
        textTransform: 'capitalize'
      }}
      onClick={() => {
        functionality()
      }}
    >
      Reply
    </Button>
  )
}

export default ReplyButton
