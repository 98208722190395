import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import CategorizationComponent from 'src/components/Asset/CategorizationComponent';

const DetailedAssetView = () => {
  const { id, type } = useParams(); // Get the id from the route parameters
  const [catId, setCatId] = useState()
  const { getCompliance, compliance: assetDetails } = useRequestCompliance({ endpoint: type }); // Adjust the endpoint as necessary
  const { getCompliance: getCatInstance, compliance: catInstance, resetCompliance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })

  useEffect(() => {
    if (id) {
      getCompliance(id); // Fetch the asset details using the id
    }
  }, [id, getCompliance]);

  useEffect(() => {
    if (assetDetails) { assetDetails.security_requirements ? setCatId(assetDetails.security_requirements) : setCatId(assetDetails.security_compatibilities) }
  }, [assetDetails])

  useEffect(() => {
    if (catId) { getCatInstance(catId) }
  }, [catId])


  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Grid container>
        {/* Title Section */}
        <Box p={2} sx={{ flexGrow: 1 }}>
          <Typography variant="h5">
            Asset Detail
          </Typography>
          <Divider />
        </Box>

        {/* Main Content */}
        <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 64px)' }}>
          {/* Details View */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Resource Details */}
              {assetDetails ? (
                <>
                  {/* If an element is selected, render the detailed view */}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          {assetDetails.title}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {assetDetails.description}
                        </Typography>
                        <Divider />
                        <List>
                          <ListItem>
                            <ListItemText
                              primary="Created By"
                              secondary={assetDetails.created_by} // Note: Display user name if available.
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Created At"
                              secondary={new Date(assetDetails.created_at).toLocaleString()}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Updated At"
                              secondary={new Date(assetDetails.updated_at).toLocaleString()}
                            />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                  {/* Roles and Responsibilities */}
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Roles and Responsibilities
                        </Typography>
                        {assetDetails.roles && Object.keys(assetDetails.roles).length > 0 ? (
                          <List>
                            {Object.entries(assetDetails.roles).map(([roleTitle, userDetails], index) => (
                              <ListItem key={index}>
                                <ListItemText
                                  primary={roleTitle}
                                  secondary={`${userDetails?.username} (${userDetails?.email})`}
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography>No roles assigned.</Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  {catInstance
                                  ? <Grid item xs={6}>
              <CategorizationComponent data={catInstance}  />
            </Grid>
                                  : null}
                </>
                
              ) : (
                // If no element is selected, render a placeholder or message
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        Loading Asset Details...
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailedAssetView;
