import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, TextField, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CardActions } from '@mui/material';
import GlobalNavMenu from '../../components/General/GlobalNavMenu';
import useRequestCompliance from '../../hooks/useRequestCompliance';
import { downloadCSV, downloadJSON } from '../../components/General/Utils';
import ButtonMenu from 'src/components/buttonMenu';
import { useNavigate } from 'react-router-dom';


export default function TeamManagementView() {
  const [searchTerm, setSearchTerm] = useState('');
  const { getComplianceList: getTeams, complianceList: teams, deleteResource} = useRequestCompliance({ endpoint: 'teams', resourceLabel: 'teams' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const navigate = useNavigate();

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    getTeams();
  }, [getTeams, refreshKey]);

  const handleAdd = () => {
    navigate('/manage/teams/add');
  };

  const handleEdit = (teamId) => {
    navigate(`/manage/teams/edit/${teamId}`);
  };

  const handleDelete = (teamId) => {
    setTeamToDelete(teamId);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteResource(teamToDelete);
    setDialogOpen(false);
  };

  const filteredTeams = teams.results
    ? teams.results.filter((team) =>
      team.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  const actionList = [
    {
      name: 'Add',
      icon: 'AddIcon',
      action: handleAdd
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(teams.results, 'teams.json')
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(teams.results, 'teams.csv')
        }
      ]
    }
    // You can add more actions if required
  ];

  return (
    <>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid>
          <Typography variant="h5" gutterBottom>
            Team Management
          </Typography>
        </Grid>
      </Grid>

      <GlobalNavMenu actionList={actionList}>
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={4}>
            <TextField
              label="Search Teams"
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box m={0} pt={3} sx={{
          flexGrow: 1,
          mb: (theme) => theme.spacing(2)
        }}>
          <Grid container spacing={2}>
            {filteredTeams.map((team) => (
              <Grid item xs={12} sm={6} md={4} key={team.id}>
                <Card variant="outlined" style={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="subtitle2" gutterBottom>
                        {team.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {team.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Members: {team.members_names.map((member) => member.username).join(', ')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Member Count: {team.member_count}
                      </Typography>
                    </CardContent>
                    <Box flexGrow={1}></Box>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                      <ButtonMenu
                        id={team.id}
                        deleteClick={() => handleDelete(team.id)}
                        editClick={() => handleEdit(team.id)}
                      />
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </GlobalNavMenu>

      <Dialog
        open={dialogOpen} // This variable controls whether the dialog is open or not
        onClose={() => setDialogOpen(false)} // Function to close the dialog
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this team?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirmDelete(); // Your function to perform the delete action
              setDialogOpen(false); // Close the dialog after deleting
            }}
            color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
