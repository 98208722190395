import { useCallback, useState, useContext } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'

import formatHttpApiError from '../helpers/formatHttpApiError'
import { LoadingOverlayResourceContext } from '../components/LoadingOverlayResource'
import getCommonOptions from '../helpers/axios/getCommonOptions'

export default function useRequestCompliance ({ endpoint, resourceLabel }) {
  const [complianceList, setComplianceList] = useState({
    results: []
  })
  const [compliance, setCompliance] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState(null)
  const loadingOverlay = useContext(LoadingOverlayResourceContext)
  const { setLoading, loading } = loadingOverlay

  const handleRequestResourceError = useCallback((err) => {
    const formattedError = formatHttpApiError(err)
    setError(formattedError)
    setLoading(false)
    enqueueSnackbar(formattedError)
  }, [enqueueSnackbar, setError, setLoading])

  const getComplianceList = useCallback((filter) => {
    setLoading(true);
    axios.get(`/api/${endpoint}/`, {
      params: {
        ...filter
      },
      ...getCommonOptions()
    }).then((res) => {
        setLoading(false);
        setComplianceList({
            results: res.data,
        });
    }).catch(handleRequestResourceError);
}, [endpoint, handleRequestResourceError, setLoading, getCommonOptions]); // Ensure getCommonOptions is included in the dependency array if it's defined outside of useCallback


  const addCompliance = useCallback((values, successCallback) => {
    setLoading(true)
    axios.post(`/api/${endpoint}/`, values, getCommonOptions())
      .then((res) => {
        setLoading(false)
        const message = res.data.message || `${resourceLabel} added`
        enqueueSnackbar(`${resourceLabel} added`)
        if (successCallback) {
          successCallback()
        }
        // Add the new item to the list
        setComplianceList(prevState => ({
          ...prevState,
          results: [res.data, ...prevState.results]
        }))
      }).catch(handleRequestResourceError)
  }, [endpoint, enqueueSnackbar, handleRequestResourceError, resourceLabel, setLoading])

  const actionResource = useCallback(async (id, values, action, successCallback) => {
    setLoading(true)
    try {
      const res = await axios.post(`/api/${endpoint}/${id}/${action}`, values, getCommonOptions())
      setLoading(false)
      const message = res.data.message || `Action executed on resource ${resourceLabel}`
      enqueueSnackbar(message)
      if (successCallback) {
        successCallback()
      }
      return { success: true, message } // Indicating success
    } catch (error) {
      const formattedError = formatHttpApiError(error)
      setError(formattedError)
      setLoading(false)
      enqueueSnackbar(formattedError)
      return { success: false, error: formattedError } // Indicating failure
    }
  }, [endpoint, enqueueSnackbar, resourceLabel, setLoading])

  const getCompliance = useCallback((id, addTrailingSlash = true) => {
    setLoading(true)
    let url = `/api/${endpoint}/${id}`
    if (addTrailingSlash) {
      url += '/'
    }
    axios.get(url, getCommonOptions())
      .then((res) => {
        setLoading(false)
        const { data } = res
        setCompliance(data)
      }).catch(handleRequestResourceError)
  }, [endpoint, handleRequestResourceError, setLoading])

  const getResourceAttribute = useCallback((id, attribute) => {
    setLoading(true)
    axios.get(`/api/${endpoint}/${id}/${attribute}/`, getCommonOptions())
      .then((res) => {
        setLoading(false)
        const { data } = res
        setComplianceList(data)
      }).catch(handleRequestResourceError)
  }, [endpoint, handleRequestResourceError, setLoading])

  const updateCompliance = useCallback((id, values, successCallback) => {
    console.log(values)
    setLoading(true)
    axios.patch(`/api/${endpoint}/${id}/`, values, getCommonOptions())
      .then((res) => {
        setLoading(false)
        enqueueSnackbar(`${resourceLabel} updated`)
        if (successCallback) {
          successCallback()
        }
        // Update the item in the list
        setComplianceList(prevState => ({
          ...prevState,
          results: prevState.results.map(item => item.id === id ? res.data : item)
        }))
        setCompliance(res.data)
      }).catch(handleRequestResourceError)
  }, [endpoint, enqueueSnackbar, resourceLabel, handleRequestResourceError, setLoading])

  const deleteResource = useCallback((id, data = {}) => {
    setLoading(true);
  
    // Use axios' params option for query parameters if needed, or you can include them in the body as per your API requirements
    const options = {
      ...getCommonOptions(),
      data: data, // For sending data in the request body, if your backend supports it
      // If your DELETE requests need to send data as query params instead, use the `params` field:
      // params: data
    };
    return axios.delete(`/api/${endpoint}/${id}/`, options)
      .then(() => {
        setLoading(false);
        enqueueSnackbar(`${resourceLabel} deleted`);
        setComplianceList(prevState => ({
          ...prevState,
          results: prevState.results.filter(item => item.id !== id)
        }));
        return { success: true, message: `${resourceLabel} deleted` }; // Indicating success
      })
      .catch((error) => {
        const formattedError = formatHttpApiError(error);
        setError(formattedError);
        setLoading(false);
        enqueueSnackbar(formattedError);
        return { success: false, error: formattedError }; // Indicating failure
      });
  }, [endpoint, enqueueSnackbar, resourceLabel, setLoading]);
  

  const addComplianceWithFile = useCallback((formData, successCallback) => {
    setLoading(true);
    console.log(formData)
    // Note: Removed manual setting of 'Content-Type' header
    axios.post(`/api/${endpoint}/`, formData, {
      ...getCommonOptions(),
      headers: {
        // Spread any other headers needed, excluding 'Content-Type' for multipart/form-data
        ...getCommonOptions().headers,
        'Content-Type': 'multipart/form-data'
      },
    })
    .then((res) => {
      setLoading(false);
      enqueueSnackbar(`${resourceLabel} added`, { variant: 'success' });
      if (successCallback) {
        successCallback(res.data);
      }
      // Optionally update state or perform additional actions on success
    }).catch(handleRequestResourceError);
  }, [endpoint, enqueueSnackbar, handleRequestResourceError, resourceLabel, setLoading]);
  
  const updateComplianceWithFile = useCallback((id, formData, successCallback) => {
    setLoading(true);
  
    // Prepare headers, ensuring 'Content-Type' is not set, to allow axios to set it for multipart/form-data
    const options = getCommonOptions();
    const headers = { ...options.headers };
    delete headers['Content-Type']; // Ensure Content-Type is not set manually for multipart/form-data
  
    axios.patch(`/api/${endpoint}/${id}/`, formData, {
      ...options,
      headers,
      'Content-Type': 'multipart/form-data'
    })
    .then((res) => {
      setLoading(false);
      enqueueSnackbar(`${resourceLabel} updated`, { variant: 'success' });
      if (successCallback) {
        successCallback(res.data);
      }
      // Optionally update the item in the list
      setComplianceList(prevState => ({
        ...prevState,
        results: prevState.results.map(item => item.id === id ? res.data : item),
      }));
    }).catch(handleRequestResourceError);
  }, [endpoint, enqueueSnackbar, handleRequestResourceError, resourceLabel, setLoading, setComplianceList]);
  

  const resetCompliance = () => {
    setCompliance(null)
  }

  // 1. Add a new state variable for the schema
  const [schema, setSchema] = useState(null)

  // 2. Add a function to fetch the schema
  const getSchema = useCallback(() => {
    setLoading(true)
    axios.get(`/api/${endpoint}/?schema=true`, getCommonOptions())
      .then((res) => {
        setLoading(false)
        setSchema(res.data)
      }).catch(handleRequestResourceError)
  }, [endpoint, handleRequestResourceError, setLoading])

  // Function to fetch the schema with assessmentId as a parameter
  const getSchemaId = useCallback((contentTypeId) => {
    setLoading(true)
    // Append the assessmentId to the query parameters
    axios.get(`/api/${endpoint}/?schema=true&contentTypeId=${contentTypeId}`, getCommonOptions())
      .then((res) => {
        setLoading(false)
        setSchema(res.data)
      })
      .catch(handleRequestResourceError)
      .finally(() => {
        setLoading(false) // Always turn off loading indication
      })
  }, [endpoint,setLoading,handleRequestResourceError])
  // In the return of the hook, provide the new state and function:
  const getChildrenByContentType = useCallback((riskAssessmentInstanceId, model1Id, model2Id, filter) => {
    setLoading(true)
    axios.get(`/api/${endpoint}/${riskAssessmentInstanceId}/get_objects/`, {
      params: {
        model1_id: model1Id,
        model2_id: model2Id,
        ...filter
      },
      ...getCommonOptions()
    })
      .then((res) => {
        setLoading(false)
        // You might want to set this data to a state or return it directly
        setComplianceList({
          results: res.data

        })
      })
      .catch(handleRequestResourceError)
      .finally(() => {
        setLoading(false) // Always turn off loading indication
      })
  }, [endpoint, setLoading, handleRequestResourceError])

  return {
    complianceList,
    getComplianceList,
    addCompliance,
    updateCompliance,
    getResourceAttribute,
    deleteResource,
    compliance,
    getChildrenByContentType,
    getCompliance,
    handleRequestResourceError,
    resetCompliance,
    addComplianceWithFile,
    updateComplianceWithFile,
    error,
    schema, // <-- provide the schema state
    getSchema, // <-- provide the function to fetch the schema
    getSchemaId,
    actionResource,
    loading
  }
}
