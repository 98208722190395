import { Box, Card, Stack, Typography, Avatar, Button } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import AddReply from './AddReply'
import OwnReply from './OwnReply'
import ReplayIcon from '@mui/icons-material/Reply'
import { deepPurple } from '@mui/material/colors'
import moment from 'moment'
import useRequestCompliance from '../../hooks/useRequestCompliance'
const RepliesSection = ({ onReplies, setComRepClicked, comRepClicked, onTar, actuser, commid }) => {
  const [repliess, setReplies] = useState(onReplies)
  const [clicked, setClicked] = useState(false)
  const [respondTo, setRespondTo] = useState(onTar)
  const [show, setShow] = useState(false)
  const { deleteResource } = useRequestCompliance({ endpoint: 'reply', resourceLabel: 'Reply' })

  useEffect(() => {
    setReplies(onReplies)
  }, [onReplies])

  useEffect(() => {
    setRespondTo(onTar)
    if (comRepClicked) { setShow(true) } else if (clicked) { setShow(false) }
  }, [comRepClicked])
  const deleteReply = (id) => {
    setReplies(repliess.filter((reply) => reply.id !== id))
    deleteResource(id)
  }
  return (
    <Stack spacing={2} sx={{ width: '90%' }} alignSelf="center" >
      {repliess.map((rep) => {
        const { content, created_at, score, user, replyingTo } = rep
        const userName = user.username
        const ava = null
        return userName === actuser.username
          ? (
          <OwnReply
            key={rep.id}
            comId={rep.id}
            onContent={content}
            onTime={moment(created_at).fromNow()}
            onCount={score}
            onTar={replyingTo}
            onDel={deleteReply}
            actuser={actuser}
          />
            )
          : (
          <Card key={rep.id} >
            <Box sx={{ p: '15px' }} >
              <Stack spacing={2} direction="row">
                <Box sx={{ width: '100%' }}>
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Avatar sx={{ bgcolor: deepPurple[400] }}>{userName.charAt(0)}</Avatar>
                      <Typography
                        fontWeight="bold"
                        sx={{ color: 'neutral.darkBlue' }}
                      >
                        {userName}
                      </Typography>
                      <Typography sx={{ color: 'neutral.grayishBlue' }}>
                        {moment(created_at).fromNow()}
                      </Typography>
                    </Stack>
                    <Button
                      variant="text"
                      onClick={() => {
                        setClicked(!clicked)
                        setRespondTo(userName)
                        if (clicked) { setShow(true) } else { setShow(false) }
                      }}

                      sx={{
                        fontWeight: 500,
                        textTransform: 'capitalize',
                        color: 'custom.moderateBlue'
                      }}
                      startIcon={<ReplayIcon />}
                    >
                      Reply
                    </Button>
                  </Stack>
                  <Typography
                    component="div"
                    sx={{ color: 'neutral.grayishBlue', p: '20px 0' }}
                  >
                    <Typography
                      sx={{
                        color: 'custom.moderateBlue',
                        width: 'fit-content',
                        display: 'inline-block',
                        fontWeight: 500
                      }}
                    >
                      {`@${replyingTo}`}
                    </Typography>{' '}
                    {content}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
            )
      })}
      {show ? <AddReply onTar={respondTo} commid={commid}/> : null}
    </Stack>
  )
}

export default RepliesSection
