import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'



const SupportingAssetMultiStepForm = ({ id }) => {

  return (
    <div>
        <MultiStepResourceForm resourceName="supporting_assets" id={id || null} />
    </div>
)
}

export default SupportingAssetMultiStepForm
