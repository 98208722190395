import React from 'react'
import { createRoot } from 'react-dom/client' // Import createRoot from react-dom/client
// Import all other necessary components and contexts

import App from './App' // Assuming your App component is exported from './App'

// Assuming "root" is the ID of the DOM element where you want to render your app
const container = document.getElementById('root')

// Check if container is not null
if (container) {
  const root = createRoot(container) // Create a root instance
  root.render(
                <App />
  )
}
