import React from 'react';
import { List, ListItem, ListItemText, Typography, Accordion, AccordionSummary, AccordionDetails, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function RenderField({ field, value }) {
  const getItemLabel = (item, index) => {
    if (typeof item === 'object' && item !== null) {
      // Use a key from the object as a label if it exists
      const itemLabel = item.name || item.title || item.id || `Item ${index + 1}`;
      return itemLabel;
    }
    return `Item ${index + 1}`;
  };

  if (Array.isArray(value)) {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{field}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {value.length > 0 ? (
            <List>
              {value.map((item, index) => (
                <ListItem key={index}>
                  <RenderField field={getItemLabel(item, index)} value={item} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2">No items available.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }

  if (typeof value === 'object' && value !== null) {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{field}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {Object.keys(value).length > 0 ? (
              Object.entries(value).map(([key, val], index) => (
                <ListItem key={index}>
                  <RenderField field={key} value={val} />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2">No items available.</Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  return <TextField label={field} value={value || ''} fullWidth disabled />;
}

export default RenderField;
