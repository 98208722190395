import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useTheme,
  Drawer,
  Toolbar
} from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import CustomFieldTemplate from 'src/components/BaseLayout/CustomFieldTemplate';
import MRTcustomized from 'src/components/General/MRTcustomized';
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm';

const raciOptions = ['R', 'A', 'C', 'I'];

const RACISelections = ({ role, task, selections, handleRACIChange }) => {
  const theme = useTheme();

  const getColor = (option) => {
    switch (option) {
      case 'R':
        return theme.palette.riskLevels.veryHigh;
      case 'A':
        return theme.palette.riskLevels.high;
      case 'C':
        return theme.palette.riskLevels.medium;
      case 'I':
        return theme.palette.riskLevels.low;
      default:
        return theme.palette.action.active;
    }
  };

  return (
    <Box>
      {raciOptions.map((option) => (
        <Chip
          key={option}
          label={option}
          onClick={() => handleRACIChange(role, task, option)}
          style={{
            margin: '2px',
            color: selections.includes(option) ? '#fff' : theme.palette.text.primary,
            backgroundColor: selections.includes(option) ? getColor(option) : theme.palette.action.disabledBackground,
          }}
        />
      ))}
    </Box>
  );
};

function ResponsibilityMultiStepForm({ id }) {
  const { getComplianceList: getRoles, complianceList: roles } = useRequestCompliance({ endpoint: 'rbac/roles', resourceLabel: 'roles' });
  const { getComplianceList: getActions, complianceList: actions } = useRequestCompliance({ endpoint: 'governance/activities', resourceLabel: 'activities' });
  const { addCompliance: addModel, getCompliance: getModel, updateCompliance: updateModel, compliance: model } = useRequestCompliance({ endpoint: 'governance/responsibilitymodels', resourceLabel: 'responsibilities model' });

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    selectedRoles: [],
    selectedTasks: [],
    raciMatrix: {},
  });
  const [rolesDrawerOpen, setRolesDrawerOpen] = useState(false);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);

  useEffect(() => {
    getRoles();
    getActions();
    if (id) {
      getModel(id);
    }
  }, [getRoles, getActions, getModel, id]);

  useEffect(() => {
    if (model) {
      const parsedRows = model.rows.map(row => ({
        ...row,
        raci_data: JSON.parse(row.raci_data.replace(/'/g, '"')),
      }));

      setFormValues({
        title: model.title,
        description: model.description,
        selectedRoles: model.roles || [],
        selectedTasks: model.activities || [],
        raciMatrix: parsedRows.reduce((acc, row) => {
          const key = `${row.role}-${row.activity}`;
          acc[key] = { raci: row.raci_data };
          return acc;
        }, {}),
      });
    }
  }, [model]);


  const handleNext = () => {


    if (activeStep === steps.length - 1) {
      
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRoleChange = useCallback((selectedRoleIds) => {
    console.log(selectedRoleIds)
    setFormValues(prevValues => ({
      ...prevValues,
      selectedRoles: selectedRoleIds,
    }));
  }, []);

  const handleTaskChange = useCallback((selectedTaskIds) => {
    setFormValues(prevValues => ({
      ...prevValues,
      selectedTasks: selectedTaskIds,
    }));
  }, []);

  const handleSubmit = () => {
    const rows = Object.entries(formValues.raciMatrix)
      .filter(([key, value]) => {
        const [roleId, activityId] = key.split('-').map(Number);
        return formValues.selectedRoles.includes(roleId) && formValues.selectedTasks.includes(activityId);
      })
      .map(([key, value]) => {
        const [roleId, activityId] = key.split('-').map(Number);
        return {
          role: roleId,
          activity: activityId,
          raci: value.raci,
        };
      });
  
    const submissionData = {
      title: formValues.title,
      description: formValues.description,
      roles: formValues.selectedRoles,
      activities: formValues.selectedTasks,
      rows,
    };
  
    if (id) {
      updateModel(id, submissionData);
    } else {
      addModel(submissionData);
    }
  };
  

  const handleRACIChange = useCallback((role, task, raciValue) => {
    const key = `${role.id}-${task.id}`;

    setFormValues(prevValues => {
      const currentRACI = prevValues.raciMatrix[key]?.raci || [];

      const updatedRACI = currentRACI.includes(raciValue)
        ? currentRACI.filter(value => value !== raciValue)
        : [...currentRACI, raciValue];

      const updatedMatrix = {
        ...prevValues.raciMatrix,
        [key]: { raci: updatedRACI },
      };

      return {
        ...prevValues,
        raciMatrix: updatedMatrix,
      };
    });
  }, []);

  const steps = [
    { title: 'Basics', description: 'Enter the model title and description' },
    { title: 'Select Roles', description: 'Select the roles to be included in the model' },
    { title: 'Select Activities', description: 'Select the activities to be included in the model' },
    { title: 'RACI Matrix', description: 'Assign RACI values to each role-activity pair' }
  ];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomFieldTemplate
                label="Model Title"
                required
                description="The title of the responsibility model."
              >
                <TextField fullWidth name="title" value={formValues.title} onChange={handleChange} />
              </CustomFieldTemplate>
            </Grid>
            <Grid item xs={12}>
              <CustomFieldTemplate
                label="Model Description"
                description="A brief description of the responsibility model."
              >
                <TextField fullWidth name="description" value={formValues.description} onChange={handleChange} multiline rows={4} />
              </CustomFieldTemplate>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <CustomFieldTemplate
            label="Roles"
            description="Select the roles to be included in the model."
          >
            <Box mb={2}>
              <Typography variant="body1" gutterBottom>
                You can create a new entry or add from the table below.
              </Typography>
              <Button color="primary" onClick={() => setRolesDrawerOpen(true)}>
                Add Role
              </Button>
              {roles && roles.results && (
                <MRTcustomized
                  key={'roles'}
                  results={roles.results}
                  initialSelectedIds={formValues.selectedRoles}
                  enableRowSelection
                  enableMultiRowSelection={true}
                  onSelectedIdsChange={handleRoleChange}
                  hideActionsColumn={true}
                />
              )}
            </Box>
            <Drawer
              ModalProps={{
                keepMounted: true
              }}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '50%'
                }
              }}
              anchor="right"
              variant="temporary"
              open={rolesDrawerOpen}
              onClose={() => setRolesDrawerOpen(false)}
            >
              <Toolbar />
              <Box p={2}>
                <MultiStepResourceForm resourceName="rbac/roles" id={null} />
              </Box>
            </Drawer>
          </CustomFieldTemplate>
        );
      case 2:
        return (
          <CustomFieldTemplate
            label="Activities"
            description="Select the activities to be included in the model."
          >
            <Box mb={2}>
              <Typography variant="body1" gutterBottom>
                You can create a new entry or add from the table below.
              </Typography>
              <Button color="primary" onClick={() => setActionsDrawerOpen(true)}>
                Add Activity
              </Button>
              {actions && actions.results && (
                <MRTcustomized
                  key={'actions'}
                  results={actions.results}
                  initialSelectedIds={formValues.selectedTasks}
                  enableRowSelection
                  enableMultiRowSelection={true}
                  onSelectedIdsChange={handleTaskChange}
                  hideActionsColumn={true}
                />
              )}
            </Box>
            <Drawer
              ModalProps={{ keepMounted: true }}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '50%'
                }
              }}
              anchor="right"
              variant="temporary"
              open={actionsDrawerOpen}
              onClose={() => setActionsDrawerOpen(false)}
            >
              <Toolbar />
              <Box p={2}>
                <MultiStepResourceForm resourceName="governance/activities" id={null} />
              </Box>
            </Drawer>
          </CustomFieldTemplate>
        );
      case 3:
        return (
          <CustomFieldTemplate
            label="RACI Matrix"
            description="Assign RACI values to each role-activity pair."
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Task \ Role</TableCell>
                    {formValues.selectedRoles.map((roleId) => {
                      const role = roles?.results?.find((role) => role.id === roleId);
                      return role ? <TableCell key={roleId}>{role.title}</TableCell> : null;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formValues.selectedTasks.map((taskId) => {
                    const task = actions?.results?.find((task) => task.id === taskId);
                    return task ? (
                      <TableRow key={taskId}>
                        <TableCell component="th" scope="row">{task.title}</TableCell>
                        {formValues.selectedRoles.map((roleId) => (
                          <TableCell key={`${taskId}-${roleId}`}>
                            <RACISelections
                              role={{ id: roleId }}
                              task={{ id: taskId }}
                              selections={formValues.raciMatrix[`${roleId}-${taskId}`]?.raci || []}
                              handleRACIChange={handleRACIChange}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ) : null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomFieldTemplate>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', my: 4, maxWidth: '1200px', overflowX: 'hidden' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {activeStep === steps.length ? (
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'center' }}>All steps completed</Typography>
          ) : (
            <>
              {getStepContent(activeStep)}
            </>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: 'background.paper',
          p: 2,
          zIndex: 1000,
          borderTop: 1,
          borderColor: 'grey.400'
        }}
      >
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 2 }}>
            Back
          </Button>
          {activeStep < steps.length - 1 ? (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Finish
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ResponsibilityMultiStepForm;
