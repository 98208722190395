import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Divider
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';

const MatrixView = ({ model }) => {
  const { getComplianceList: getCategorizations, complianceList: categorizations } = useRequestCompliance({ endpoint: 'categorization_models_detail', resourceLabel: 'categorization models' });

  const [formValues, setFormValues] = useState({
    xModel: model?.x_categorization_model || null,
    yModel: model?.y_categorization_model || null,
    zModel: model?.z_categorization_model || null,
    raciMatrix: {}
  });

  useEffect(() => {
    getCategorizations();
    if (model?.elements) {
      const initialRaciMatrix = model.elements.reduce((acc, elem) => {
        const key = `${elem.row}-${elem.column}`;
        acc[key] = elem.value;
        return acc;
      }, {});
      setFormValues(prevValues => ({
        ...prevValues,
        raciMatrix: initialRaciMatrix
      }));
    }
  }, [model, getCategorizations]);

  const renderTable = () => {
    const xModel = categorizations.results.find(m => m.id === formValues.xModel);
    const yModel = categorizations.results.find(m => m.id === formValues.yModel);
    const zModel = categorizations.results.find(m => m.id === formValues.zModel);

    if (!xModel || !yModel || !zModel) return null;

    const xLevels = xModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];
    const yLevels = yModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];
    const zLevels = zModel.criterias[0]?.levels.sort((a, b) => a.index - b.index) || [];

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title={xModel.criterias[0]?.description || ''}>
                  <Box display="flex" alignItems="center">
                    {xModel.criterias[0]?.title}
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Divider />
                <Tooltip title={yModel.criterias[0]?.description || ''}>
                  <Box display="flex" alignItems="center">
                    {yModel.criterias[0]?.title}
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </TableCell>
              {xLevels.map((level, index) => (
                <TableCell key={index}>
                  <Tooltip title={level.description || ''}>
                    <Box display="flex" alignItems="center">
                      {level.title}
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <InfoIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {yLevels.map((yLevel, yIndex) => (
              <TableRow key={yIndex}>
                <TableCell component="th" scope="row">
                  <Tooltip title={yLevel.description || ''}>
                    <Box display="flex" alignItems="center">
                      {yLevel.title}
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <InfoIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </TableCell>
                {xLevels.map((xLevel, xIndex) => (
                  <TableCell key={`${yIndex}-${xIndex}`}>
                      <Box display="flex" alignItems="center">
                        {zLevels[formValues.raciMatrix[`${yIndex}-${xIndex}`]].title }
                      </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      {renderTable()}
    </Box>
  );
};

export default MatrixView;
