import React, { useState, useEffect } from 'react';
import DataList from 'src/components/General/DataList';
import StatCard from '../../../components/StatCard';
import { Grid, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import Typography from '@mui/material/Typography';
import GlobalNavMenu from '../../../components/General/GlobalNavMenu';
import { downloadJSON, downloadCSV } from '../../../components/General/Utils';
import { useNavigate } from 'react-router-dom';

export default function StandardView() {
  const { getComplianceList, complianceList } = useRequestCompliance({ endpoint: 'nestedstandards' });
  const [refreshKey, setRefreshKey] = useState(0);
  const [standard, setStandard] = useState();
  const navigate = useNavigate();

  const getActionList = (standard, fileName, standards) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'JSON Standard',
          action: () => navigate('/compliance/standards/nestedstandards/json')
        },
        {
          name: 'SubControl',
          action: () => navigate('/compliance/standards/sub-controls/add')
        },
        {
          name: 'Control',
          action: () => navigate('/compliance/standards/controls/add')
        },
        {
          name: 'Standard',
          action: () => navigate('/compliance/standards/standards/add')
        }
      ]
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(standard, `${fileName}.json`)
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(standard, `${fileName}.csv`)
        },
        {
          name: 'Download all JSON',
          action: () => downloadJSON(standards.results, 'All_Standards.json')
        },
        {
          name: 'Download all CSV',
          action: () => downloadCSV(standards.results, 'All_Standards.csv')
        }
      ]
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon'
    }
  ];
  const actionList = getActionList(standard, standard ? standard.title : false, complianceList);
  const { getComplianceList: getKpi, complianceList: kpi } =
    useRequestCompliance({ endpoint: 'kpi/standard' });
  useEffect(() => {
    getKpi();
  }, [getKpi, refreshKey]);

  useEffect(() => {
    getComplianceList();
  }, [refreshKey, getComplianceList]);

  return (
    <>
      <Grid container spacing={0} justifyContent="space-between">
        <Grid>
          <Typography variant="h5" gutterBottom>
            Compliance standards
          </Typography>
        </Grid>
      </Grid>
      <GlobalNavMenu actionList={actionList}>
        <Box m={0} pt={3} sx={{
          flexGrow: 1,
          mb: (theme) => theme.spacing(2)
        }}>
          <Grid container spacing={2}>
            <StatCard
              title="Total standards"
              value={kpi ? kpi.results.total_standards : 'Loading...'}
              loading={false}
              icon={<AssignmentIcon fontSize="small" />}
            />
            <StatCard
              title="Standards used in assessments"
              value={kpi ? kpi.results.standards_used_in_assessment : 'Loading...'}
              loading={false}
              icon={<DataUsageIcon fontSize="small" />}
            />
          </Grid>
        </Box>
        <DataList result={complianceList.results} setStandard={setStandard}> </DataList>
      </GlobalNavMenu>
    </>
  );
}
