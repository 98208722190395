import React, { useState, useEffect } from 'react'
import GlobalNavMenu from '../../components/General/GlobalNavMenu'
import StatCard from '../../components/StatCard'
import { Grid, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import DynamicTabsAndTables from '../../components/General/DynamicTabsAndTables'
import { downloadCSV, downloadJSON } from '../../components/General/Utils'
import { useNavigate } from 'react-router-dom'
import useRequestCompliance from '../../hooks/useRequestCompliance'
import DvrIcon from '@mui/icons-material/Dvr'
import StorageIcon from '@mui/icons-material/Storage'
import BarChartIcon from '@mui/icons-material/BarChart'

const tabEndpoints = [
  { name: 'Information system', endpoint: 'information_systems' },
  { name: 'Primary assets', endpoint: 'primary_assets' },
  { name: 'Supporting assets', endpoint: 'supporting_assets' }
]

export default function StandardView () {
  const navigate = useNavigate()
  const getActionList = (complianceList, fileName) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'Information system',
          action: () => navigate('/manage/assets/information-systems/add')
        },
        {
          name: 'Primary asset',
          action: () => navigate('/manage/assets/primary-assets/add')
        },
        {
          name: 'Supporting asset',
          action: () => navigate('/manage/assets/supporting-assets/add')
        }
      ]
    },
    {
      name: 'Eunoia Explorer',
      icon: 'ExploreIcon',
      action: () => navigate('/manage/assets/explore')
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(complianceList.results, `${fileName}.json`)
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(complianceList.results, `${fileName}.csv`)
        }
      ]
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon'
    }
  ]

  const [complianceList, setComplianceList] = useState([])
  const [fileName, setFileName] = useState()
  const [refreshKey, setRefreshKey] = useState(0)

  // Use the function here to get the action list
  const actionList = getActionList(complianceList, fileName)
  const { getComplianceList: getKpi, complianceList: kpi } =
    useRequestCompliance({ endpoint: 'kpis' })
  useEffect(() => {
    getKpi()
  }, [getKpi, refreshKey])
  return (
    <>
          <Grid container spacing={0} justifyContent="space-between">
      <Grid >
      <Typography variant="h5" gutterBottom>
        Asset management
      </Typography>
      </Grid>
      </Grid>
      <GlobalNavMenu actionList={actionList}>
    <Box pt={3} sx={{
      flexGrow: 1,
      mb: (theme) => theme.spacing(2)
    }}>
            <Grid container spacing={2}>
  <StatCard
    title="Total Information Systems"
    value={kpi ? kpi.results.total_information_systems : 'Loading...'}
    loading={!kpi}
    icon={<DvrIcon fontSize="small" />}
  />
  <StatCard
    title="Total Assets"
    value={kpi ? kpi.results.total_assets : 'Loading...'}
    loading={!kpi}
    icon={<StorageIcon fontSize="small" />}
  />
  <StatCard
    title="Level Value Fulfillment Percentage"
    value={kpi ? `${kpi.results.level_value_fulfillment_percentage?.toFixed(0)}%` : 'Loading...'}
    loading={!kpi}
    icon={<BarChartIcon fontSize="small" />}
  />
            </Grid>
        </Box>
            <DynamicTabsAndTables
        tabEndpoints={tabEndpoints}
        onDataLoaded={(data, filename) => {
          setComplianceList(data)
          setFileName(filename)
        }}
        refreshKey={refreshKey}
    />
    </GlobalNavMenu>
    </>
  )
}
