import React, { useContext } from 'react'
import { Container, Stack } from '@mui/material'
import Comment from './Comment'
import AddComment from './AddComment'
import CommentContext from '../../contexts/commentContext'

const Core = (props) => {
  const { commentSection, user } = useContext(CommentContext)
  return (
    <Container maxWidth="md" >
      <Stack spacing={3} >
        {commentSection.map((comment) => {
          return <Comment key={comment.id} onPass={comment} actuser={user} selectedSubInstance={props.selectedSubInstance}/>
        })}
        <AddComment selectedSubInstance={props.selectedSubInstance}/>
      </Stack>
    </Container>
  )
}

export default Core
