import React, { useEffect } from 'react'
import MultiProcess from '../../../components/Governance/MultiProcess'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import { IconButton, Typography, Box, Toolbar } from '@mui/material'
import { ArrowBackIos as ArrowBackIcon } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'

function App () {
  const { id } = useParams()
  const navigate = useNavigate()
  const { getCompliance: getProcess, compliance: process } = useRequestCompliance({ endpoint: 'processes', resourceLabel: 'processes' })

  useEffect(() => {
    getProcess(id)
  }, [getProcess, id])

  return (
        <div className="App">
            <Box display="flex" flexDirection="column" height="100vh">
                <Toolbar>
                    <IconButton onClick={() => navigate(-1)} edge="start" color="inherit" aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" flex={1}>
                        Process view
                    </Typography>
                </Toolbar>
                <Box display="flex" flex={1} pt={2}>
                    <Box flex={1} pl={3}>
                        <MultiProcess data={process || null} />
                    </Box>
                </Box>
            </Box>
        </div>
  )
}

export default App
