import React from 'react'
import { Box} from '@mui/material'
import MultiStepResourceForm
 from 'src/components/Risk/MultiStepResourceForm'

const MethodologyForm = ({ id }) => {
  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <MultiStepResourceForm id={id} resourceName={"risk-assessment-methodologies"} ></MultiStepResourceForm>
    </Box>
  )
}

export default MethodologyForm
