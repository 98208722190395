import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '@mui/material/styles';

const StakeHolderMetric = ({ data }) => {
  const chartRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      return;
    }

    const myChart = echarts.init(chartRef.current);

    const indicators = Object.keys(data).map(title => ({
      name: title, max: 7 // Adjust max value as necessary
    }));

    const overallValues = Object.values(data);
    // Adjust these values based on your thresholds
    const controlThresholdValues = indicators.map(() => 1.1);
    const watchThresholdValues = indicators.map(() => 5);
    const dangerThresholdValues = indicators.map(() => 0.4);

    const dataForScatter = indicators.map((indicator, index) => ({
      name: indicator.name,
      value: overallValues[index]
    }));

    const option = {
      tooltip: {
        show: true,
        formatter: function (params) {
          return `${params.name}: ${params.value}`;
        }
      },
      legend: {
        data: ['Overall Threat Level', 'Watch Threshold', 'Control Threshold', 'Danger Threshold']
      },
      angleAxis: {
        type: 'category',
        data: Object.keys(data)
      },
      radiusAxis: {
        max: 7
      },
      polar: {},
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0],
          filterMode: 'none'
        },
        {
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'none'
        },
        {
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'none'
        },
        {
          type: 'slider',
          yAxisIndex: [0],
          filterMode: 'none'
        }
      ],
      series: [
        {
          name: 'Watch Threshold',
          type: 'line',
          coordinateSystem: 'polar',
          data: watchThresholdValues,
          lineStyle: {
            type: 'dashed',
            color: theme.palette.riskLevels.veryLow
          },
          symbol: 'none'
        },
        {
          name: 'Control Threshold',
          type: 'line',
          coordinateSystem: 'polar',
          data: controlThresholdValues,
          lineStyle: {
            type: 'dashed',
            color: theme.palette.riskLevels.medium
          },
          symbol: 'none'
        },
        {
          name: 'Danger Threshold',
          type: 'line',
          coordinateSystem: 'polar',
          data: dangerThresholdValues,
          lineStyle: {
            type: 'dashed',
            color: theme.palette.riskLevels.veryHigh
          },
          symbol: 'none'
        },
        {
          name: 'Overall Threat Level',
          type: 'scatter',
          coordinateSystem: 'polar',
          data: dataForScatter,
          label: {
            show: false,
            formatter: function (params) {
              return params.value;
            },
          },
          itemStyle: {
            color: theme.palette.primary.main
          }
        },
      ]
    };

    myChart.setOption(option);
    // Add a cleanup function to dispose of the chart when the component unmounts
    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, [data, theme.palette]);

  // Check if data is empty and render placeholder or chart
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  return <div ref={chartRef} style={{ width: "100%", height: '400px' }}></div>;
};

export default StakeHolderMetric;
