import { Button } from '@mui/material'
import React, { useContext } from 'react'
import CommentContext from '../../../../../contexts/commentContext'
import useRequestCompliance from '../../../../../hooks/useRequestCompliance'

const UpdateReplyButton = ({
  repText,
  setClicked,
  setEditingRep,
  editingRep,
  clicked,
  comId
}) => {
  const { addReply } = useContext(CommentContext)
  const { updateCompliance } = useRequestCompliance({ endpoint: 'reply', resourceLabel: 'Reply' })

  return (
    <Button
      sx={{
        bgcolor: 'custom.moderateBlue',
        color: 'neutral.white',
        p: '8px 25px',
        float: 'right',
        '&:hover': {
          bgcolor: 'custom.lightGrayishBlue'
        }
      }}
      onClick={() => {
        !repText.trim()
          ? alert('Read the placeholder.')
          : setEditingRep(!editingRep)
        setClicked(!clicked); updateCompliance(comId, { content: repText })

        addReply()
      }}
    >
      Update
    </Button>
  )
}

export default UpdateReplyButton
