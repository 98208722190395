import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, Paper, MenuItem, Stack, Dialog, DialogTitle, DialogActions } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EunoiaFlow from 'src/components/Risk/EunoiaFlow'
import SyncIcon from '@mui/icons-material/Sync'
import SaveIcon from '@mui/icons-material/Save'

// Utility function to parse a filter string and prefix its keys
const parseAndPrefixFilter = (filterString, prefix) => {
  return filterString?.split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=')
    result[`${prefix}_${key}`] = value
    return result
  }, {})
}

function flattenJsonToFlow(obj, nodes = [], edges = [], parent = null, filterType = null) {
  if (!Array.isArray(nodes)) {
    console.error('flattenJsonToFlow was called with nodes not being an array:', nodes);
    nodes = []; // Fallback to an empty array or handle this error as appropriate
  }
  if (Array.isArray(obj)) {
    obj.forEach(item => {
      flattenJsonToFlow(item, nodes, edges, parent, filterType);
    });
  } else if (typeof obj === 'object' && obj !== null) {
    if (obj.model_id === undefined || obj.object_id === undefined) {
      // Skip this object if model_id or object_id is undefined
      return;
    }
    const objId = `${obj.model_id}-${obj.object_id}`;
    // Conditionally push node based on filterType
    if (!nodes.some(node => node.id === objId)) {
      // Check if filterType is 'strategic', then only add if type is 'primaryasset' or 'stakeholder'
      if (filterType === 'strategic') {
        if (obj.group === 'primaryasset' || obj.group === 'stakeholder') {
          pushNode(obj, nodes, objId);
        }
      } else {
        // If not filtering or another filterType, add node
        pushNode(obj, nodes, objId);
      }
    }

    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        const childObj = obj[key];
        if (Array.isArray(childObj)) {
          childObj.forEach(child => {
            if (typeof child === 'object' && child !== null && child.model_id !== undefined && child.object_id !== undefined) {
              const childId = `${child.model_id}-${child.object_id}`;
              edges.push({
                id: `e${objId}-${childId}`,
                source: objId,
                target: childId,
                animated: true,
                label: 'has',
                sourceHandle: 'bottom-handle',
                targetHandle: 'top-handle'
              });
              flattenJsonToFlow(child, nodes, edges, obj, filterType);
            }
          });
        } else {
          if (childObj.model_id !== undefined && childObj.object_id !== undefined) {
            const childId = `${childObj.model_id}-${childObj.object_id}`;
            edges.push({
              id: `e${objId}-${childId}`,
              source: objId,
              target: childId,
              animated: true
            });
            flattenJsonToFlow(childObj, nodes, edges, obj, filterType);
          }
        }
      }
    });
  }

  return { nodes, edges };
}

function pushNode (obj, nodes, objId) {
  nodes.push({
    id: objId,
    type: obj.group,
    position: { x: Math.random() * 500, y: Math.random() * 500 },
    data: { ...obj }
  })
}
function addNodes (nodes = [], vulns = []) {
  vulns?.forEach(vuln => {
    const vulnId = `${vuln.model_id}-${vuln.id}` // Create a unique ID for the vulnerability node

    if (!nodes.some(node => node.id === vulnId)) {
      nodes.push({
        id: vulnId,
        type: 'vulnerability', // Specify a type for vulnerability nodes
        position: { x: Math.random() * 500, y: Math.random() * 500 },
        data: vuln // Store the vulnerability data
      })
    }
  })
  return nodes
}

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word?.slice(0, -1)}ies`
  }
  return `${word}s` // simple pluralization
}

function Develop ({ model, instance_id, model1, model2, filter1, filter2, updateParentState,refreshKey }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const pluralModel2Name = pluralize(model2?.model)
  const pluralModel1Name = pluralize(model1?.model)
  const [itemToEdit, setItemToEdit] = useState(null)

  const { getChildrenByContentType, complianceList: listResources, actionResource } = useRequestCompliance({ endpoint: model, resourceLabel: model })
  const { deleteResource } = useRequestCompliance({ endpoint: model2 ? pluralModel2Name : pluralModel1Name, resourceLabel: 'Item resource' })
  const { getComplianceList: getStakeHolders, complianceList: stakeHolders } = useRequestCompliance({ endpoint: 'stakeholders', resourceLabel: 'stakholder' })
  const { getComplianceList: getVulns, complianceList: vulns } = useRequestCompliance({ endpoint: 'vulnerabilities', resourceLabel: 'vulnerability' })
  const { getChildrenByContentType: getPrimaryAssetss, complianceList: primaryAssets } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Risk assessment instance' })
  const { getComplianceList: getContents, complianceList: contents } = useRequestCompliance({ endpoint: 'contenttypes', resourceLabel: 'contenttypes' })

  const [selectedId, setSelectedId] = useState()
  const [selectedSource, setSelectedSource] = useState(null)

  const [graphUpdate, setGraphUpdate] = useState(0)
  const [shouldSendRequest, setShouldSendRequest] = useState(false)
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  console.log(pluralModel2Name)

  useEffect(() => {
    const filter = {
      model: "primaryasset",
    };
    getContents(filter)
  },[getContents])
  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')
    const filter = {
      risk_assessment_instance: instance_id,
    };
    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }    
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata)
    getStakeHolders(filter)
    if (contents?.results[0]?.id)
    {
      getPrimaryAssetss(instance_id, contents?.results[0]?.id, null, null)
    }
    getVulns(filter)
  }, [instance_id, model1.id, model2?.id,contents, filter2, filter1,getChildrenByContentType,getStakeHolders,getPrimaryAssetss,getVulns])

  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')

    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }    
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata);
  }, [refreshKey, instance_id, model1.id, model2?.id, filter2, filter1, getChildrenByContentType]);
  

  useEffect(() => {
    let rnodes = []
    rnodes = addNodes(nodes, vulns?.results)
    setNodes(rnodes)
  }, [nodes, vulns]) // Added vulns to dependencies array

  useEffect(() => {
    let jsonData = []
    let layout = { nodes: [], edges: [] } // Default empty layout

    if (filter2?.includes('strategic')) {
      // Assuming stakeHolders and primaryAssets need to be combined into a single array
      jsonData = [...stakeHolders.results, ...primaryAssets.results]
      layout = flattenJsonToFlow(jsonData, [], [], null, 'strategic')
    } else {
      // Assuming for operational you want to include everything or specific types, adjust as needed
      // Here's how you'd call it without filtering (or adjust the filterType argument as needed)
      jsonData = [...stakeHolders.results, ...primaryAssets.results]
      layout = flattenJsonToFlow(jsonData, [], [], null, 'operational')
    }
    setNodes(layout.nodes)
    setEdges(layout.edges)
  }, [filter1, filter2, stakeHolders.results, primaryAssets.results]) // Ensure to list all dependencies here

  const handleOpenChoiceDialog = (raw) => {
    handleAddNewItem()
    setItemToEdit(null) // Set the ID of the item to be deleted
    setSelectedSource({
      ...raw.original,
      model: model1
    })
    setSelectedId(raw.id)
  }

  const handleAddNewItem = () => {
    setItemToEdit(null) // Set the ID of the item to be deleted
    setIsDrawerOpen(true) // Open the drawer for adding a new item
  }

  const handleSave = () => {
    setGraphUpdate(prevKey => prevKey + 1) // Trigger state update
    setShouldSendRequest(true) // Set the trigger
    setIsDrawerOpen(false) // Assuming you want to close the drawer immediately
  }

  const handleSaveScenario = async (scenario) => {
    let filter = 'operational'
    if (filter2?.includes('strategic')) { filter = 'strategic' }

    if (scenario && shouldSendRequest) {
      const payload = {
        scenario,
        filter,
        object_id: selectedId,
        content_type_id: model1.id,
        object_edit: itemToEdit?.id
      }
      setShouldSendRequest(false) // Reset the trigger

      // Await the result from actionResource and check the success status
      const result = await actionResource(instance_id, payload, `develop-${model2 ? pluralModel2Name : pluralModel1Name}/`)
      if (result.success) {
        // If action was successful, refresh the related content
        updateParentState() // Refresh content
      }
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsDrawerOpen(open)
  }

  // Drawer content
  const drawerContent =
        <>
        <Box>
            <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Paper elevation={0}>

            <Box p={3}>
            <Stack direction="row" spacing={2} justifyContent="flex-start">
            <IconButton color="secondary" aria-label="add an alarm" onClick={() => setGraphUpdate(prevKey => prevKey + 1)}>
                <SyncIcon />
            </IconButton>
            <IconButton color="primary" aria-label="add an alarm" onClick={() => handleSave()}>
                <SaveIcon />
            </IconButton>
            </Stack>
            <EunoiaFlow key={selectedSource?.id} graphUpdate={graphUpdate}
            initialNodes={nodes}
            initialEdges={edges}
            selectedSource={selectedSource}
            itemToEdit={itemToEdit}
            endpoint = {pluralModel2Name || pluralModel1Name}
            handleSaveScenario={handleSaveScenario}
            vulns={vulns?.results}
            />
            </Box>

        </Paper>
        </>
  

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false) // State for delete confirmation dialog
  const [itemToDelete, setItemToDelete] = useState(null) // State to track the item to be deleted

  const handleIconClick = (id) => {
    setItemToDelete(id) // Set the ID of the item to be deleted
    setDeleteConfirmationOpen(true) // Open the confirmation dialog
  }
  const handleEditClick = (instance, row) => {
    setItemToEdit(instance) // Set the ID of the item to be deleted
    setSelectedSource({
      ...row.original,
      model: model1
    })
    setSelectedId(row.id)
    setIsDrawerOpen(true) // Open the drawer for adding a new item
  }

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      // Call the delete function with itemToDelete
      const { success, message, error } = await deleteResource(itemToDelete);
      setDeleteConfirmationOpen(false) // Close the dialog after deletion
    // Check if the delete operation was successful
    if (success) {
      // Only update parent state if the delete was successful
      updateParentState();
      // Optionally, show a success message
      } 
      }
  }

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false) // Close the dialog without deleting
  }
  const handleDeleteClick = (id) => {
    if (id) { setIdToDelete(id) }
    setDeleteOpen(true)
  }

  return (
        <>            <Dialog open={deleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogActions>
                    <Button onClick={handleConfirmDelete}>Yes, Delete</Button>
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Drawer
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                  zIndex: theme => theme.zIndex.drawer, // Ensures drawer is above most elements
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '75%'
                  }
                }}
                anchor="right"
                variant="temporary"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                >
                <Toolbar />
                {drawerContent}
                </Drawer>
            <MRTcustomized
                key={`MRT-${instance_id}-${model1?.id}-${model2?.id}`}
                results={listResources.results}
                enableRowSelection={false}
                enableRowActions={true}
                enableDetailPanel={true}
                nestedObjectName ={pluralModel2Name}
                onIconClick={handleIconClick}
                onEditClick={handleEditClick}
                columnOrder={[
                  'mrt-row-expand', 'mrt-row-actions', 'title', 'description', 'group']}
                {...(model2
                  ? {
                      renderRowActionMenuItems: ({ row }) => [
                        <MenuItem key={`Add-${model2.model}`} onClick={(e) => handleOpenChoiceDialog(row)}>
                            Add {model2.model}
                        </MenuItem>
                      ]
                    }
                  : {
                      renderRowActions: ({ row }) => (
                        <Box>
                            <IconButton color='error' onClick={() => handleDeleteClick(row.id) }>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                      )
                    })}
            />
        </>
  )
}

export default Develop
