import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import useRequestCompliance from '../hooks/useRequestCompliance'

export default function SelectList ({ ivalue, ichoices, endpoint, item, resourceLabel, field }) {
  const [value, setValue] = React.useState(ivalue || '')
  const { updateCompliance } = useRequestCompliance({ endpoint, resourceLabel })

  React.useEffect(() => {
    setValue(ivalue)
  }, [ivalue])

  const handleChange = (event) => {
    const val = {
      [field]: event.target.value
    }
    setValue(event.target.value)
    updateCompliance(item, val)
  }

  return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {ichoices && ichoices.length > 0
                      ? (
                          ichoices.map((ichoice) => (
                            <MenuItem key={ichoice.value} value={ichoice.value}>
                                {ichoice.label}
                            </MenuItem>
                          ))
                        )
                      : (
                        <MenuItem value="">
                            No Choices Available
                        </MenuItem>
                        )}
                </Select>
            </FormControl>
        </Box>
  )
}
