import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Formik } from 'formik';
import * as yup from 'yup';
import useRequestAuth from '../../hooks/useRequestAuth';
import { Link } from 'react-router-dom';
import logo from '../../static/images/logo.png'; // Adjust the path as needed

const validationSchema = yup.object({
  username: yup.string().required('Email Address / Username is required'),
  password: yup.string().required('Password is required')
});

export default function SignIn() {
  const { login, loading } = useRequestAuth();

  const handleSubmit = (values) => {
    login(values);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2} sx={{ height: '100vh', alignItems: 'center' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Eunoia Security Hub
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, color: 'gray' }}>
              Less complexity, more security
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                padding: 3,
                boxShadow: 3,
                borderRadius: 2,
                bgcolor: 'background.paper'
              }}
            >
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Sign in
              </Typography>
              <Formik
                validationSchema={validationSchema}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                initialValues={{
                  username: '',
                  password: ''
                }}
              >
                {(formik) => {
                  return (
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username / Email Address"
                        name="username"
                        autoFocus
                        {...formik.getFieldProps('username')}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        {...formik.getFieldProps('password')}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                      />
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign In
                      </LoadingButton>
                      <Grid container>
                        <Grid item>
                          <Link to="/auth/signup" key="signup">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
