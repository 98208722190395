import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'



const ISMultiStepForm = ({ id }) => {

  return (
    <div>
        <MultiStepResourceForm resourceName="information_systems" id={id || null} />
    </div>
)
}

export default ISMultiStepForm
