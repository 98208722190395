import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, MenuItem } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector'
import Typography from '@mui/material/Typography'

function ThreatLikelihood ({ resourceName, id, instance_id }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { getCompliance: getAdversarialSources, compliance: adversarialSources } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Threat source instance' })
  const { getCompliance: getNonAdversarialSources, compliance: nonAdversarialSources } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Threat source instance' })

  const { getCompliance: getCatInstance, compliance: catInstance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })
  const [refreshKey, setRefreshKey] = useState(0)

  const updateParentState = (newValue) => {
    setRefreshKey(newValue)
  }

  useEffect(() => {
    if (instance_id) {
      const url = instance_id + '/risk-instances?threat_source_type=adversarial'
      const nurl = instance_id + '/risk-instances?threat_source_type=non_adv'

      // Assume getSources can accept a query parameter for filtering
      getAdversarialSources(url, false)
      getNonAdversarialSources(nurl, false)
    }
  }, [instance_id, getAdversarialSources, getNonAdversarialSources, refreshKey])

  const endpointUrl = `risk-assessment-instances/${instance_id}/update-criteria-instance/`

  const handleCharClick = (id) => {
    getCatInstance(id)
    setIsDrawerOpen(true)
  }
  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  return (
        <>
        <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '40%',
                    marginTop: '64px'
                  }
                }}
            anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
      <div>
        <IconButton onClick={closeDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Box p={2} sx={{ flexGrow: 1 }}>
      {catInstance
        ? <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance} setRefreshKey={updateParentState} endpoint={endpointUrl} />
        : null}
        </Box>
          </Drawer>
          <Typography variant="h6" sx={{ mt: 2 }}>
                Adversarial Risks
            </Typography>
          <MRTcustomized
        key={'adversarial-' + instance_id}
        results={adversarialSources}
        enableRowActions={true}
        enableRowSelection={false}
        columnOrder={['mrt-row-actions', 'threat_event', 'threat_source', 'CHARACTERISTICS OF ADVERSARY CAPABILITY', 'CHARACTERISTICS OF ADVERSARY INTENT', 'CHARACTERISTICS OF ADVERSARY TARGETING', 'RELEVANCE OF RISK INSTANCE', 'Likelihood of Threat Event Initiation (Adversarial)']}
        renderRowActionMenuItems={({ row }) => [
            <MenuItem key="occurance" onClick={() => handleCharClick(row.original.occurance_likelihood)}>
                Occurance likelihood
            </MenuItem>,
            <MenuItem key="resulting" onClick={() => handleCharClick(row.original.resulting_likelihood)}>
                Resulting likelihood
            </MenuItem>
        ]}
    />
                <Typography variant="h6" sx={{ mt: 2 }}>
                Non-Adversarial Risks
            </Typography>
    <MRTcustomized
        key={'non-adversarial-' + instance_id}
        results={nonAdversarialSources}
        enableRowActions={true}
        enableRowSelection={false}
        columnOrder={['mrt-row-actions', 'threat_event', 'threat_source', 'Range of Effects for Non-Adversarial Threat Sources', 'RELEVANCE OF RISK INSTANCE', 'Likelihood of Threat Event Occurrence (Non-Adversarial)']}
        renderRowActionMenuItems={({ row }) => [
            <MenuItem key="occurance" onClick={() => handleCharClick(row.original.occurance_likelihood)}>
                Occurance likelihood
            </MenuItem>,
            <MenuItem key="resulting" onClick={() => handleCharClick(row.original.resulting_likelihood)}>
                Resulting likelihood
            </MenuItem>
        ]}
            />
        </>
  )
}

export default ThreatLikelihood
