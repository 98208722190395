import React, { useState, useEffect } from 'react'
import MultiProcess from '../../../components/Governance/MultiProcess'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import { List, ListItem, Typography, Box, Divider, IconButton, Toolbar, TextField } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

function App () {
  const { getComplianceList: getProcesses, complianceList: processes } = useRequestCompliance({ endpoint: 'processes', resourceLabel: 'processes' })

  const [selectedProcessIndex, setSelectedProcessIndex] = useState(0)
  const [isMenuVisible, setIsMenuVisible] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    getProcesses()
  }, [getProcesses])

  const handleSelection = (index) => {
    setSelectedProcessIndex(index)
  }

  const filteredProcesses = processes.results?.filter(process =>
    process.title.toLowerCase().includes(searchTerm.toLowerCase())
  ) || []

  return (
    <div className="App">
      <Box display="flex" flexDirection="column" height="100vh">
          <Toolbar >
            <Typography variant="h5" flex={1}>
              Eunoia Process Explorer
            </Typography>
            <IconButton onClick={() => setIsMenuVisible(!isMenuVisible)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>

        <Box display="flex" flex={1} pt={2}>
          {isMenuVisible && (
            <Box pr={2} width={200} borderRight="1px solid #ccc">
              <TextField
                label="Search by Title"
                variant="outlined"
                size="small"
                fullWidth
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
                margin="normal"
              />
              <List>
                {filteredProcesses.map((processData, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSelection(index)}
                    selected={selectedProcessIndex === index}
                  >
                    {processData.title}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Box flex={1} pl={3}>
            <Divider orientation="horizontal" flexItem />
            <MultiProcess data={filteredProcesses[selectedProcessIndex] || null} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default App
