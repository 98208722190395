import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react'
import {
  useMaterialReactTable,
  MaterialReactTable
} from 'material-react-table'
import {
  Stack, Button, Box, Typography, IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'


const MRTcustomized = ({
  results = [],
  initialSelectedIds = [],
  enableRowActions,
  enableRowSelection = true,
  renderRowActions,
  onSelectedIdsChange,
  enableMultiRowSelection,
  firstColumnAccessor,
  columnOrder,
  filterBycolumnOrder = false,
  renderRowActionMenuItems,
  enableDownload = false, // Add the enableDownload prop with a default value of true
  nestedObjectName = 'threat_event_instances', // new prop for specifying the nested object name
  onIconClick,
  extraFields,
  onEditClick,
  renderMenuItems
}) => {
  const [rowSelection, setRowSelection] = useState({})
  const isInitialSelectionSet = useRef(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [pinnedRows, setPinnedRows] = useState([]);


  console.log(results)

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveRow(null);
  };

  // CSV export configurations
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  })

  const handleExportRows = (rows) => {
    const flattenObject = (obj) => {
      const flatten = {};
  
      const recurse = (cur, prop) => {
        if (Object(cur) !== cur || Array.isArray(cur)) {
          flatten[prop] = cur;
        } else {
          let isEmpty = true;
          for (const [key, value] of Object.entries(cur)) {
            isEmpty = false;
            recurse(value, prop ? `${prop}.${key}` : key);
          }
          if (isEmpty && prop) {
            flatten[prop] = null;
          }
        }
      };
  
      recurse(obj, '');
  
      return flatten;
    };
  
    const rowData = rows.map((row) => flattenObject(row.original));
  
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  
  

 
  const flattenedData = useMemo(() => results?.map(item => {
    const newItem = {...item};  // Shallow copy to avoid mutating the original data
    if (item.roles) {
      Object.keys(item.roles).forEach(roleKey => {
        newItem[roleKey] = item.roles[roleKey]?.username;
      });
      delete newItem.roles; // Remove the original roles object
    }    
    extraFields?.forEach(field => {
      if (typeof item[field] === 'object' && item[field] !== null && !Array.isArray(item[field])) {
        // Merge child keys and values directly into the new item
        Object.assign(newItem, item[field]);
        // Optionally, delete the original field to avoid duplication
        delete newItem[field];
      }
    });
    return newItem;
  }), [results, extraFields]);

   // Function to export all data
   const handleExportData = () => {
    const csv = generateCsv(csvConfig)(flattenedData)
    download(csvConfig)(csv)
  }
  useEffect(() => {
    if (!isInitialSelectionSet.current) {
      const initialRowSelectionState = Array.isArray(initialSelectedIds)
        ? initialSelectedIds.reduce((acc, currId) => {
          acc[currId] = true
          return acc
        }, {})
        : { [initialSelectedIds]: true }

      setRowSelection(initialRowSelectionState)
      isInitialSelectionSet.current = true
    }
  }, [initialSelectedIds])

  useEffect(() => {
    const selectedIdsArray = Object.entries(rowSelection)
    .filter(([key, value]) => value)
    .map(([key]) => Number(key))

    setPinnedRows(selectedIdsArray)
  }, [rowSelection])

  useEffect(() => {
    const selectedIdsArray = Object.entries(rowSelection)
      .filter(([key, value]) => value)
      .map(([key]) => Number(key))
    if (enableRowSelection && onSelectedIdsChange) {
      if (enableMultiRowSelection === false) {
        onSelectedIdsChange(selectedIdsArray[0])
      } else {
        onSelectedIdsChange(selectedIdsArray)
      }
    }

  }, [rowSelection,enableMultiRowSelection, enableRowSelection, onSelectedIdsChange])

  const columns = useMemo(() => {
    if (!Array.isArray(flattenedData) || flattenedData.length === 0) return []
    const sample = flattenedData[0]

    const columnList = Object.keys(sample).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)
    }))

    if (firstColumnAccessor) {
      const firstColumnIndex = columnList.findIndex(
        (column) => column.accessorKey === firstColumnAccessor
      )
      if (firstColumnIndex !== -1) {
        const firstColumn = columnList.splice(firstColumnIndex, 1)
        columnList.unshift(...firstColumn)
      }
    }

    // Exclude columns with nested data (for example, objects or arrays)
    const filteredColumns = columnList.filter((column) => {
      const value = sample[column.accessorKey]
      return !Array.isArray(value) && typeof value !== 'object'
    })

    return filteredColumns
  }, [flattenedData, firstColumnAccessor])

  const tableColumns = useMemo(() => {
    if (!Array.isArray(flattenedData) || flattenedData.length === 0) return []

    if (columnOrder && filterBycolumnOrder && columnOrder.length > 0) {
      const columnKeysSet = new Set(columnOrder)

      const filteredColumns = columns.filter((column) =>
        columnKeysSet.has(column.accessorKey)
      )

      return filteredColumns
    } else {
      return columns
    }
    
  }, [flattenedData, columns, columnOrder,filterBycolumnOrder])

  const renderDownloadButtons = () => {
    if (!enableDownload) {
      return null // Return nothing if enableDownload is set to false
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap'
        }}
      >
        <Button
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    )
  }
  const renderDetailPanel = useCallback(({ row }) => {
    // Accessing the nested object using the specified prop
    const nestedObject = row?.original[nestedObjectName];
  
    // Check if nestedObject is not empty, return null if it's empty or doesn't exist
    if (!nestedObject || nestedObject.length === 0) {
      return null; // This prevents the detail panel button from being displayed
    }
  
    // If nestedObject exists and is not empty, render the detail panel content
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {nestedObject.map((instance, index) => (
              <TableRow key={index}>
                {renderMenuItems && (
                  <TableCell>
                      {renderMenuItems ? renderMenuItems(instance, handleMenuClose) : null}
                  </TableCell>
                )}
                {onIconClick && (
                  <TableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => onIconClick(instance.id, row)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                )}
                {onEditClick && (
                  <TableCell>
                    <IconButton aria-label="edit" size="small" onClick={() => onEditClick(instance, row)}>
                      <EditIcon color="warning" />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell>
                  <Typography>{instance.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{instance.description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{instance.objective}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [ nestedObjectName, onIconClick, onEditClick]); // Ensure all dependencies are included
  console.log(pinnedRows)

  const table = useMaterialReactTable({
    columns: tableColumns,
    data: flattenedData,
    enablePagination: true,
    enableRowPinning: true,
    rowPinningDisplayMode: 'select-sticky',
    getRowId: (row) => row.id,
    enableGrouping: true,
    initialState: {
      columnOrder,
      columnVisibility: {
        id: false,
        created_by: false,
        created_at: false,
        updated_at: false
      },

    },
    enableRowSelection,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    onRowSelectionChange: setRowSelection,    
    state: {
      rowPinning: {
        top: pinnedRows,
      },
      rowSelection,

    },
    muiTableContainerProps: {
            sx: {
        maxHeight: '600px'
      }
    },
    defaultColumn: {
      maxSize: 400,
      minSize: 20,
      size: 300
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowActions,
    renderRowActions,
    enableMultiRowSelection,
    renderRowActionMenuItems,
    renderTopToolbarCustomActions: renderDownloadButtons, // Use the renderDownloadButtons function
    renderDetailPanel,
    
  })
  console.log(table)
  return (
    <Stack sx={{ m: '2rem 0' }}>
      <MaterialReactTable table={table} />
    </Stack>
  )
}

export default MRTcustomized
