import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import DownloadIcon from '@mui/icons-material/Download'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ExploreIcon from '@mui/icons-material/Explore'

const iconMap = {
  AddIcon,
  RefreshIcon,
  DownloadIcon,
  ReadMoreIcon,
  ExploreIcon
}

export default function GlobalNavMenu ({ actionList, children }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentActionItems, setCurrentActionItems] = useState([])

  // Return early if actionList is empty or null
  if (!actionList || actionList.length === 0) {
    return <div>No actions available</div>
  }

  const handleOpenMenu = (event, items) => {
    setCurrentActionItems(items || [])
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={2}>
        {actionList.map((action, index) => {
          const IconComponent = iconMap[action.icon]
          return (
            action.items && action.items.length > 0
              ? (
              <Button
                key={index}
                startIcon={<IconComponent />}
                onClick={(e) => handleOpenMenu(e, action.items)}
              >
                {action.name}
              </Button>
                )
              : (
              <Button
                key={index}
                startIcon={<IconComponent />}
                onClick={action.action || (() => console.log(`Action: ${action.name}`))}
              >
                {action.name}
              </Button>
                )
          )
        })}
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currentActionItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (item.action) {
                item.action()
              }
              handleClose()
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <Divider />
      {children}
    </Box>
  )
}
