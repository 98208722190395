import { Button } from '@mui/material'
import React, { useContext } from 'react'
import CommentContext from '../../../../../contexts/commentContext'
import useRequestCompliance from '../../../../../hooks/useRequestCompliance'

const AddReplyButton = ({ setReplyText, replyText, onTar, commid }) => {
  const { addReply } = useContext(CommentContext)
  const { addCompliance } = useRequestCompliance({ endpoint: 'reply', resourceLabel: 'Reply' })

  const addComment = (replyText, commid, onTar) => {
    addCompliance(
      {
        content: replyText,
        score: 0,
        replyingTo: onTar,
        replying_to: commid
      }
    )
  }

  return (
    <Button
      size="large"
      sx={{
        bgcolor: 'custom.moderateBlue',
        color: 'neutral.white',
        p: '8px 25px',
        '&:hover': {
          bgcolor: 'custom.lightGrayishBlue'
        }
      }}
      onClick={(e) => {
        !replyText.trim() ? e.preventDefault() : addComment(replyText, commid, onTar); addReply()
        setReplyText('')
      }}
    >
      Reply
    </Button>
  )
}

export default AddReplyButton
