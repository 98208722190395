import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Drawer from '@mui/material/Drawer'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DynamicLevelSelector from 'src/components/General/DynamicLevelSelector'

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word.slice(0, -1)}ies`
  }
  return `${word}s` // simple pluralization
}
// Utility function to parse a filter string and prefix its keys
const parseAndPrefixFilter = (filterString, prefix) => {
  return filterString?.split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=')
    result[`${prefix}_${key}`] = value
    return result
  }, {})
}

function View ({ model, instance_id, model1, model2, assessments,filter1, filter2 }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pluralModel2Name = pluralize(model2?.model)


  const { getChildrenByContentType, complianceList: listResources } = useRequestCompliance({ endpoint: model, resourceLabel: model })
  const { getCompliance: getCatInstance, compliance: catInstance } =
    useRequestCompliance({ endpoint: 'categorization_instances' })
  const endpointUrl = `risk-assessment-instances/${instance_id}/update-criteria-instance/`

  const [refreshKey, setRefreshKey] = useState(0)
console.log(model)
  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')

    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata)
  }, [instance_id, refreshKey, getChildrenByContentType, filter1, filter2])

  useEffect(() => {
    console.log(listResources)
  }, [listResources])

  const updateParentState = (newValue) => {
    setRefreshKey(newValue)
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  console.log(assessments)
  return (
        <>                   <Drawer ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '40%',
            marginTop: '64px'
          }
        }}
    anchor="right" variant="temporary" open={isDrawerOpen} onClose={closeDrawer}>
<div>
<IconButton onClick={closeDrawer}>
  <ChevronRightIcon />
</IconButton>
</div>
<Box p={2} sx={{ flexGrow: 1 }}>
{catInstance
  ? <DynamicLevelSelector data={catInstance.categorization_model} initialValues={catInstance} setRefreshKey={updateParentState} endpoint={endpointUrl} />
  : null}
  </Box>
  </Drawer>
            <MRTcustomized
  key={`MRT-${instance_id}-${model1?.id}-${model2?.id}-${assessments.join('-')}`}
  results={listResources.results}
    enableRowActions={false}
    enableDetailPanel={false}
    nestedObjectName ={pluralModel2Name}
    extraFields={assessments}
    columnOrder={[
      'mrt-row-expand', 'mrt-row-actions', 'title', 'description', 'group'
    ]}
    enableDownload={true}
/>

        </>
  )
}

export default View
