import React from 'react'
import { Handle, Position } from 'reactflow'

const CustomNode = ({ data, type, icon: Icon, iconColor = '#555', backgroundColor = '#f0f0f0' }) => {
  const isThreatNode = type === 'threat' // Replace 'data.type' with the appropriate property
  return (
    <div className="node-container" >
      <div className="custom-node" style={{ backgroundColor }}>
        <div className="node-icon">
          <Icon style={{ fontSize: 30, color: iconColor }} />
        </div>

        {/* Handles for connecting (source and target attack) */}
        {isThreatNode && (
          <>
          <Handle
            type="source"
            position={Position.Right}
            id="source-threat"
            style={{ backgroundColor: '#b71c1c' }}
          />
          <Handle
          type="target"
          position={Position.Left}
          id="target-threat"
          style={{ backgroundColor: '#b71c1c' }}
        />
        <Handle
        type="source"
        position={Position.Top}
        id="source-attack"
        style={{ backgroundColor: '#b71c1c' }}
      /></>
        )}
        {!isThreatNode && (
          <>
        {/* Handles for display only (not connectable) */}
        <Handle
          type="target"
          position={Position.Left}
          id="target-attack"
          style={{ backgroundColor: '#b71c1c' }}
          />
        <Handle
          type="target"
          position={Position.Top}
          id="top-handle"
          isConnectable={false} // This handle is not connectable
          className={'invisible-handle'}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="bottom-handle"
          isConnectable={false} // This handle is not connectable
          className={'invisible-handle'}
        />
        </>
        )}
      </div>
      <div className="node-title">{data.label}</div>
    </div>
  )
}

export default CustomNode
