import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Box, IconButton, Paper, Dialog, DialogTitle, DialogActions } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

function Predisposing ({ resourceName, id, instance_id }) {
  const [selectedIds, setSelectedIds] = useState([])
  const [lastSelectedIds, setLastSelectedIds] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)

  const { getComplianceList: getResources, complianceList: resources } = useRequestCompliance({ endpoint: 'predisposing_condition' })
  const { getCompliance: getRiskInstance, compliance: riskInstance, updateCompliance: updateRiskInstance, actionResource } = useRequestCompliance({ endpoint: 'risk-assessment-instances', resourceLabel: 'Predisposing condition instance' })
  const { deleteResource } = useRequestCompliance({ endpoint: 'predisposing_condition', resourceLabel: 'predisposing_condition' })
  const [refreshKey, setRefreshKey] = useState(0)

  useEffect(() => {
    getResources()
    if (instance_id) {
      getRiskInstance(instance_id)
    }
  }, [instance_id, getRiskInstance, getResources])
  useEffect(() => {
    getResources()
  }, [refreshKey])

  useEffect(() => {
    if (riskInstance && riskInstance.predisposing_conditions) {
      setSelectedIds(riskInstance.predisposing_conditions.map(ts => ts.id))
    } else {
      // Handle the case when there are no threat sources or when riskInstance is not available
      setSelectedIds([]) // You can set it to an empty array or any other default value as needed
    }
  }, [riskInstance])

  const handleSelectedIdsChange = (newSelectedIds) => {
    setLastSelectedIds(newSelectedIds)
  }

  const handleSaveChanges = () => {
    const payload = {
      predisposing_conditions_ids: lastSelectedIds
    }
    actionResource(instance_id, payload, 'associate_predisposing_conditions/')
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsDrawerOpen(open)
  }
  const updateParentState = (newValue) => {
    setRefreshKey(newValue)
  }
  // Drawer content
  const drawerContent = (
        <>
        <Box>
            <IconButton onClick={toggleDrawer(false)}>
                    <CloseIcon />
            </IconButton>
        </Box>
        <Paper elevation={3}>
            <Box p={3}>
            <MultiStepResourceForm setRefreshKey={updateParentState} resourceName={'predisposing_condition'}></MultiStepResourceForm>
            </Box>
        </Paper>
        </>
  )

  const handleDeleteClick = (id) => {
    if (id) { setIdToDelete(id) }
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleDelete = () => {
    deleteResource(idToDelete)
    setDeleteOpen(false)
    setRefreshKey(prevKey => prevKey + 1)
  }

  return (
        <>
              <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          Are you sure you want to delete this predisposing condition from the catalog?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>
            Yes
          </Button>
          <Button onClick={handleDeleteClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" justifyContent="flex-end" m={1}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleDrawer(true)}
                >
                    Add new predisposing condition
                </Button>
            </Box>
            <Drawer ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '40%'
                    // marginTop: "64px",
                  }
                }}
            anchor="right" variant="temporary" open={isDrawerOpen} onClose={toggleDrawer}>
                <Toolbar />
                {drawerContent}
            </Drawer>
            <MRTcustomized
                key={JSON.stringify(selectedIds)} // Use a unique key based on initialSelectedIds
                results={resources.results}
                initialSelectedIds={selectedIds}
                onSelectedIdsChange={handleSelectedIdsChange}
                enableRowActions={true}
      renderRowActions={ ({ row }) => (
        <Box>
          <IconButton color='error' onClick={() => handleDeleteClick(row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
            >
                Save Changes
            </Button>
        </>
  )
}

export default Predisposing
