import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import * as echarts from 'echarts/core';
import { HeatmapChart } from 'echarts/charts';
import {
  TooltipComponent,
  VisualMapComponent,
  GridComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactECharts from 'echarts-for-react';

// Register the required ECharts components
echarts.use([
  HeatmapChart,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  TitleComponent,
  LegendComponent,
  CanvasRenderer,
]);


const ComplianceHeatmap = ({ data }) => {
  const theme = useTheme();
  const [option, setOption] = useState({});

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    // Unique values for axes
    const xAxes = [...new Set(data.map(d => d.standard))];
    const yAxes = [...new Set(data.map(d => d.system))];
    const values = data.map(d => [xAxes.indexOf(d.standard), yAxes.indexOf(d.system), d.value]);

    setOption({
      tooltip: {
        position: 'top',
        formatter: function (params) {
          const { value } = params;
          return `${yAxes[value[1]]}<br/>${xAxes[value[0]]}: ${value[2]}%`;
        },
      },
      grid: {
        top: '10%',
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: xAxes,
        splitArea: { show: true },
      },
      yAxis: {
        type: 'category',
        data: yAxes,
        splitArea: { show: true },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '5%',
        inRange: {
          color: [theme.palette.riskLevels.veryHigh,theme.palette.riskLevels.high,theme.palette.riskLevels.medium,theme.palette.riskLevels.low, theme.palette.riskLevels.veryLow],
        },
      },
      series: [{
        name: 'Compliance %',
        type: 'heatmap',
        data: values,
        label: {
          show: true,
          formatter: function ({ value }) {
            return `${value[2]}%`;
          }
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    });
  }, [data, theme]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div style={{ height: '500px', width: '100%' }}>
      <ReactECharts option={option} style={{ height: '100%', width: '100%' }} />
    </div>
  );
};

export default ComplianceHeatmap;
