import React, { useEffect } from 'react'
import ProcessGuide from '../../../components/Risk/ProcessGuide'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import { IconButton, Typography, Box, Toolbar, Grid } from '@mui/material'
import { ArrowBackIos as ArrowBackIcon } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'

export default function AssessmentPage () {
  const { id } = useParams()
  const navigate = useNavigate()
  const { getCompliance: getProcess, compliance: process } = useRequestCompliance({ endpoint: 'processes/by-assessment-instance', resourceLabel: 'process' })

  useEffect(() => {
    getProcess(id)
  }, [getProcess, id])

  return (
        <>
            <Grid container> {/* Use Grid container to define the layout */}
                <Grid item xs={12}> {/* Grid item to occupy all the available space on small screens */}
                    <Box display="flex" flexDirection="column" height="100vh" >
                        <Toolbar>
                            <IconButton onClick={() => navigate(-1)} edge="start" color="inherit" aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6" flex={1}>
                                Risk assessment process
                            </Typography>
                        </Toolbar>
                        <Box display="flex" flex={1} pt={2}>
                            <Box flex={1} pl={3}>
                                <ProcessGuide instance_id={id} data={process || null} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
  )
}
