import React, { useEffect, useState } from 'react';
import { Grid, Typography, Tabs, Tab, Box, Card, CardContent, CircularProgress } from '@mui/material';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import { useParams } from 'react-router-dom'; // Import if using React Router
import CustomFilter from 'src/components/General/CustomFilter';
import ComplianceTable from 'src/components/complianceAssessment/ComplianceTable';
import ComplianceHeatmap from 'src/components/complianceAssessment/ComplianceHeatmap';

const flattenComplianceData = (data) => {
  const flattenedData = [];
  data.forEach(standard => {
    standard.systems_compliance.forEach(system => {
      flattenedData.push({
        standard: standard.standard,
        system: system.information_system,
        value: Math.round(system.compliance_rate)
      });
    });
  });
  return flattenedData;
};

export default function ComplianceMonitorPage() {
  const { getComplianceList: getOrganizations, complianceList: organizations } = useRequestCompliance({ endpoint: 'organizations', resourceLabel: 'Organizations' });
  const { getComplianceList: getMissions, complianceList: missions } = useRequestCompliance({ endpoint: 'missions', resourceLabel: 'Missions' });
  const { getComplianceList: getInfoSystems, complianceList: informationSystems } = useRequestCompliance({ endpoint: 'information-systems', resourceLabel: 'Information systems' });
  const { getComplianceList: getInstances, complianceList: scInstances, loading: loadingInstance } = useRequestCompliance({ endpoint: 'subcontrolinstancemetric', resourceLabel: 'Subcontrol Instance metrics' });
  const { getComplianceList: getComplianceRate, complianceList: cRate, loading: loadingCompliance } = useRequestCompliance({ endpoint: 'complianceassessment/compliance_rate', resourceLabel: 'Compliance Rate' });

  const { id } = useParams(); // Use this line if id comes from URL parameters
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [missionOptions, setMissionOptions] = useState([]);
  const [informationSystemOptions, setInformationSystemOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    organization: null,
    mission: [],
    informationSystem: [],
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectionChange = (selections) => {
    const filter = {};
    setSelectedFilters(prev => ({
      ...prev,
      ...selections
    }));
    if (selections.organization) {
      filter['organization'] = selections.organization.value;
    }
    if (selections.mission.length > 0) {
      const missionIds = selections.mission.map(option => option.value).join(',');
      filter['missions'] = missionIds;
    }
    if (selections.informationSystem.length > 0) {
      const systemIds = selections.informationSystem.map(option => option.value).join(',');
      filter['information_systems'] = systemIds;
    }
    getInstances(filter);
    getComplianceRate(filter);
  };

  useEffect(() => {
    if (selectedFilters.organization) {
      getMissions({ organization: selectedFilters.organization.value });
    }
  }, [selectedFilters.organization, getMissions]);

  useEffect(() => {
    if (missions.results) {
      const formattedOptions = missions.results.map(mission => ({ value: mission.id, label: mission.title }));
      setMissionOptions(formattedOptions);
    }
  }, [missions]);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  useEffect(() => {
    if (organizations.results) {
      const formattedOptions = organizations.results.map(org => ({ value: org.id, label: org.title }));
      setOrganizationOptions(formattedOptions);
    }
  }, [organizations]);

  useEffect(() => {
    const filter = {};
    if (selectedFilters.organization) {
      filter.organization = selectedFilters.organization.value;
    }
    if (selectedFilters.mission.length > 0) {
      filter.missions = selectedFilters.mission.map(m => m.value).join(',');
    }
    if (Object.keys(filter).length > 0) {
      getInfoSystems(filter);
    }
  }, [selectedFilters.organization, selectedFilters.mission, getInfoSystems]);

  useEffect(() => {
    if (informationSystems.results) {
      const formattedOptions = informationSystems.results.map(sys => ({ value: sys.id, label: sys.title }));
      setInformationSystemOptions(formattedOptions);
    }
  }, [informationSystems]);

  useEffect(() => {
    let filter = '';
    if (id) {
      filter = { complianceAssessment: id };
      getInstances(filter);
      getComplianceRate(filter);
    }
  }, [id, getInstances, getComplianceRate]);

  return (
    <Grid justifyContent="space-between">
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Compliance Posture
        </Typography>
      </Grid>
      
      <Grid item>
        <Card>
          <CardContent>
            {!id && (
              <CustomFilter
                organizationOptions={organizationOptions}
                missionOptions={missionOptions}
                informationSystemOptions={informationSystemOptions}
                onSelectionChange={handleSelectionChange}
              />
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        <Box mb={2}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Compliance Data Tabs" centered>
            <Tab label="Compliance Table" />
            <Tab label="Compliance Heatmap" />
          </Tabs>
        </Box>
      </Grid>

      <Grid item>
        <Box>
          {selectedTab === 0 && (
            <Grid item xs={12}>
              {loadingInstance ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : (
                <ComplianceTable data={scInstances?.results?.flat()} loading={loadingInstance} />
              )}
            </Grid>
          )}
          {selectedTab === 1 && (
            <Grid item xs={12}>
              {loadingCompliance ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </Box>
              ) : (
                <ComplianceHeatmap data={flattenComplianceData(cRate?.results)} />
              )}
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
