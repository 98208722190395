import React, { useState } from 'react';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
  StepContent,
  Collapse,
  Box
} from '@mui/material';

const MultiProcess = ({ data }) => {
  const [completedSteps, setCompletedSteps] = useState({});
  const [completedTasks, setCompletedTasks] = useState({});
  const [completedActions, setCompletedActions] = useState({});
  const [expandedStep, setExpandedStep] = useState(null);
  const [expandedTask, setExpandedTask] = useState(null);
  const [expandedAction, setExpandedAction] = useState(null);

  const isDependencyMet = (dependencies, lookup) => {
    for (const dep of dependencies) {
      if (!lookup[dep]) return false;
    }
    return true;
  };

  const areAllTasksCompleted = (step) => {
    for (const task of step.tasks) {
      if (!completedTasks[task.title]) return false;
    }
    return true;
  };

  const areAllActionsCompleted = (task) => {
    for (const action of task.actions) {
      if (!completedActions[action.title]) return false;
    }
    return true;
  };

  const handleStepToggle = (stepTitle) => {
    if (expandedStep === stepTitle) {
      setExpandedStep(null); // Collapse if the step is already expanded
    } else {
      setExpandedStep(stepTitle); // Expand the clicked step
    }
  };

  const handleTaskToggle = (taskTitle) => {
    if (expandedTask === taskTitle) {
      setExpandedTask(null); // Collapse if the task is already expanded
    } else {
      setExpandedTask(taskTitle); // Expand the clicked task
    }
  };

  const handleActionToggle = (actionTitle) => {
    if (expandedAction === actionTitle) {
      setExpandedAction(null); // Collapse if the action is already expanded
    } else {
      setExpandedAction(actionTitle); // Expand the clicked action
    }
  };

  const handleStepComplete = (step) => {
    if (areAllTasksCompleted(step)) {
      setCompletedSteps((prev) => ({ ...prev, [step.title]: true }));
    }
  };

  const handleTaskComplete = (task) => {
    if (areAllActionsCompleted(task)) {
      setCompletedTasks((prev) => ({ ...prev, [task.title]: true }));
    }
  };

  const handleActionComplete = (action) => {
    setCompletedActions((prev) => ({ ...prev, [action.title]: true }));
  };

  if (!data || !data.steps) return null;

  return (
    <div>
      {/* Display process title and description */}
      <Typography variant="h4" component="h2" gutterBottom>
        {data.title}
      </Typography>
      <Typography variant="body1" paragraph>
        {data.description}
      </Typography>

      <Stepper orientation="vertical">
        {data.steps.map((step) => (
          <Step key={step.title} active={expandedStep === step.title}>
            <StepLabel
              onClick={() => handleStepToggle(step.title)}
              style={{ cursor: 'pointer' }}
            >
              {step.title}
            </StepLabel>
            <StepContent>
              <div style={{ paddingLeft: '24px', marginTop: '8px' }}>
                <Button
                  variant="contained"
                  onClick={() => handleStepComplete(step)}
                  disabled={!areAllTasksCompleted(step) || !isDependencyMet(step.dependencies, completedSteps)}
                >
                  Complete Step
                </Button>
                {step.tasks && (
                  <List>
                    {step.tasks.map((task) => (
                      <div key={task.title}>
                        <ListItem alignItems="flex-start" button onClick={() => handleTaskToggle(task.title)}>
                          <ListItemText primary={task.title} secondary={task.description} />
                        </ListItem>
                        <Collapse in={expandedTask === task.title} timeout="auto" unmountOnExit>
                          <Box sx={{ pl: 4, mt: 1, mb: 1 }}>
                            <Stepper orientation="vertical" nonLinear>
                              {task.actions.map((action) => (
                                <Step key={action.title} active={expandedAction === action.title}>
                                  <StepLabel
                                    onClick={() => handleActionToggle(action.title)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {action.title}
                                  </StepLabel>
                                  <StepContent>
                                    <Typography variant="body2" color="textSecondary">
                                      {action.description}
                                    </Typography>
                                  </StepContent>
                                </Step>
                              ))}
                            </Stepper>
                          </Box>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default MultiProcess;
