import { Avatar, Card, Stack, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import CommentContext from '../../contexts/commentContext'
import EditableCommentField from './Reusable/Comment/EditableCommentField'
import SendButton from './Reusable/Buttons/BgButtons/SendButton'

const AddComment = (props) => {
  const [commentTxt, setCommentTxt] = useState('')

  return (
      <Card>
        <Box sx={{ p: '15px' }}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar
              variant="rounded"
              alt="user-avatar"
            />
            <EditableCommentField
              commentText={commentTxt}
              setCommentText={setCommentTxt}
              placeHolder="Add a comment"
            />
            <SendButton commentTxt={commentTxt} setCommentTxt={setCommentTxt} selectedSubInstance={props.selectedSubInstance}/>
          </Stack>
        </Box>
      </Card>
  )
}

export default AddComment
