import * as React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Popover } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import useRequestCompliance from '../../hooks/useRequestCompliance' // replace with the actual path

function DynamicLevelSelector ({ data, initialValues, setRefreshKey, endpoint = 'update_criteria' }) {
  const [selectedLevels, setSelectedLevels] = React.useState({})
  const { updateCompliance: updateCat } = useRequestCompliance({ endpoint, resourceLabel: 'Level' })

  React.useEffect(() => {
    if (data && initialValues) {
      const levels = {}
      initialValues.criteria_instances.forEach(instance => {
        const levelId = data.criterias
          .find(criteria => criteria.id === instance.criteria.id)
          ?.levels.find(level => level.id === instance.level_value?.id)?.id
        if (levelId) {
          levels[instance.criteria.id] = levelId
        }
      })
      setSelectedLevels(levels)
    }
  }, [data, initialValues])
  const handleChange = (criteriaId, event) => {
    const newLevelId = event.target.value
    setSelectedLevels(prev => ({ ...prev, [criteriaId]: newLevelId }))

    if (initialValues && initialValues.id) {
      // Find the corresponding criteria_instance
      const criteriaInstance = initialValues.criteria_instances.find(instance => instance.criteria.id === criteriaId)
      console.log(initialValues)
      console.log(criteriaId)
      console.log(criteriaInstance)

      if (criteriaInstance) {
        // Call the updateCat function to update the level_value of the specific CriteriaInstance
        updateCat(criteriaInstance.id, { level_value: newLevelId })
        if (setRefreshKey) { setRefreshKey(prevKey => prevKey + 1) }
      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedCriteria, setSelectedCriteria] = React.useState(null)

  const handleClick = (event, criteria) => {
    setAnchorEl(event.currentTarget)
    setSelectedCriteria(criteria)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Typography variant="h2">{data.title}</Typography>
      <Typography variant="body1">{data.description}</Typography>
      {data.criterias.map(criteria => (
        <Box key={criteria.id} marginBottom={3}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>{criteria.title}</InputLabel>
            <Select
              value={selectedLevels[criteria.id] || ''}
              label={criteria.title}
              onChange={(event) => handleChange(criteria.id, event)}
            >
              {criteria.levels.map(level => (
                <MenuItem key={level.id} value={level.id}>
                  {level.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="textSecondary">{criteria.description}</Typography>
            <IconButton size="small" onClick={(event) => handleClick(event, criteria)}>
              <HelpIcon fontSize="small" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Level</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCriteria?.levels.map(level => (
                    <TableRow key={level.id}>
                      <TableCell>{level.title}</TableCell>
                      <TableCell>{level.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Popover>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default DynamicLevelSelector
