import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';

const VulnerabilityMetrics = ({ data }) => {
  const theme = useTheme();

  // Adjusted color mapping to match the data structure
  const colors = {
    Open: theme.palette.riskLevels.high,
    Closed: theme.palette.riskLevels.veryLow,
    Mitigated: theme.palette.success.main,
    "Very High": theme.palette.riskLevels.veryHigh,
    High: theme.palette.riskLevels.high,
    Moderate: theme.palette.riskLevels.medium,
    Low: theme.palette.riskLevels.low,
    "Very Low": theme.palette.riskLevels.veryLow,
    Software: theme.palette.accent.main,
    Network: theme.palette.info.main,
    Hardware: theme.palette.warning.main,
    Physical: theme.palette.selection.main,
    Other: theme.palette.background.default,
  };

  const convertDataForChart = (dataset, key, colorMap) => {
    return dataset?.map(item => ({
      name: item[key],
      value: item.count,
      itemStyle: {
        color: colorMap[item[key]] || theme.palette.secondary.main,
      },
    }));
  };

  const getChartOption = (type, title, dataset, key, colorMap) => {
    switch (type) {
      case 'pie':
        return {
          title: {
            text: title,
            left: 'center',
            textStyle: {
              color: theme.palette.text.primary,
            },
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              name: title,
              type: 'pie',
              radius: '55%',
              data: convertDataForChart(dataset, key, colorMap),
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        };
      case 'bar':
        return {
          title: {
            text: title,
            left: 'center',
            textStyle: {
              color: theme.palette.text.primary,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            type: 'category',
            data: dataset?.map(item => item[key]),
            axisLabel: {
              color: theme.palette.text.primary,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: theme.palette.text.primary,
            },
          },
          series: [
            {
              data: dataset?.map(item => item.count),
              type: 'bar',
              itemStyle: {
                color: function (params) {
                  return colorMap[dataset[params.dataIndex][key]] || theme.palette.primary.main;
                },
              },
            },
          ],
        };
      default:
        return {};
    }
  };
  const convertMonthlyDataForChart = (monthlyData) => {
    return monthlyData?.map(item => ({
        name: item.month,
        value: [item.month, item.count],
    }));
};

const monthlyTrendOption = {
    title: {
        text: 'Monthly Trends',
        left: 'center',
        textStyle: {
            color: theme.palette.text.primary,
        },
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: {
        type: 'time',
        axisLabel: {
            color: theme.palette.text.primary,
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: theme.palette.text.primary,
        },
    },
    series: [
        {
            name: 'Discoveries',
            type: 'line',
            data: convertMonthlyDataForChart(data.monthly_discoveries),
            itemStyle: {
                color: theme.palette.riskLevels.high,
            },
            lineStyle: {
                color: theme.palette.riskLevels.high, // Ensure the line itself is red
                width: 2,
            },
        },
        {
            name: 'Resolutions',
            type: 'line',
            data: convertMonthlyDataForChart(data.monthly_resolutions),
            itemStyle: {
                color: theme.palette.riskLevels.veryLow, // Use the red color for resolutions
            },
            lineStyle: {
                color: theme.palette.riskLevels.veryLow, // Ensure the line itself is red
                width: 2,
                type: 'dashed', // Optional: if you want the resolutions line to be dashed
            },
            symbol: 'circle', // Optional: style of the resolution data points
            symbolSize: 8, // Optional: size of the data points
        },
    ],
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
    },
};
  // Check if data is empty and render placeholder or chart
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }
  return (
    <Grid container spacing={3}>
      {/* Status Distribution */}
      <Grid item xs={12} md={4}>
        <ReactECharts
          option={getChartOption('pie', 'Status Distribution', data.status_counts, 'status', colors)}
          style={{ height: '300px' }}
        />
      </Grid>

      {/* Severity Distribution */}
      <Grid item xs={12} md={4}>
        <ReactECharts
          option={getChartOption('bar', 'Severity Distribution', data.severity_counts, 'severity', colors)}
          style={{ height: '300px' }}
        />
      </Grid>

      {/* Category Distribution */}
      <Grid item xs={12} md={4}>
        <ReactECharts
          option={getChartOption('bar', 'Category Distribution', data.category_counts, 'category', colors)}
          style={{ height: '300px' }}
        />
      </Grid>
    </Grid>
  );
};

export default VulnerabilityMetrics;
