import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

const Input = styled('input')({
  display: 'none',
});

const PreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

class CustomFileWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl: props.value || '',
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // This is the key adjustment: ensuring the data-url is set as the value
        this.setState({ imagePreviewUrl: reader.result });
        this.props.onChange(reader.result); // onChange should pass the data-url string
      };
      reader.readAsDataURL(file);
    }
  };
  

  handleRemove = () => {
    this.setState({ imagePreviewUrl: '' });
    this.props.onChange('');
  };

  render() {
    const { imagePreviewUrl } = this.state;
    return (
      <Box textAlign="center">
        <label htmlFor="icon-button-file">
          <Input accept="image/*" id="icon-button-file" type="file" onChange={this.handleChange} />
          <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
            Upload Logo
          </Button>
        </label>
        {imagePreviewUrl && (
          <PreviewContainer>
            <img src={imagePreviewUrl} alt="Preview" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
            <Typography variant="caption" sx={{ mt: 1 }}>Logo Preview</Typography>
            <Button size="small" startIcon={<DeleteIcon />} onClick={this.handleRemove} sx={{ mt: 1 }}>
              Remove
            </Button>
          </PreviewContainer>
        )}
      </Box>
    );
  }
}

export default CustomFileWidget;
