import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Paper, Typography, Drawer, IconButton, Toolbar, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RenderField from './RenderField'; // Import the RenderField component
import useRequestCompliance from '../../hooks/useRequestCompliance';
import pluralize from 'pluralize';

function ObjectDetailTabs({ id, endpoint, open, setOpen }) {
  const [currentTab, setCurrentTab] = useState('basic');
  const { compliance: data = {}, getCompliance: getData } = useRequestCompliance({ endpoint, resourceLabel: endpoint });
  const safeData = data || {};

  const flatData = Object.entries(safeData)
    .filter(([key, value]) => typeof value !== 'object')
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const nestedData = Object.entries(safeData)
    .filter(([key, value]) => typeof value === 'object')
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getData(id);
    }
  }, [open, id, getData]);

  return (
    <Drawer
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'block', md: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '40%',
        },
      }}
      anchor="right"
      variant="temporary"
      open={open}
      onClose={handleDrawerClose}
    >
      <Toolbar />
      <Box>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Paper elevation={3}>
        <Tabs value={currentTab} onChange={handleChange}>
          <Tab label="Basic" value="basic" />
          {Object.keys(nestedData).filter(key => nestedData[key] !== null).map(key => (
            <Tab label={key} value={key} key={key} />
          ))}
        </Tabs>
        <Box p={3}>
          <Stack spacing={2}>
            {currentTab === 'basic' ? (
              Object.keys(flatData).length > 0 ? (
                Object.entries(flatData).map(([key, value]) => (
                  <RenderField key={key} field={key} value={value} />
                ))
              ) : (
                <Typography variant="body2">No basic data available.</Typography>
              )
            ) : (
              Object.keys(nestedData[currentTab] || {}).length > 0 ? (
                Object.entries(nestedData[currentTab] || {}).map(([key, value]) => (
                  <RenderField key={key} field={`${pluralize.singular(currentTab)} - ${key}`} value={value} />
                ))
              ) : (
                <Typography variant="body2">No data available.</Typography>
              )
            )}
          </Stack>
        </Box>
      </Paper>
    </Drawer>
  );
}

export default ObjectDetailTabs;
