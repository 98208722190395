import React, { useState, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import MenuList from '@mui/material/MenuList'
import { useTheme } from '@mui/material/styles'

// Import Material-UI icons
import BusinessIcon from '@mui/icons-material/Business' // Organization Icon
import StorageIcon from '@mui/icons-material/Storage' // Asset Icon
import FeedIcon from '@mui/icons-material/Feed'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' // Expand Icon
import ChevronRightIcon from '@mui/icons-material/ChevronRight' // Collapse Icon
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import DvrIcon from '@mui/icons-material/Dvr'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'

const getIcon = (type) => {
  switch (type) {
    case 'O':
      return <BusinessIcon fontSize="small" color="primary"/>
    case 'M':
      return <AssignmentTurnedInIcon fontSize="small" color="primary" />
    case 'SA':
      return <StorageIcon fontSize="small" color="primary" />
    case 'PA':
      return <FeedIcon fontSize="small" color="primary" />
    case 'IS':
      return <DvrIcon fontSize="small" color="primary" />
    default:
      return null
  }
}

const TreeNode = ({ node, children, type, selectedNode, setSelectedNode, handleNodeSelect, isExpanded }) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const icon = getIcon(type)
  const theme = useTheme()
  const handleSelectNode = () => {
    console.log(node)
    setSelectedNode({ id: node.id, type })
    if (handleNodeSelect) {
      handleNodeSelect(node)
    }
  }
  // Update the expanded state when isExpanded prop changes
  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  const isSelected = `${selectedNode?.id}-${selectedNode?.type}` === `${node.id}-${type}`
  return (
        <div>
    <MenuItem
      style={{
        backgroundColor: isSelected ? theme.palette.selection.main : theme.palette.background.default
      }}
    >            <span style={{ display: 'inline-flex', alignItems: 'center', marginRight: 2 }}>
              <span onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
              </span>
              {icon}
            </span>
            <Tooltip title={node.title}>
              <span
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={handleSelectNode}
              >
                {node.title}
              </span>
            </Tooltip>
          </MenuItem>
          {expanded && <div style={{ marginLeft: 10 }}>{children}</div>}
        </div>
  )
}

const renderMissionTree = (missions, selectedNode, setSelectedNode, handleNodeSelect, expandAll) => {
  return missions.map((mission) => (
          <TreeNode
            node={mission} // Pass the entire node object
            key={mission.id}
            type="M"
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleNodeSelect={handleNodeSelect}
            isExpanded={expandAll}
          >
            {mission.information_systems && renderISTree(mission.information_systems, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
          </TreeNode>
  ))
}

const renderISTree = (information_systems, selectedNode, setSelectedNode, handleNodeSelect, expandAll) => {
  return information_systems.map((information_system) => (
          <TreeNode
            node={information_system} // Pass the entire node object
            key={information_system.id}
            type="IS"
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleNodeSelect={handleNodeSelect}
            isExpanded={expandAll}
          >
            {information_system.related_information_systems && information_system.related_information_systems.some(e => e !== null) && renderISTree(information_system.related_information_systems, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
            {information_system.primary_assets && renderAssetTree(information_system.primary_assets, 'primary_assets', selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
            {information_system.supporting_assets && renderAssetTree(information_system.supporting_assets, 'supporting_assets', selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
          </TreeNode>
  ))
}

const renderAssetTree = (assets, type, selectedNode, setSelectedNode, handleNodeSelect, expandAll) => {
  const symbol = type === 'supporting_assets' ? 'SA' : 'PA'
  return assets.map((asset) => (
    asset && ( // Check if asset is not null
          <TreeNode
            node={asset} // Pass the entire node object
            key={asset.id}
            type={symbol}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleNodeSelect={handleNodeSelect}
            isExpanded={expandAll}

          >
          {asset.related_assets && asset.related_assets.some(e => e !== null) && renderAssetTree(asset.related_assets, type, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
          {asset.supporting_assets && asset.supporting_assets.some(e => e !== null) && renderAssetTree(asset.supporting_assets, 'supporting_assets', selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
          </TreeNode>)
  ))
}
const renderOrganizationTree = (organizations, selectedNode, setSelectedNode, handleNodeSelect, expandAll) => {
  return organizations.map((organization) => (
          <TreeNode
            node={organization}
            key={organization.id}
            type="O"
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleNodeSelect={handleNodeSelect}
            isExpanded={expandAll}
          >
                {organization.missions && renderMissionTree(organization.missions, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
                {organization.related_organization && renderOrganizationTree(organization.related_organization, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
            </TreeNode>
  ))
}
const CustomizedTreeView = ({ data, handleNodeSelect }) => {
  const [selectedNode, setSelectedNode] = useState(null)
  const [expandAll, setExpandAll] = useState(false)

  return (
        <div>
          <FormControlLabel
            control={<Switch checked={expandAll} onChange={() => setExpandAll(!expandAll)} />}
            label="Expand All"
          />
          <MenuList open={true} anchorEl={document.body}>
            {data && renderOrganizationTree(data, selectedNode, setSelectedNode, handleNodeSelect, expandAll)}
          </MenuList>
        </div>
  )
}

export default CustomizedTreeView
