import { Button } from '@mui/material'
import React, { useContext } from 'react'
import CommentContext from '../../../../../contexts/commentContext'

const UpdateButton = ({ commentText, editingComm, setEditingComm, id }) => {
  const { updateComment } = useContext(CommentContext)

  return (
    <Button
      sx={{
        float: 'right',
        bgcolor: 'custom.moderateBlue',
        color: 'neutral.white',
        p: '8px 25px',
        '&:hover': {
          bgcolor: 'custom.lightGrayishBlue'
        }
      }}
      onClick={() => {
        !commentText.trim()
          ? alert(
            'If  you want to remove the comment text, just delete the comment.'
          )
          : setEditingComm(!editingComm); updateComment(commentText, id)
      }}
    >
      Update
    </Button>
  )
}

export default UpdateButton
