import { Avatar, Card, Stack, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import CommentContext from '../../contexts/commentContext'
import AddReplyButton from './Reusable/Buttons/BgButtons/AddReplyButton'
import EditableReplyField from './Reusable/Reply/EditableReplyField'

const AddReply = ({ onAdd, onTar, commid }) => {
  const [replyText, setReplyText] = useState('')
  return (
      <Card>
        <Box sx={{ p: '15px' }}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar
              variant="rounded"
              alt="user-avatar"
            />
            <EditableReplyField
              placeHolder="Add a reply"
              setText={setReplyText}
              text={replyText}
            />
            <AddReplyButton
              onAdd={onAdd}
              replyText={replyText}
              setReplyText={setReplyText}
              onTar={onTar}
              commid={commid}
            />
          </Stack>
        </Box>
      </Card>
  )
}

export default AddReply
