import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { LinearProgress, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled LinearProgress with custom color for the background
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '& .MuiLinearProgress-barColorSuccess': {
      backgroundColor: theme.palette.success.main, // Color of the progress bar
    },
    '& .MuiLinearProgress-dashed': {
      backgroundColor: 'transparent', // Removes the dashed background
    },
    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: theme.palette.success.main, // Keeps the determinate bar the same as success color
    },
    '& .MuiLinearProgress-root': {
      backgroundColor: theme.palette.grey, // Background color of the entire progress component
    },
  }));
  
const ComplianceTable = ({ data, loading }) => {
  const columns = [
    { accessorKey: 'information_system', header: 'Information System' },
    { accessorKey: 'standard', header: 'Standard' },
    { accessorKey: 'control', header: 'Control' },
    { accessorKey: 'subControl', header: 'SubControl' },
    {
        accessorKey: 'compliance_status',
        header: 'Compliance Status',
        AggregatedCell: ({ row }) => {
          // Use row.subRows to calculate percentages only within this group
          const allStatuses = row.leafRows.map((subRow) => subRow.original.compliance_status);
          const okCount = allStatuses.filter(status => status === 'OK').length;
          const nokCount = allStatuses.filter(status => status === 'NOK').length;
          const totalCount = okCount + nokCount;
          const okPercentage = totalCount > 0 ? (okCount / totalCount) * 100 : 0;

          return (
            <Box sx={{ width: '100%', mr: 1 }}>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <CustomLinearProgress variant="determinate" value={okPercentage} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${Math.round(okPercentage)}%`}</Typography>
                </Box>
              </Box>
            </Box>
          );
        },
    },
    {
        accessorKey: 'status',
        header: 'Status',
        // Custom Aggregated Cell to calculate and display the number of 'Done' status
        AggregatedCell: ({ row }) => {
            console.log(row)
          const allStatuses = row.leafRows.map((subRow) => subRow.original.status); // Collect all status values from subRows
          const doneCount = allStatuses.filter(status => status === 'Done').length; // Count how many 'Done' statuses there are
          return `${doneCount} of ${allStatuses.length} Done`; // Return formatted string
        },
     },
  ];

  return (
    <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
      <Paper elevation={3} sx={{ maxHeight: 1000, overflow: 'auto' }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{
          density: 'compact',
          columnVisibility: { myColumnId: false },
          grouping: ['information_system'],
          pagination: { pageSize: 25 } // Set initial page size to 25
        }}
        enableColumnOrdering
        enableColumnResizing
        enablePinning
        enableRowActions
        enableRowSelection
        enableSorting
        enableGrouping='true'
        state={{ isLoading: loading }}

      />
      </Paper>
    </Box>
      );
};

export default ComplianceTable;
