import * as React from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { DropzoneArea } from 'mui-file-dropzone'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import Stack from '@mui/material/Stack'
import useRequestCompliance from '../../hooks/useRequestCompliance'
import axios from 'axios'
import getCommonOptions from '../../helpers/axios/getCommonOptions'
import { useSnackbar } from 'notistack'
import Core from '../Comments/Core'
import { CommentProvider } from '../../contexts/commentContext'
import Divider from '@mui/material/Divider'
import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/CloudDownload'
import RichEditor
 from '../General/RichEditor'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'background.paper',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

export default function OutcomeForm (props) {
  const { handleRequestResourceError, updateCompliance } = useRequestCompliance({ endpoint: 'dataStorageTarget', resourceLabel: 'Data target storage' })
  const outcomes = props.outcomes
  const [data, setData] = React.useState(props.data)

  const { enqueueSnackbar } = useSnackbar()
  const [previewFiles, setPreviewFiles] = React.useState([])
  const handleRichTextChange = (index, value, type) => {
    const newData = data.map((item, i) => {
      if (i === index) {
        return { ...item, text: value, type: type };
      }
      return item;
    });
    setData(newData);
  };

  console.log(data)

  let title
  const saveFile = (id, files) => {
    console.log('Button clicked')
    const formData = new FormData()
    formData.append('dataStorageTarget', id)
    files.map((file) => {
      formData.append('files', file)
    })
    const axiosConfig = getCommonOptions()
    axiosConfig.headers['Content-Type'] = 'multipart/form-data; boundary=<calculated when request is sent>'

    console.log(formData)
    axios.post('/api/outcomeEvidence/multiple_upload/', formData, axiosConfig).then(
      () => {
        enqueueSnackbar('Outcome evidences updated')
      }
    ).catch(handleRequestResourceError)
  }

  const capitalizeFirst = str => {
    return str?.charAt(0).toUpperCase() + str?.slice(1)
  }

  React.useEffect(() => {
    if (props.data !== []) {
      setData(props.data)
    }
  }, [])
  function handleType (index, value, type) {
    const newData = data.map((item, i) => {
      if (i === index) {
        item.type = type
        if (item.type === 'Text field' ) {
          item.text = value
        } 
        else {
          item.files = value
        }
        return item
      } else {
        // The rest haven't changed
        return item
      }
    })
    setData(newData)
  }

  const handleSubmit = () => {
    console.log('submit')
    data.forEach((item, index) => {
      switch (item.type) {
        case 'Text field':
        case 'Rich field': // Assuming rich text is handled similarly to plain text but may need conversion
          updateCompliance(item.id, { ...item, text: item.text });
          props.updateParentState()
          break;
        case 'File upload':
          saveFile(item.id, item.files);
          props.updateParentState()
          break;
        default:
          console.log('Unhandled type', item.type);
      }
    });
  };
  
  console.log(data)

  if (outcomes && props.data.length > 0) {
    title = <Item>{outcomes.name}</Item>
    const reducedData = props.data.map((item) => ({
      id: item.id,
      iniFiles: item.outcomeevidences.map((evidence) => evidence.file)
    }))
    reducedData.forEach((item) => {
      const filesArray = []
      item.iniFiles.forEach((file) => {
        filesArray.push(file)
      })
      item.iniFiles = filesArray
    })
    return (
<>
  <Box margin={2} padding={2} >
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Fill Outcome Form
        </Typography>
        <Divider />
      </Grid>

      {props.data.map((item, index) => (
        <Box margin={1} padding={4} sx={{ backgroundColor: 'background.paper', borderRadius: '8px', boxShadow: 3, width: '100%' }}>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {capitalizeFirst(outcomes.find(obj => obj.id === item.subControlOutcome)?.name)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {capitalizeFirst(outcomes.find(obj => obj.id === item.subControlOutcome)?.description)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {capitalizeFirst(outcomes.find(obj => obj.id === item.subControlOutcome)?.request)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {(() => {
                switch (outcomes.find(obj => obj.id === item.subControlOutcome)?.type) {
                  case 'Rich field':
                    return (
                      <RichEditor
                        theme="snow"
                        label="Scope"
                        name="value_text"
                        onChange={(value) => handleRichTextChange(index, value, "Rich field")}
                        initialValue={item.text}
                        dataStorageTarget={item.id}
                        id={outcomes.find(obj => obj.id === item.subControlOutcome)?.name}
                        fullWidth
                      />
                    )
                  case 'Text field':
                    return (
                      <TextField
                        required
                        value={data && data[index] ? data[index].text : ''}
                        fullWidth
                        id={outcomes.find(obj => obj.id === item.subControlOutcome)?.name}
                        label="Required"
                        placeholder="Placeholder"
                        multiline
                        onChange={(event) => handleType(index, event.target.value, 'Text field')}
                      />
                    )
                  case 'File upload':
                    return (
                        <>
                          <DropzoneArea
                            showFileNames
                            filesLimit={5}
                            initialFiles={reducedData.length > 0 ? reducedData[index].iniFiles : []}
                            onChange={(files) => {
                              handleType(index, files, 'File upload')
                              setPreviewFiles(files) // Set the temporary state to display files
                            }}
                            useChipsForPreview
                            previewText="Selected files"
                          />
                          <List dense={true}>
                                              {previewFiles.map((file, i) => (

                            <ListItem key={i}>

                              <ListItemIcon>
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  onClick={() => {
                                    const blobUrl = URL.createObjectURL(file)
                                    const link = document.createElement('a')
                                    link.href = blobUrl
                                    link.download = file.name
                                    link.click()
                                    URL.revokeObjectURL(blobUrl)
                                  }}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </ListItemIcon>
                              <ListItemText primary={file.name} />
                            </ListItem>
                                              ))}
                          </List>
                        </>
                    )
                  default:
                    return null
                }
              })()}
            </Grid>
          </Grid>
        </Box>
      ))}

      <Grid item xs={12}>
        <Divider />
        <Stack margin={2} direction="row" spacing={2} justifyContent="space-between">
          <Button variant="outlined" color="success" endIcon={<SendIcon />} onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
  </Box>

  <Box margin={2} padding={2}>
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Comments
        </Typography>
        <Divider />
      </Grid>
    </Grid>
  </Box>

  <Box margin={0} padding={0}>
    <Grid container>
      <Grid item xs={12}>
      <CommentProvider key={props.selectedSubInstance} selectedSubInstance={props.selectedSubInstance}>
    <Core selectedSubInstance={props.selectedSubInstance} />
</CommentProvider>

      </Grid>
    </Grid>
  </Box>
</>

    )
  }
}
