import React, { useState, useEffect } from 'react'
import GlobalNavMenu from '../../../components/General/GlobalNavMenu'
import StatCard from '../../../components/StatCard'
import { Grid, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import DynamicTabsAndTables from '../../../components/General/DynamicTabsAndTables'
import { downloadCSV, downloadJSON } from '../../../components/General/Utils'
import { useNavigate } from 'react-router-dom'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

const tabEndpoints = [
  { name: 'Categorization Models', endpoint: 'categorization_models' },
  { name: 'Grid Matrices', endpoint: 'grid-matrices' },
]

export default function ModelView () {
  const navigate = useNavigate()
  const getActionList = (standard, fileName, standards) => [
    {
      name: 'Add',
      icon: 'AddIcon',
      items: [
        {
          name: 'Categorization Model',
          action: () => navigate('/settings/models/categorization-models/add')
        },
        {
          name: 'Grid Matrice',
          action: () => navigate('/settings/models/grid-matrices/add')
        }
      ]
    },
    {
      name: 'Refresh',
      icon: 'RefreshIcon',
      action: () => setRefreshKey(prevKey => prevKey + 1)
    },
    {
      name: 'Download',
      icon: 'DownloadIcon',
      items: [
        {
          name: 'Download JSON',
          action: () => downloadJSON(standard, `${fileName}.json`)
        },
        {
          name: 'Download CSV',
          action: () => downloadCSV(standard, `${fileName}.csv`)
        },
        {
          name: 'Download all JSON',
          action: () => downloadJSON(standards.results, 'All_Standards.json')
        },
        {
          name: 'Download all CSV',
          action: () => downloadCSV(standards.results, 'All_Standards.csv')
        }
      ]
    },
    {
      name: 'Learn More',
      icon: 'ReadMoreIcon'
    }
  ]

  const [complianceList, setComplianceList] = useState([])
  const [fileName, setFileName] = useState()
  const [refreshKey, setRefreshKey] = useState(0)

  // Use the function here to get the action list
  const actionList = getActionList(complianceList, fileName)
  const { getComplianceList: getKpi, complianceList: kpi } =
    useRequestCompliance({ endpoint: 'kpi/models' })
  useEffect(() => {
    getKpi()
  }, [getKpi, refreshKey])

  return (
    <>
          <Grid container spacing={0} justifyContent="space-between">
      <Grid >
      <Typography variant="h5" gutterBottom>
        Models management
      </Typography>
      </Grid>
      </Grid>
      <GlobalNavMenu actionList={actionList}>
    <Box pt={3} sx={{
      flexGrow: 1,
      mb: (theme) => theme.spacing(2)
    }}>
            <Grid container spacing={2}>
            <StatCard
    title="Total categorization models"
    value={kpi ? kpi.results.total_categoriziation_models: 'Loading...'}
    loading={!kpi}
    icon={<PersonIcon fontSize="small" />}
  />
                  <StatCard
                    title="Total grid matrices"
                    value={kpi ? kpi.results.total_matrices: 'Loading...'}
                    loading={!kpi}
                    icon={<GroupIcon fontSize="small" />}
                />
            </Grid>
        </Box>
            <DynamicTabsAndTables
        tabEndpoints={tabEndpoints}
        onDataLoaded={(data, filename) => {
          setComplianceList(data)
          setFileName(filename)
        }}
        refreshKey={refreshKey}
    />
    </GlobalNavMenu>
    </>
  )
}
