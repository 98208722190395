import React from 'react';
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'; // Adjust the path if necessary

function GenericResourcePage({ resourceName, id }) {
  return (
    <div>
      <MultiStepResourceForm resourceName={resourceName} id={id || null} />
    </div>
  );
}

export default GenericResourcePage;
