import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DetailHalfPage from '../../../components/DetailHalfPage'
import SelectList from '../../../components/SelectList'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import useRequestCompliance from '../../../hooks/useRequestCompliance'
import { Drawer, Link, TableCell } from '@mui/material'
import GenericExtraData from '../../../components/General/GenericExtraData'
const choices = [
  { value: 'TD', label: 'To Do' },
  { value: 'D', label: 'Done' },
  { value: 'C', label: 'Cancelled' },
  { value: 'NA', label: 'Not applicable' },
  { value: 'IP', label: 'In Progress' }]

function OneSelectList (props) {
  const [value, setValue] = React.useState(null)
  const { updateCompliance } = useRequestCompliance({ endpoint: props.endpoint, resourceLabel: props.resourceLabel })
  const handleChange = (event, value) => {
    const val = {
      responsible: ''
    }
    if (value) { val.responsible = value.id } else { val.responsible = null }
    props.updateInstances(props.item, val.responsible, props.pitem)
    setValue(value)
    updateCompliance(props.item, val, props.childitem)
  }
  useEffect(() => {
    if (props.ivalue) {
      setValue(props.options.find(word => word.id === props.ivalue))
    }
  }, [props.ivalue, props.options])
  const defval = props.options.find(word => word.id === props.ivalue)
  return (
    <Autocomplete
    disablePortal
    id="oneList"
    value={value}
    options={props.options}
    getOptionLabel={(option) => option.username}
    sx={{ width: 150 }}
    renderInput={(params) => <TextField {...params} label="username" />}
    onChange={handleChange}
  />
  )
}

function Row (props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const { getComplianceList, complianceList } = useRequestCompliance({ endpoint: 'user', resourceLabel: 'user list' })
  const [selectedMetaField, setSelectedMetaField] = useState(null)
  const handleEditClick = (id) => {
    props.setDataId(id)
    props.setDrawerOpen(true)
  }

  useEffect(() => {
    getComplianceList()
  }, [])
  if (props.controls && props.row) {
    const indice = props.row.control
    const object = props.controls.find(obj => obj.id === indice)
    if (object && complianceList.lenght !== 0) {
      return (

    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {object.title}
        </TableCell>
        <TableCell >{object.category}</TableCell>
        <TableCell >
          <OneSelectList ivalue={row.responsible} controlsinstances={props.controlsinstances} updateInstances={props.upcontrolsinstances} options={complianceList.results} item={row.id} endpoint="controlInstance" resourceLabel="Control responsible"/>
        </TableCell>
        <TableCell ><SelectList field='status' ivalue={row.status} ichoices={choices} item={row.id} endpoint="controlInstance" resourceLabel="Control status" upcontrolsinstances={props.upcontrolsinstances} ></SelectList></TableCell>
        <TableCell ><SelectList field='result_status' ivalue={row.result_status} ichoices={props.comstatuses} item={row.id} endpoint="controlInstance" resourceLabel="Control result status" upcontrolsinstances={props.upcontrolsinstances} ></SelectList></TableCell>
        <TableCell ><Link underline="hover" onClick={(e) => { e.stopPropagation(); props.setOpen(true); props.setSelectedTargetData(row.datastoragetarges); props.setSelectedOutcomes(object.outcomes) }} >
  {'Edit'}
  </Link> </TableCell>
  {
            props.metaFields.results && props.metaFields.results.length > 0 && props.metaFields.results.map((field, index) => {
              if (field.content_type.model === 'controlinstance') {
                // Filter by name
                const metaFielddata = row.meta_fields.find(mf => mf.field_name === field.name)
                // metaField now contains the object where name matches field.name
                if (metaFielddata) {
                  return (
                    <TableCell >
                                      <Link underline="hover" onClick={() => handleEditClick(metaFielddata.id)}>
                  {'Edit'}
                </Link>

                    </TableCell>
                  )
                }
              }
              return null
            })
        }      </TableRow>
      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 80 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2, p: 2, backgroundColor: '#f9f9f9', border: 1, borderColor: 'grey.300', borderRadius: '16px' }}>
              <Typography variant="h6" gutterBottom component="div">
                Sub controls
              </Typography>
              <Table size="small" aria-label="subcontrolinstances">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Responsible</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Compliance status</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Outcomes</TableCell>
                    {
            props.metaFields.results && props.metaFields.results.length > 0 && props.metaFields.results.map((field, index) => {
              if (field.content_type.model === 'subcontrolinstance') { // Filter based on content_type.model
                return (
                        <TableCell key={index}>
                            {field.name} {/* Render the name of the field */}
                        </TableCell>
                )
              }
              return null
            })
        }
                  </TableRow>
                </TableHead>
                 <TableBody>
                  {row.subcontrolsinstances.map((subRow) => (
                    <TableRow key={object.subControls.title}>
                      <TableCell component="th" scope="row">
                          {object.subControls.find(obj => obj.id === subRow.subControl).title}
                      </TableCell>
                      <TableCell >
                        <OneSelectList ivalue={subRow.responsible} updateInstances={props.upsubcontrolsinstances} options={complianceList.results} item={subRow.id} pitem={row.id} endpoint="subControlInstance" resourceLabel="SubControl responsible"/>
                      </TableCell>
                      <TableCell ><SelectList field='status'ivalue={subRow.status} ichoices={choices} item={subRow.id} endpoint="subControlInstance" resourceLabel="Subcontrol status"></SelectList></TableCell>
                      <TableCell >
      <SelectList
        field='result_status'
        ivalue={subRow.result_status}
        ichoices={props.comstatuses}
        item={subRow.id}
        endpoint="subControlInstance"
        resourceLabel="Subcontrol compliance status"
      />
</TableCell>                      <TableCell>{object.subControls.find(obj => obj.id === subRow.subControl).type}</TableCell>
                      <TableCell ><Link underline="hover" onClick={(e) => { e.stopPropagation(); props.setOpen(true); props.setSelectedTargetData(subRow.datastoragetarges); props.setSelectedSubInstance(subRow.id); props.setSelectedOutcomes(object.subControls.find(obj => obj.id === subRow.subControl).outcomes) }} >
  {'Edit'}
  </Link> </TableCell>
  {
  props.metaFields.results && props.metaFields.results.length > 0 && props.metaFields.results.map((field, index) => {
    if (field.content_type.model === 'subcontrolinstance') {
      // Filter by name
      const metaFielddata = subRow.meta_fields.find(mf => mf.field_name === field.name)
      // metaField now contains the object where name matches field.name
      if (metaFielddata) {
        return (
          <TableCell >
                <Link underline="hover" onClick={() => handleEditClick(metaFielddata.id)}>
                  {'Edit'}
                </Link>
          </TableCell>
        )
      }
    }
    return null
  })
}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
      )
    }
  }
}

export default function CollapsibleTable (props) {
  const [moreOpen, setMoreOpen] = useState(false)
  const [selectedOutcomes, setSelectedOutcomes] = useState(null)
  const [selectedTargetData, setSelectedTargetData] = useState(null)
  const [controlsinstances, setControlsinstances] = useState(props.controlsinstances)
  const [selectedSubInstance, setSelectedSubInstance] = useState(null)

  const { getComplianceList: getComplianceStatuses, complianceList: complianceStatuses } = useRequestCompliance({ endpoint: 'get_result_status' })
  const { getComplianceList: getMetaFields, complianceList: metaFields } = useRequestCompliance({ endpoint: 'metafields' })
  const [dataId, setDataId] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    getComplianceStatuses()
    getMetaFields()
  }, [])

  function handleState (value) {
    setMoreOpen(true)
  }
  // used to change control instance when responsible change locally
  function updateInstances (controlid, value) {
    const ins = controlsinstances
    ins.find(word => word.id === controlid).responsible = value
    setControlsinstances(ins)
  }
  function updateSubInstances (subcontrolid, value, controlid) {
    const ins = controlsinstances
    const subins = ins.find(word => word.id === controlid)
    subins.subcontrolsinstances.find(word => word.id === subcontrolid).responsible = value
    setControlsinstances(ins)
  }
  if (props.controlsinstances && complianceStatuses.results.length > 0) {
    return (
    <>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Title </TableCell>
            <TableCell >Category</TableCell>
            <TableCell >Responsible</TableCell>
            <TableCell >Status</TableCell>
            <TableCell>Compliance status</TableCell>
            <TableCell >Outcomes</TableCell>
                    {/* Dynamically add table cells based on meta_fields */}
        {
            metaFields.results && metaFields.results.length > 0 && metaFields.results.map((field, index) => {
              if (field.content_type.model === 'controlinstance') { // Filter based on content_type.model
                return (
                        <TableCell key={index}>
                            {field.name} {/* Render the name of the field */}
                        </TableCell>
                )
              }
              return null
            })
        }
          </TableRow>
        </TableHead>
        <TableBody>
          {controlsinstances.map((controlsinstance, index) => (
            <Row setDrawerOpen={setDrawerOpen} setDataId={setDataId} metaFields={metaFields} comstatuses={complianceStatuses.results} key={index} row={controlsinstance} controls={props.data} setOpen={handleState} setSelectedSubInstance={setSelectedSubInstance} setSelectedOutcomes={setSelectedOutcomes} controlsinstances={controlsinstances} setSelectedTargetData={setSelectedTargetData} upcontrolsinstances={updateInstances} upsubcontrolsinstances={updateSubInstances}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <DetailHalfPage
        key={selectedSubInstance} // Add key prop here
        open={moreOpen}
        setOpen={setMoreOpen}
        selectedSubInstance={selectedSubInstance}
        selectedData={selectedOutcomes}
        selectedTargetData={selectedTargetData}
        endpoint="outcomes"
      />
    <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '60%',
            marginTop: '64px'
          }
        }}
      >
        {dataId && (
          <GenericExtraData
            id={dataId}
          />
        )}
      </Drawer>
    </>
    )
  }
}
