import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useRequestCompliance from '../../../hooks/useRequestCompliance';
import ButtonMenu from '../../../components/buttonMenu';
import { CardActionArea, Button, Dialog, DialogTitle, DialogActions, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function OutlinedCard() {
  const { getComplianceList, complianceList, deleteResource } = useRequestCompliance({ endpoint: 'complianceAssessment', resourceLabel: 'compliance assessment' });
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getComplianceList();
  }, [getComplianceList]);

  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  
  const handleConfirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    setDeleteOpen(false);
    deleteResource(idToDelete);
    getComplianceList();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClickOpen = (id) => {
    if (id) {
      navigate(`/compliance/assessments/edit/${id}`);
    } else {
      navigate('/compliance/assessments/add/');
    }
  };

  const filteredCompliances = complianceList.results?.filter((compliance) => {
    return (
      compliance.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      compliance.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const deleteClick = (id) => {
    handleConfirmDelete(id);
  };

  const editClick = (id) => {
    handleClickOpen(id);
  };

  const cardList = filteredCompliances.map((item) => (
    <Grid item xs={12} sm={6} md={4} key={item.id}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          elevation: 3,
          p: 2,
        }}
      >
        <CardActionArea onClick={() => navigate(`/compliance/assessments/process/${item.id}`)}>
          <CardContent>
            <Typography variant="h3" component="div" fontWeight="bold" mt={2}>
              {item.title}
            </Typography>
            <Typography sx={{ mb: 1.5, color: 'text.secondary' }}>
              {item.description}
            </Typography>
            <Box mt={2} p={1} bgcolor="background.paper" border={1} borderRadius={2} borderColor="divider">
              <Typography component="span" fontWeight="bold" sx={{ color: 'text.secondary' }}>
                Standards:
              </Typography>
              {item.standards.map((std, index) => (
                <Typography key={index} component="div" sx={{ color: 'text.secondary' }}>
                  - {std.title}
                </Typography>
              ))}
              <Typography variant="subtitle2" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
                <Typography component="span" fontWeight="bold">
                  Information system:
                </Typography>{' '}
                {item.information_system.title || 'Not Provided'}
              </Typography>
            </Box>
          </CardContent>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" color="success" value={item.percentage_count} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(item.percentage_count)}%`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                  Compliance rate
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <ButtonMenu
                editClick={() => editClick(item.id)}
                deleteClick={() => deleteClick(item.id)}
                id={item.assessment_id}
                navigateTo={`/compliance/assessments/process/${item.id}`}
                navigateLabel="Assess"
                navigateIcon={<AssessmentIcon />}
                handleMonitor={() => {
                  navigate(`/compliance/assessments/monitor/${item.id}`);
                }}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <div>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Are you sure you want to delete this assessment?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleDeleteClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Grid container justifyContent="space-between" spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h5" gutterBottom>
            Compliance assessments
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => handleClickOpen(null)} startIcon={<AddIcon />}>
            Add assessment
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
                <Grid item sm={12}>
                    <TextField
                        type="search"
                        id="search"
                        label="Search"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                          )
                        }}
                    />
                </Grid>
      </Grid>
      <Grid container spacing={2}>
        {cardList}
      </Grid>
    </div>
  );
}
