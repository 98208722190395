import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm' // Adjust the path if necessary

function AssessmentForm ({ id }) {
  return (
        <div>
            <MultiStepResourceForm resourceName="complianceAssessment" id={id || null} />
        </div>
  )
}

export default AssessmentForm
