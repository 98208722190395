import { useCallback, useState, useContext } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import formatHttpApiError from '../helpers/formatHttpApiError';
import { LoadingOverlayResourceContext } from '../components/LoadingOverlayResource';
import getCommonOptions from '../helpers/axios/getCommonOptions';

export default function useGlobalSearch() {
  const [searchResults, setSearchResults] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const loadingOverlay = useContext(LoadingOverlayResourceContext);
  const { setLoading, loading } = loadingOverlay;

  const handleRequestError = useCallback((err) => {
    const formattedError = formatHttpApiError(err);
    setError(formattedError);
    setLoading(false);
    enqueueSnackbar(formattedError, { variant: 'error' });
  }, [enqueueSnackbar, setLoading]);

  const performSearch = useCallback((query) => {
    setLoading(true);
    axios.get(`/api/search/query/`, {
      params: { q: query },
      ...getCommonOptions()
    }).then((res) => {
        setLoading(false);
        setSearchResults(res.data);
    }).catch(handleRequestError);
  }, [handleRequestError, setLoading]);

  return {
    searchResults,
    performSearch,
    error,
    loading
  };
}
