import React, { useState, useEffect } from 'react'
import useRequestCompliance from 'src/hooks/useRequestCompliance'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'
import MRTcustomized from 'src/components/General/MRTcustomized'
import { Box, IconButton, Paper, MenuItem, Dialog, DialogTitle, DialogActions } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'

const pluralize = (word) => {
  if (word?.toLowerCase().endsWith('s')) {
    return word // already plural
  }
  if (word?.toLowerCase().endsWith('y')) {
    // Remove 'y' and add 'ies'
    return `${word?.slice(0, -1)}ies`
  }
  return `${word}s` // simple pluralization
}
function removeInstanceWords (inputString) {
  // Use regular expression to remove all instances of "instance" (case-insensitive)
  return inputString.replace(/instance/gi, '')
}
// Utility function to parse a filter string and prefix its keys
const parseAndPrefixFilter = (filterString, prefix) => {
  return filterString?.split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=')
    result[`${prefix}_${key}`] = value
    return result
  }, {})
}
function Identify ({ model, instance_id, model1, model2, filter1, filter2,assessments, updateParentState,refreshKey }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pluralModel2Name = pluralize(model2?.model)
  const pluralModel1Name = pluralize(model1?.model)
  const [selectedIds, setSelectedIds] = useState([]) // State to hold the selected IDs

  const [showCatalog, setShowCatalog] = useState(false) // New state for tracking user choice
  const { getChildrenByContentType, complianceList: listResources,actionResource } = useRequestCompliance({ endpoint: model, resourceLabel: model })
  const { deleteResource } = useRequestCompliance({ endpoint: model2 ? pluralModel2Name : pluralModel1Name, resourceLabel: 'Item resource' })
  const { getComplianceList: getResources, complianceList: resources } = useRequestCompliance({ endpoint: pluralize(removeInstanceWords(model2 ? model2?.model : model1?.model)) })

  const [lastSelectedIds, setLastSelectedIds] = useState([])
  const [selectedId, setSelectedId] = useState()
  
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false) // State for delete confirmation dialog
  const [itemToDelete, setItemToDelete] = useState(null) // State to track the item to be deleted
  const [rowOriginal, setRowOriginal] = useState(null); // State to store the current row's original data

  useEffect(() => {
    // Convert filter1 and filter2 strings to objects with prefixed keys
    const filter1Obj = parseAndPrefixFilter(filter1, 'ct1')
    const filter2Obj = parseAndPrefixFilter(filter2, 'ct2')

    // Combine both filter objects
    const filterdata = { ...filter1Obj, ...filter2Obj }
    getChildrenByContentType(instance_id, model1.id, model2?.id, filterdata)
  }, [instance_id, refreshKey, getChildrenByContentType, filter1, filter2])

  const [choiceDialogOpen, setChoiceDialogOpen] = useState(false)

  const handleOpenChoiceDialog = (id) => {
    setChoiceDialogOpen(true)
    setSelectedId(id)
  }

  const handleCloseChoiceDialog = () => {
    setChoiceDialogOpen(false)
  }

  const handleAddFromCatalog = (id) => {
    getResources({riskassessment_id: instance_id}) // Fetch resources for the catalog
    setShowCatalog(true) // Set to show the catalog in the drawer
    setIsDrawerOpen(true) // Open the drawer
    if (!model2?.model) {
      const related_id = removeInstanceWords(model1?.model)
      const idArray = listResources?.results.map(obj => obj[`${related_id}_id`])
      setSelectedIds(idArray)
    }
    handleCloseChoiceDialog()
  }

  const handleAddNewItem = () => {
    setShowCatalog(false) // Set to show the form
    setIsDrawerOpen(true) // Open the drawer for adding a new item
    handleCloseChoiceDialog()
  }
  const handleSelectedIdsChange = (newSelectedIds) => {
    setLastSelectedIds(prevIds => {
      // Convert both arrays to JSON string and compare
      if (JSON.stringify(prevIds) !== JSON.stringify(newSelectedIds)) {
        return newSelectedIds;
      }
      return prevIds; // Return previous state to avoid unnecessary re-render
    });
  };
  const handleSave = async () => {
    const payload = {
      child_ids: lastSelectedIds,
      object_id: selectedId,
      content_type_id: model1.id,
      filter1: filter1,
      filter2: filter2
    }
    
    // Await the result from actionResource and check the success status
    const result = await actionResource(instance_id, payload, `identify-${model2 ? pluralModel2Name : pluralModel1Name}/`)
    if (result.success) {
      // If action was successful, refresh the related content
      updateParentState() // Refresh content
    }
  }
  const handleAddSubmit = async (extrapayload) => {
    const payload = {
      object_id: selectedId,
      content_type_id: model1.id,
      filter1: filter1,
      filter2: filter2
    }
    // Merging payload and extrapayload
    const mergedPayload = {
      ...payload,
      ...extrapayload
    }
    const result = await actionResource(instance_id, mergedPayload, `identify-${model2 ? model2.model : model1.model}/`)
    if (result.success) {
      // If action was successful, refresh the related content
      updateParentState() // Refresh content
    }
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setIsDrawerOpen(open)
  }

  useEffect(() => {
    if (selectedId !== null) {
      const foundObject = listResources.results.find(obj => obj.id === selectedId)
      if (foundObject && pluralModel2Name in foundObject) {
        const related_id = removeInstanceWords(model2?.model)
        const idArray = foundObject[pluralModel2Name].map(obj => obj[`${related_id}_id`])
        setSelectedIds(idArray)
      } else {
        setSelectedIds([]) // Reset if no matching object is found
      }
    }
  }, [selectedId, listResources.results, pluralModel2Name])
  // Drawer content
  const drawerContent = showCatalog ? (
        <>
        <Box>
            <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Paper elevation={3}>
            <Box p={3}>
        <MRTcustomized
    key="catalog-"selectedId // Change key based on selectedIds
    results={resources.results}
            enableRowSelection={true}
            initialSelectedIds={selectedIds}
            onSelectedIdsChange={handleSelectedIdsChange}
        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Button variant="outlined" color="primary" onClick={toggleDrawer(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleSave() }>
                            Save
                        </Button>
                </Box>
        </Paper>
        </>
  ) : (
        <>
        <Box>
            <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Paper elevation={3}>
            <Box p={3}>
                <MultiStepResourceForm setRefreshKey={updateParentState} resourceName={pluralize(removeInstanceWords(model2 ? model2?.model : model1?.model))} handleAddSubmit={handleAddSubmit}></MultiStepResourceForm>
            </Box>
        </Paper>
        </>
  )

  const addButton = !model2
    ? (
        <>
              <Box display="flex" justifyContent="flex-end" m={1}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={model1.model.includes('instance') ? () => setChoiceDialogOpen(true) : () => handleAddNewItem() }
                >
                    Add new {model1.model}
                </Button>
            </Box>
        </>
      )
    : null

  

  // When an icon is clicked to initiate a delete, store row.original in state
  const handleIconClick = (id, row) => {
    setItemToDelete(id); // Store the ID of the item to delete
    setRowOriginal(row.original); // Store the row's original data
    setDeleteConfirmationOpen(true); // Open the delete confirmation dialog
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      // Call the delete function with itemToDelete
      let data = {}; // Initialize data as an empty object
      // If rowOriginal contains an object_id, include it in the data
      if (rowOriginal && rowOriginal.object_id) {
        data = { object_id: rowOriginal.object_id };
      }      
      const { success, message, error } = await deleteResource(itemToDelete,data);
      setDeleteConfirmationOpen(false) // Close the dialog after deletion
      setRowOriginal(null); // Clear the stored rowOriginal data

    // Check if the delete operation was successful
    if (success) {
      // Only update parent state if the delete was successful
      updateParentState();
      // Optionally, show a success message
      } 
      }
  }

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false) // Close the dialog without deleting
  }


  return (
        <>            <Dialog open={deleteConfirmationOpen} onClose={handleCloseDeleteConfirmation}>
                <DialogTitle>Are you sure you want to delete this Item?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleConfirmDelete}>Yes, Delete</Button>
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                </DialogActions>
            </Dialog>
              
      <Dialog open={choiceDialogOpen} onClose={handleCloseChoiceDialog}>
                <DialogTitle>
                    Add {model2 ? model2.model : model1.model}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleAddFromCatalog}>Add from Catalog</Button>
                    <Button onClick={handleAddNewItem}>Add New Item</Button>
                </DialogActions>
            </Dialog>
            <Drawer ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '60%'
                    // marginTop: "64px",
                  }
                }}
            anchor="right" variant="temporary" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <Toolbar />
                {drawerContent}
            </Drawer>
            {addButton}
            <MRTcustomized
                key={`MRT-${instance_id}-${model1?.id}-${model2?.id}`}
                results={listResources.results}
                enableRowSelection={false}
                enableRowActions={true}
                enableDetailPanel={true}
                nestedObjectName ={pluralModel2Name}
                onIconClick={handleIconClick}
                extraFields={assessments}
                columnOrder={[
                  'mrt-row-expand', 'mrt-row-actions', 'title', 'description', 'group']}
                {...(model2
                  ? {
                      renderRowActionMenuItems: ({ row }) => [
                        <MenuItem key={`Add-${model2.model}`} onClick={() => handleOpenChoiceDialog(row.id)}>
                            Add {model2.model}
                        </MenuItem>
                      ]
                    }
                  : {
                      renderRowActions: ({ row }) => (
                        <Box>
                            <IconButton color='error' onClick={() => handleIconClick(row.id, row)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                      )
                    })}
            />
        </>
  )
}

export default Identify
