import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'



const RoleMultiStepForm = ({ id }) => {

  return (
    <div>
        <MultiStepResourceForm resourceName="rbac/roles" id={id || null} />
    </div>
)
}

export default RoleMultiStepForm
