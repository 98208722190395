import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'

import Compliance from './pages/Compliance/Program'

import Standard from './pages/Compliance/Standard'
import Assessment from './pages/Compliance/Assessment'
import ComplianceAssessmentDetails from './pages/Compliance/Assessment/Details'
import SignUp from './pages/Auth/SignUp'
import SignIn from './pages/Auth/SignIn'
import { SnackbarProvider } from 'notistack'
import AuthContextProvider from './contexts/AuthContextProvider'
import RequireAuth from './components/RequireAuth'
import RequireNotAuth from './components/RequireNotAuth'
import BaseLayout from './components/BaseLayout'
import ThemeModeProvider from './contexts/ThemeModeProvider'
import RBAC from './pages/RBAC'
import Team from './pages/Team'
import Asset from './pages/Asset'
import Explore from './pages/Asset/Explorer'
import AddItemPage from './pages/common/AddItemPage'
import Processes from './pages/Governance/Processes'
import ProcessesExplore from './pages/Governance/Processes/detail'
import OneProcess from './pages/Governance/Processes/OneProcess'
import RiskMethodologies from './pages/Risk/Methodologies'
import Organization from './pages/Governance/Organization'
import Strategy from './pages/Governance/Strategy'
import Responsibility from './pages/Governance/Responsibility'
import RiskAssessments from './pages/Risk/Assessments'
import RiskAssessmentInstance from './pages/Risk/Assessments/AssessmentPage'
import MonitorPage from './pages/Risk/Assessments/Monitor'
import RaciExplore from './pages/Governance/Responsibility/raci/detail'
import ComplianceAssessmentInstance from './pages/Compliance/Assessment/AssessmentPage'
import ComplianceMonitorPage from './pages/Compliance/Assessment/ComplianceMonitorPage'
import IAMdView from './pages/Settings/IAM'
import ModelView from './pages/Settings/Models'
import DetailedAssetView
 from './pages/Asset/detailedAssetView'
import DetailedProgramView from './pages/Compliance/Program/detailedProgramView'
import MethodologyDetail from './pages/Risk/Methodologies/MethodologyDetail'
import Welcome from './pages/Welcome'
import NotFound from './pages/NotFound' // Import the NotFound component
import TaxonomyView from './pages/Risk/Taxonomies.js'

export default function App () {
  return <div>
    <ThemeModeProvider>
    <CssBaseline />
    <AuthContextProvider>
      <SnackbarProvider>
          <Router>
              <Box sx={{
                bgcolor: (theme) => theme.palette.background.default,
                minHeight: '100vh',
                width: '100%'
              }}>
                <Routes>
                  <Route element={<RequireAuth />}>
                    <Route element={<BaseLayout />}>

                      <Route path="/" element={<Welcome />} />

                      <Route path="/governance/processes/explore" element={<ProcessesExplore />} />
                      <Route path="/governance/processes" element={<Processes />} />
                      <Route path="/governance/processes/detail/:id" element={<OneProcess />} />
                      <Route path="/governance/organizations" element={<Organization />} />
                      <Route path="/governance/strategies" element={<Strategy />} />
                      <Route path="/governance/responsibilities" element={<Responsibility />} />
                      <Route path="/governance/responsibilities/explore" element={<RaciExplore />} />
                      <Route path="/governance/responsibilities/explore/:id" element={<RaciExplore />} />
                      <Route path="/:module/processes/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/organizations/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/strategies/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/responsibilities/:type/edit/:id" element={<AddItemPage />} />

                      <Route path="/compliance/programs" element={<Compliance />}/>
                      <Route path="/compliance/standards" element={<Standard />} />
                      <Route path="/compliance/assessments" element={<Assessment />} />
                      <Route path="/compliance/assessments/process/:id" element={<ComplianceAssessmentInstance />} />
                      <Route path="/assessments/edit/:id" element={<ComplianceAssessmentDetails />} />
                      <Route path="/compliance/assessments/monitor/:id" element={<ComplianceMonitorPage />} />
                      <Route path="/compliance/monitoring" element={<ComplianceMonitorPage />} />
                      <Route path="/compliance/standards/:id" element={<Standard />} />
                      <Route path="/compliance/programs/detail/:id" element={<DetailedProgramView />}/>
                      <Route path="/:module/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/standards/:type/edit/:id" element={<AddItemPage />} />

                      <Route path="/risk/taxonomies" element={<TaxonomyView />} />
                      <Route path="/risk/methodologies" element={<RiskMethodologies />} />
                      <Route path="/risk/assessments" element={<RiskAssessments />} />
                      <Route path="/risk/assessments/process/:id" element={<RiskAssessmentInstance />} />
                      <Route path="/risk/assessments/monitor/:id" element={<MonitorPage />} />
                      <Route path="/risk/monitoring" element={<MonitorPage />} />
                      <Route path="/risk/methodologies/detail/:id" element={<MethodologyDetail />} />
                      <Route path="/:module/:type/edit/:id" element={<AddItemPage />} />


                      <Route path="/manage/rbac" element={<RBAC />} />
                      <Route path="/manage/teams" element={<Team />} />
                      <Route path="/manage/assets" element={<Asset />} />
                      <Route path="/manage/assets/explore" element={<Explore />} />
                      <Route path="/:module/assets/:type/detail/:id" element={<DetailedAssetView />} />
                      <Route path="/:module/assets/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/rbac/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/:resource/:type/:action" element={<AddItemPage />} />

                      <Route path="/:module/:type/add" element={<AddItemPage />} />
                      <Route path="/edit/:type/:id" element={<AddItemPage />} />
                      <Route path="/add/:resource/:type" element={<AddItemPage />} />
                      <Route path="/:admin/:action/:type/:id" element={<AddItemPage />} />
                      <Route path="/:module/:resource/:type/add" element={<AddItemPage />} />
                      <Route path="/:module/:type/add" element={<AddItemPage />} />

                      <Route path="/settings/IAM" element={<IAMdView />} />
                      <Route path="/settings/Models" element={<ModelView />} />
                      <Route path="/:module/IAM/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="/:module/Models/:type/edit/:id" element={<AddItemPage />} />
                      <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 Not Found */}
                    </Route>
                  </Route>
                  <Route element={<RequireNotAuth />} >
                    <Route path="/auth/signup" element={<SignUp />} />
                    <Route path="/auth/signin" element={<SignIn />} />
                  </Route>

                </Routes>

              </Box>
          </Router>
        </SnackbarProvider>
    </AuthContextProvider>
    </ThemeModeProvider>

    </div>
}
