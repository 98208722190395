import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MatrixDrawer
 from './MatrixDrawer';

const DetailedMethodologyView = () => {
  const { id } = useParams(); // Get the id from the route parameters
  const navigate = useNavigate(); // Hook for navigation
  const { getCompliance, compliance: methodologyDetails } = useRequestCompliance({ endpoint: 'risk-assessment-methodologies' });

  // State for managing drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState('');

  useEffect(() => {
    if (id) {
      getCompliance(id); // Fetch the methodology details using the id
    }
  }, [id, getCompliance]);

  const handleDrawerOpen = (title, content) => {
    setDrawerContent(content);
    setDrawerTitle(title);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setDrawerContent(null);
    setDrawerTitle('');
  };

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* Title Section */}
        <Box p={2} sx={{ flexGrow: 1 }}>
          <Typography variant="h5">
            Methodology Detail
          </Typography>
          <Divider />
        </Box>

        {/* Main Content */}
        {methodologyDetails ? (
          <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 64px)' }}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {methodologyDetails.title}
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    {methodologyDetails.description}
                  </Typography>
                  <Divider />
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Analysis Approach"
                        secondary={methodologyDetails.analysis_approach}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Assessment Approach"
                        secondary={methodologyDetails.assessment_approach}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Created By"
                        secondary={methodologyDetails.created_by}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Created At"
                        secondary={methodologyDetails.created_at}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Updated At"
                        secondary={methodologyDetails.updated_at}
                      />
                    </ListItem>
                  </List>
                  <Divider />
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/governance/processes/${methodologyDetails.process}`)}
                    >
                      View Process
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            
            {/* Categorization Models Card */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Risk Categorization Models
                  </Typography>
                  <List>
                    {methodologyDetails.risk_categorization_models.map((model, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleDrawerOpen(model.title, model)}
                      >
                        <ListItemText
                          primary={model.title}
                          secondary={model.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {/* Grid Matrices Card */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Grid Matrices
                  </Typography>
                  <List>
                    {methodologyDetails.grid_matrices.map((matrix, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleDrawerOpen(matrix.title, matrix)}
                      >
                        <ListItemText
                          primary={matrix.title}
                          secondary={matrix.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Loading Methodology Details...
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}                 sx={{
                  display: { xs: 'block', sm: 'block', md: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    marginTop: '64px'
                  }}} >
        <Box sx={{ maxWidth: 1000, p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{drawerTitle}</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {drawerTitle && drawerContent && (
              <>
                <ListItem>
                  <ListItemText
                    primary={drawerContent.title}
                    secondary={drawerContent.description}
                  />
                </ListItem>
                {drawerContent.criterias && drawerContent.criterias.map((criteria, idx) => (
                  <List key={idx}>
                    <ListItem>
                      <ListItemText
                        primary={criteria.title}
                        secondary={criteria.description}
                      />
                    </ListItem>
                    {criteria.levels && criteria.levels.map((level, levelIdx) => (
                      <ListItem key={levelIdx} sx={{ pl: 4 }}>
                        <ListItemText
                          primary={level.title}
                          secondary={level.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                ))}
                {drawerContent.elements &&  (
                    <MatrixDrawer model={drawerContent}></MatrixDrawer>
                )}
              </>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default DetailedMethodologyView;
