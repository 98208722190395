import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

function RiskLevels({ data }) {
  const theme = useTheme();

  const getColor = (riskLevel) => {
    const { riskLevels } = theme.palette;
    switch (riskLevel) {
      case 'Very High':
        return riskLevels.veryHigh;
      case 'High':
        return riskLevels.high;
      case 'Medium':
      case 'Moderate': // Grouped Medium and Moderate for the same color
        return riskLevels.medium;
      case 'Low':
        return riskLevels.low;
      case 'Very Low':
        return riskLevels.veryLow;
      default:
        return theme.palette.secondary.main;
    }
  };

  const lineStyle = (color) => ({
    height: '20px',
    width: '4px',
    backgroundColor: color,
    marginRight: '8px',
  });
  // Check if data is empty and render placeholder or chart
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }
  return (
    <Box sx={{ width: '100%' }}> {/* Confirm the container takes full width */}
      {Object.entries(data).map(([level, count], index) => (
        <Box key={index} sx={{ display: 'flex', width: '100%', alignItems: 'center', mb: 2 }}>
          <div style={lineStyle(getColor(level))}></div>
          <Typography variant="h7" sx={{ flexGrow: 1, textAlign: 'left' }}>{level}</Typography>
          <Typography variant="h7" sx={{ flexGrow: 0 }}>{count}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default RiskLevels;
