import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import {
  TextField, Button, Grid, Paper, Box, Typography, Stack
} from '@mui/material'
import * as Yup from 'yup'
import RichEditor from './RichEditor'
import useRequestCompliance from '../../hooks/useRequestCompliance'

const validationSchema = Yup.object({
  value_text: Yup.string().required('Required')
})

const renderFieldByType = (field_type, formik, field_description) => {
  console.log(formik)
  switch (field_type) {
    case 'Text':
      return (
        <TextField
          name="value_text"
          label={field_description}
          value={formik.values.value_text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.value_text && Boolean(formik.errors.value_text)}
          helperText={formik.touched.value_text && formik.errors.value_text}
        />
      )
    case 'Rich field':
      return (
        <RichEditor
          theme="snow"
          label="Scope"
          name="value_text"
          onChange={(html) => formik.setFieldValue('value_text', html)}
          value={formik.values.value_text}
          fullWidth
        />
      )
    default:
      return null
  }
}

const GenericExtraData = ({ id }) => {
  const { getCompliance, compliance, updateCompliance } = useRequestCompliance({
    endpoint: 'meta_field_data',
    resourceLabel: 'Field'
  })

  const formik = useFormik({
    initialValues: {
      value_text: ''
    },
    validationSchema,
    onSubmit: (values) => {
      updateCompliance(id, values, () => {
        console.log('Successfully updated assessment')
      })
    }
  })

  const { value_text: initialValue } = compliance || {}

  useEffect(() => {
    getCompliance(id)
  }, [getCompliance, id])

  useEffect(() => {
    if (initialValue) {
      formik.setFieldValue('value_text', initialValue)
    }
  }, [initialValue, formik.setFieldValue])

  const {
    field: {
      field_name = '',
      field_description = '',
      field_type = ''
    } = {}
  } = compliance || {}

  return (
<Box margin={2} padding={2}>
  <Grid container spacing={3}>
    <Paper elevation={3} style={{ height: '700px', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
      <Box flex="1" overflow="auto" margin={2}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <Typography variant="h4" gutterBottom>{compliance?.field_name}</Typography>
            <Typography variant="h5" gutterBottom>{compliance?.field_description}</Typography>
            <div>
              {renderFieldByType(compliance?.field_type, formik, compliance?.field_description)}
            </div>
          </Stack>
          <Box marginTop={40}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button type="button" onClick={formik.handleReset} disabled={formik.isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" disabled={formik.isSubmitting}>
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </Paper>
  </Grid>
</Box>

  )
}

GenericExtraData.propTypes = {
  id: PropTypes.string.isRequired
}

export default GenericExtraData
