import React from 'react'
import MultiStepResourceForm from 'src/components/Risk/MultiStepResourceForm'



const MissionMultiStepForm = ({ id }) => {

  return (
    <div>
        <MultiStepResourceForm resourceName="missions" id={id || null} />
    </div>
)
}

export default MissionMultiStepForm
