import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { useTheme } from '@mui/material/styles';

function CategorizationComponent({ data, editLevels }) {
  const theme = useTheme(); // Access theme

  // Define the style for the circle
  const circleStyle = {
    borderRadius: '50%', // Makes it a circle
    width: '16px', // Set width and height as needed
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  // Updated getColor function to use theme colors based on level_value.index
  const getColor = (levelIndex) => {
    const { riskLevels } = theme.palette; // Destructure for easier access
    switch (levelIndex) {
      case 4:
        return riskLevels.veryHigh;
      case 3:
        return riskLevels.high;
      case 2:
        return riskLevels.medium;
      case 1:
        return riskLevels.low;
      case 0:
        return riskLevels.veryLow;
      default:
        return 'grey'; // Use a default color or adjust as needed
    }
  };

  const handleEdit = () => {
    // Handle the edit functionality here
    if (editLevels) {
      editLevels();
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={data.categorization_model.title}
          action={
            <Tooltip title={data.categorization_model.description}>
              <InfoIcon color="action" />
            </Tooltip>
          }
        />
        <CardContent>
          {data.criteria_instances.map((instance, index) => (
            <Box key={index} mb={2}>
              <Typography variant="h6" component="div">
                {instance.criteria.title}{' '}
                <Tooltip title={instance.criteria.description}>
                  <InfoIcon fontSize="small" color="action" />
                </Tooltip>
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Typography color="textSecondary" mr={1}>
                  Level Value:
                </Typography>
                <Chip
                  icon={<div style={{ ...circleStyle, backgroundColor: getColor(instance.level_value?.index) }} />} // Set the background color of the circle
                  label={instance.level_value ? instance.level_value.title : 'N/A'}
                />
              </Box>
            </Box>
          ))}
        </CardContent>
        {editLevels && (
          <CardActions>
            <Button onClick={handleEdit}>
              Edit
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}

export default CategorizationComponent;
