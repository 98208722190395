import React, { useState, useMemo } from 'react'
import { Backdrop, Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

export const LoadingOverlayResourceContext = React.createContext({
  setLoading: () => {},
  loading: false // Define default value for loading as well
})

export default function LoadingOverlayResource ({ children }) {
  const [loading, setLoading] = useState(false)
  const overlayValue = useMemo(() => {
    return { setLoading, loading } // Include loading in the context value
  }, [setLoading, loading]) // Include loading as a dependency

  return (
        <LoadingOverlayResourceContext.Provider value={overlayValue}>
            <Box sx={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              height: '100vh'
            }}>
<Backdrop sx={{
  zIndex: (theme) => theme.zIndex.drawer + 1, // Ensures it's above other content
  color: '#fff',
  position: 'fixed', // Use fixed instead of absolute
  top: 0,
  left: 0
}} open={loading}>
    <CircularProgress color="secondary"/>
</Backdrop>
                {children}
            </Box>
        </LoadingOverlayResourceContext.Provider>
  )
}

LoadingOverlayResource.propTypes = {
  children: PropTypes.node
}
