import React, { useState, useEffect, useMemo } from 'react';
import {
  useMaterialReactTable,
  MaterialReactTable,
} from 'material-react-table';
import { Menu, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ObjectDetailTabs from './ObjectDetailTabs';

export default function DynamicTable({
  complianceList,
  handleDelete,
  handleEdit,
  currentTab,
  setParentSelectedRows,
  initialSelectedRows = [], // Provide a default value
  hideActionsColumn,
  singleSelect,
  handleResetPassword,
}) {
  const [currentRowId, setCurrentRowId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // State for the confirmation dialog
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowId(id);
  };

  const handleDetail = () => {
    setDrawerOpen(true);
  };

  const handleRowSelect = (rowId) => {
    setParentSelectedRows((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        prevSelected = []; // Ensure prevSelected is an array
      }
      if (singleSelect) {
        return [rowId];
      } else {
        if (prevSelected.includes(rowId)) {
          return prevSelected.filter((id) => id !== rowId);
        } else {
          return [...prevSelected, rowId];
        }
      }
    });
  };

  const handleConfirmDeleteOpen = () => {
    setConfirmDeleteOpen(true);
    handleClose();
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDeleteConfirmed = () => {
    handleDelete(currentRowId);
    handleConfirmDeleteClose();
  };

  const columns = useMemo(() => {
    const renderCellContent = (value) => {
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <div key={index}>{renderCellContent(item)}</div>
        ));
      } else if (typeof value === 'object' && value !== null) {
        return Object.entries(value).map(([key, val], index) => (
          <div key={index}>
            <strong>{key}:</strong> {renderCellContent(val)}
          </div>
        ));
      }
      return value;
    };

    const stringColumns = Object.keys(complianceList[0] || {}).filter(
      (key) => typeof complianceList[0][key] === 'string'
    );

    return [
      ...stringColumns.map((key) => ({
        accessorKey: key,
        header: key.charAt(0).toUpperCase() + key.slice(1),
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()} arrow>
            <span style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '300px' // Adjust the max width as needed
            }}>
              {renderCellContent(cell.getValue())}
            </span>
          </Tooltip>
        ),
      })),
      {
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <IconButton onClick={(event) => handleClick(event, row.original.id)}>
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ];
  }, [complianceList]);

  const data = useMemo(() => complianceList, [complianceList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds = {} } = {}, // Ensure selectedRowIds is initialized
  } = useMaterialReactTable({
    data,
    columns,
    initialState: {
      hiddenColumns: hideActionsColumn ? ['actions'] : [],
      pagination: {
        pageSize: 5,
      },
      selectedRowIds: initialSelectedRows.reduce(
        (acc, id) => ({ ...acc, [id]: true }),
        {}
      ),
    },
    manualPagination: true,
    pageCount: Math.ceil(complianceList.length / 5),
    onRowSelectionChange: (rows) => {
      setParentSelectedRows(rows.selectedFlatRows?.map((row) => row.original));
    },
    enableRowSelection: true,
    enableMultiSelect: !singleSelect,
  });

  useEffect(() => {
    setParentSelectedRows(selectedFlatRows?.map((row) => row.original));
  }, [selectedFlatRows, setParentSelectedRows]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        renderRowActions={({ row }) => (
          <IconButton onClick={(event) => handleClick(event, row.original.id)}>
            <MoreVertIcon />
          </IconButton>
        )}
        enableRowSelection
        enableGrouping
        enableMultiSelect={!singleSelect}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            handleRowSelect(row.original.id);
          },
          selected: selectedRowIds[row.id] || false, // Ensure selectedRowIds is defined and use a fallback value
        })}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={1}
      >
        <MenuItem onClick={handleConfirmDeleteOpen}>Delete</MenuItem>
        <MenuItem onClick={() => handleEdit(currentRowId)}>Edit</MenuItem>
        <MenuItem onClick={handleDetail}>Detail</MenuItem>
        {handleResetPassword && (
          <MenuItem onClick={() => { handleResetPassword(currentRowId); handleClose(); }}>Reset Password</MenuItem>
        )}
      </Menu>
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ObjectDetailTabs
        id={currentRowId}
        endpoint={currentTab ? currentTab.endpoint : null}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </>
  );
}
