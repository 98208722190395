import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import '@mdxeditor/editor/style.css';
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, imagePlugin, InsertImage, tablePlugin,InsertTable  } from '@mdxeditor/editor';
import axios from 'axios';
import getCommonOptions from '../../helpers/axios/getCommonOptions';
import { styled, useTheme } from '@mui/material/styles';

// Using styled API
const EditorContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #ccc', // adds a grey border of 2px width around images
  fontFamily: theme.typography.fontFamily,
  borderRadius: theme.shape.borderRadius,
  zIndex: theme.zIndex.tooltip + 1,
  '& img': { // targeting all <img> tags within the editor content
    maxWidth: '50%', // ensures images are not wider than their container
    height: 'auto', // maintains the aspect ratio of the image
    display: 'block', // removes bottom space/gap under the image
  },
}));

const RichEditor = ({ initialValue, onChange, dataStorageTarget }) => {
  const [value, setValue] = useState(initialValue);


  useEffect(() => {
    // This will ensure the editor updates when the initial value changes
    setValue(initialValue);
  }, [initialValue]);

  const handleEditorChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const imageUploadHandler = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('dataStorageTarget', dataStorageTarget);

    try {
      const axiosConfig = getCommonOptions();
      const response = await axios.post('/api/outcomeEvidence/upload-image/', formData, axiosConfig);
      return response.data.url;
    } catch (error) {
      return '';
    }
  };

  return (
    <EditorContainer>
    <MDXEditor
      markdown={value}
      onChange={handleEditorChange}
      plugins={[
        tablePlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <InsertTable />       
              <InsertImage />
            </>
          )
        }),
        imagePlugin({
          imageUploadHandler: imageUploadHandler,
        }),
      ]}
    />
    </EditorContainer>
  );
};

RichEditor.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataStorageTarget: PropTypes.number.isRequired,
};

export default RichEditor;
