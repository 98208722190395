import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import Select from 'react-select';
import DetailHalfPage from '../DetailHalfPage';
import MRTNested from './MRTNested';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import useRequestCompliance from 'src/hooks/useRequestCompliance';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function DataList(props) {
  const results = props.result;
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedControl, setSelectedControl] = useState(null);
  const [detailEndpoint, setDetailEndpoint] = useState("");
  const [moreOpen, setMoreOpen] = useState(false);
  const [menuState, setMenuState] = useState({ anchorEl: null, activeRowId: null });
  const navigate = useNavigate();
  const { deleteResource: deleteStandard } = useRequestCompliance({ endpoint: 'standards', resourceLabel: 'standard' });
  const { deleteResource: deleteControl } = useRequestCompliance({ endpoint: 'controls', resourceLabel: 'control' });
  const { deleteResource: deleteSubControl } = useRequestCompliance({ endpoint: 'sub-controls', resourceLabel: 'sub-control' });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: null,
    isSubControl: false,
    isStandard: false,
  });
  const { id } = useParams(); // Get the id from the route parameters

  useEffect(() => {
    if (id && results.length > 0) {
      const item = results.find(item => item.id === parseInt(id));
      if (item) {
        setSelectedItem(item);
        props.setStandard(item);
      }
    }
  }, [id, results, props]);

  useEffect(() => {
    if (results.length > 0) {
      setSelectedItem(results[0]);
      props.setStandard(results[0]);
    }
  }, [results]);

  const handleClose = () => {
    setMenuState({ anchorEl: null, activeRowId: null });
  };

  const handleEditStandard = () => {
    if (selectedItem) {
      navigate(`/compliance/standards/standards/edit/${selectedItem.id}`);
    }
  };

  const handleEdit = (id, isSubControl) => {
    if (isSubControl) {
      navigate(`/compliance/standards/sub-controls/edit/${id}`);
    } else {
      navigate(`/compliance/standards/controls/edit/${id}`);
    }
    handleClose(); // Close the menu after action
  };

  const handleDelete = (id, isSubControl, isStandard) => {
    if (isStandard) {
      deleteStandard(id);
    } else if (isSubControl) {
      deleteSubControl(id);
    } else {
      deleteControl(id);
    }
    handleClose(); // Close the menu after action
  };

  const renderActionCell = ({ row }) => {
    const isSubControl = row.parentId !== undefined;

    return (
      <>
        <Dialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this item? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog({ ...deleteDialog, open: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              handleDelete(deleteDialog.id, deleteDialog.isSubControl, deleteDialog.isStandard);
              setDeleteDialog({ ...deleteDialog, open: false });
            }} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton onClick={(event) => setMenuState({ anchorEl: event.currentTarget, activeRowId: row.id })}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="action-menu"
          anchorEl={menuState.anchorEl}
          keepMounted
          open={Boolean(menuState.anchorEl) && menuState.activeRowId === row.id}
          onClose={handleClose}
          elevation={1}
        >
          <MenuItem onClick={() => handleEdit(row.original.id, isSubControl)}>Edit</MenuItem>
          <MenuItem onClick={() => setDeleteDialog({ open: true, id: row.original.id, isSubControl: isSubControl })}>Delete</MenuItem>
          <MenuItem onClick={() => {
            setMoreOpen(true);
            setSelectedControl(row.original.id);
            if (isSubControl) {
              setDetailEndpoint("compliance/sub-controls");
            } else {
              setDetailEndpoint("compliance/controls");
            }
            handleClose(); // Close the menu after action
          }}>Detail</MenuItem>
        </Menu>
      </>
    );
  };

  const renderTopToolbarCustomActions = () => (
    <>
      <Button
        color="primary"
        onClick={handleEditStandard}
        startIcon={<EditIcon />}
      >
        Edit Standard
      </Button>
      <Button
        color="error"
        onClick={() => setDeleteDialog({ open: true, id: selectedItem?.id, isSubControl: false, isStandard: true })}
        startIcon={<DeleteIcon />}
        sx={{ ml: 1 }}
      >
        Delete Standard
      </Button>
    </>
  );

  const handleSelectChange = (selectedOption) => {
    const item = results.find(result => result.id === selectedOption.value);
    if (item) {
      setSelectedItem(item);
      props.setStandard(item);
      navigate(`/compliance/standards/${item.id}`);
    }
  };

  const selectOptions = results.map(item => ({
    value: item.id,
    label: item.title,
    image: item.image_url
  }));

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {image ? (
        <img src={image} alt={label} style={{ width: 24, height: 24, marginRight: 2 }} />
      ) : (
        <Avatar sx={{ width: 24, height: 24, marginRight: 2 }}>{label.charAt(0)}</Avatar>
      )}
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
  };

  useEffect(() => {
    if (id && results.length > 0) {
      const item = results.find(result => result.id === parseInt(id));
      if (item) {
        setSelectedItem(item);
        props.setStandard(item);
      }
    }
  }, [id, results, props]);

  const defaultValue = selectOptions.find(option => option.value === (id ? parseInt(id) : null)) || selectOptions[0];

  if (selectedItem) {
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          value={defaultValue}
          isSearchable
          formatOptionLabel={formatOptionLabel}
          styles={customStyles}
        />
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" gutterBottom>
            {selectedItem.description}
          </Typography>
          {/* Other content */}
        </Box>
        <MRTNested
          data={selectedItem?.controls}
          renderActionCell={renderActionCell}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        />
        <DetailHalfPage open={moreOpen} setOpen={setMoreOpen} selectedData={selectedControl} endpoint={detailEndpoint} />
      </Box>
    );
  } else {
    // Optionally handle the case where selectedItem is null
    return null;
  }
}
